<mat-card>
  <mat-card-title style="text-align: center; font-size: 20px;">SUSTANCIAS DE REGISTRO</mat-card-title>
  <mat-card-subtitle class="subtitle"><strong>SELECCIONE LA SUSTANCIA.</strong></mat-card-subtitle>
 
  <div class="row main__search">
    <div class="col-md-12 column" style="text-align: right">      
      <mat-form-field style="min-width:auto" appearance="outline" floatLabel="never">
        <mat-label><mat-icon style="size: 100%;">search</mat-icon> {{Buscar}}</mat-label>
        <input matInput placeholder="Buscar" (keyup)="applyFilter($event)">
               
      </mat-form-field>
    </div>
  </div> <br>
  <mat-card-content>
   
  <div class="mat-elevation-z8">
       <table mat-table [dataSource]="sustancias" #tablaSeleccion matSort>
      <ng-container matColumnDef="id_sustancia">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> N° </th>
        <td mat-cell *matCellDef="let row; let i = index">
          {{ i + 1 }}
        </td>
        <!-- <td mat-cell *matCellDef="let element"> {{ element.id_sustancia }} </td> -->
      </ng-container>
      <ng-container matColumnDef="acciones">
        <th mat-header-cell *matHeaderCellDef> Acciones </th>
        <td mat-cell *matCellDef="let element">
          <div *ngIf="validaExistencia(element); else agregarSustancia">
            <button disabled mat-icon-button matTooltip="Eliminar Sustancia" (click)="eliminaSustancia(element)"
              matTooltipClass="tooltip-background" color="warn">
              <mat-icon class="color-verde-3">verified</mat-icon>
            </button>
          </div>
         
          <ng-template  #agregarSustancia >
            <mat-checkbox  matTooltip="Seleccionar Sustancia" (click)="seleccionarSustancia(element)"
            [mat-dialog-close]="sustanciasSeleccionadas">
            </mat-checkbox>
            <!-- <button mat-icon-button matTooltip="Seleccionar Sustancia" -->
           <!--  <button mat-icon-button matTooltip="Seleccionar Sustancia" [mat-dialog-close]="sustanciasSeleccionadas"
              (click)="seleccionarSustancia(element)" matTooltipClass="tooltip-background">
              <mat-icon class="color-yellow-2">check_circle</mat-icon>
            </button> -->
          </ng-template>
   
        </td>
      </ng-container>


      <!-- Name Column -->
      <ng-container matColumnDef="nombre_sustancia" style="width: 50%;">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 50%;"> Sustancia </th>
        <td mat-cell *matCellDef="let element"  style="width: 50%;"> {{element.nombre_sustancia}} </td>
      </ng-container>
      <ng-container matColumnDef="nombre_unidad_medida">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Unidad de medida </th>
        <td mat-cell *matCellDef="let element" class="text-uppercase"> {{element.nombre_unidad_medida}} </td>
      </ng-container>
      <ng-container matColumnDef="nombre_sustancia_primaria">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Sustancia Primaria </th>
        <td mat-cell *matCellDef="let element"> {{element.nombre_sustancia_primaria}} </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="columnasSustancias" class="header-row"></tr>
      <tr mat-row *matRowDef="let row; columns: columnasSustancias;"></tr>
    </table>



  <mat-paginator [pageSizeOptions]="[5, 10]" showFirstLastButtons
    aria-label="Seleccione páginas de un periodo de elementos">
  </mat-paginator>
</div>
  <div mat-dialog-actions class="mt-3 mb-1">
    <button mat-raised-button color="warn" (click)=cerrarDialog()><mat-icon>cancel</mat-icon>Cancelar</button>
  </div>
</mat-card-content>
</mat-card>