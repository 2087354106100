<div class="auth-container">
    <div class="row flex-column justify-content-center align-items-center">
        <div class="col-md-3 auth-container__box">

            <div class="auth-container__head">
                <div class="auth-container__head head__logo">
                    <a routerLink="/login">
                        <img src="assets/images/logo2021.jpg" alt="DGSC" />
                    </a>
                </div>
             
               <a routerLink="/login">
                <img src="assets/images/iconofinal.png" alt="DGSC" width="65" height="65"/>                
            </a> <br>
          <!--  <mat-card-title style="text-align: center; font-size: 20px;color: #575757;"  class="main__title"><br>ESTADO DIGITAL 6 - DGSC</mat-card-title> -->
           <mat-card style="border-radius: 10px;">
            <div class="row" style="text-align: center;font-size: 1.2rem;background: radial-gradient(circle, rgb(51, 240, 224) 0%, #8dcac6 100%); border-radius: 7px;"> 
            <img class="responsiveimg"  src="assets/svg/done_outline_white_24dp.svg"><br>
            <h3 style="text-align: center; font-size: 2.5rem; color: #fff;">¡PAGO EXITOSO!</h3>
            </div>     
        </mat-card>
            </div>
            
            <router-outlet></router-outlet>
         
        </div>
    </div>
</div>



