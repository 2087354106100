<div style="position: relative;" *ngIf="!hidden">

  <mat-horizontal-stepper class="mt-4" linear="true" #stepper>

    <mat-step [stepControl]="tiposPagoForm" label="Tipo de pago" style="padding: 0;" *ngIf="!importeDBEstaCompleto">
      <div class="mt-4 mb-5 text-center">
        <h2 class="text-uppercase">Selecciona tu método de pago preferido</h2>
      </div>
      <div class="row mt-3 justify-content-between">
        <div class="col-md-4">
          <p style="font-weight: 300; font-size: 1rem;"><span style="font-weight: 500">Tipo de trámite:</span>
            {{tipoTramite}}</p>
          <p style="font-weight: 300; font-size: 1rem;"><span style="font-weight: 500">Costo del trámite:</span> Bs.
            {{montoACobrar}}.-</p>
          <p *ngIf="categoriaTramite" style="font-weight: 300; font-size: 1rem;"><span
              style="font-weight: 500">Categoría del trámite:</span>
            {{categoriaTramite}}
          </p>
        </div>
        <!-- <div class="col"></div> -->
        <div class="col-md-4">
          <!--  <p style="font-weight: 300; font-size: 1rem;"><span style="font-weight: 500">Total pagado CPT:</span> Bs.
            {{importeTotalCPT}}</p> -->
          <p style="font-weight: 300; font-size: 1rem;"><span style="font-weight: 500">Total pagado boletas:</span> Bs.
            {{importeTotalComprobantes}}.-</p>
          <p style="font-weight: 600; font-size: 1rem; color: #ed2a56;"><span style="font-weight: 500">Saldo a
              pagar:</span> Bs.
            {{ (montoACobrar-importeTotalComprobantes > 0) ? montoACobrar-(importeTotalComprobantes+importeTotalONLINE)
            : '0'}}.-</p>
        </div>
      </div>

      <form [formGroup]="tiposPagoForm">
        <div class="first-step">
          <div class="step-contain">

            <mat-radio-group aria-label="Selecciona un tipo de pago" formControlName="tipoPago" class="radio-group">

              <!-- <div class="tipo-de-pago" [ngClass]="{ 'tipo-seleccionado': tiposPagoForm.value.tipoPago==1 }">
                <mat-radio-button value="1" color="primary">
                  <img src="assets/images/pago-cpt.jpg" alt="Pago por PPTE">
                  Paga usando PPTE
                </mat-radio-button>
              </div> -->

              <mat-radio-button value="2" color="primary">
                <div class="radio-container">
                  <img src="assets/images/transferencias.jpg" alt="Pago con depósito o transferencia">
                  <span class="radio-label">Paga con Depósito o Transferencia</span>
                </div>
              </mat-radio-button>

              <!-- metodo QR libelula -->
              <!--     <mat-radio-button value="3" color="primary" *ngIf="!activar">
                <div class="radio-container">
                  <img src="assets/images/red_enlace.png" alt="Paga con pasarela de Pagos Online">
                  <span class="radio-label">Paga con Pasarela de Pagos Online</span>
                </div>
              </mat-radio-button> -->

            </mat-radio-group>

          </div>
        </div>

        <div mat-dialog-actions class="mt-3 mb-1">

          <button mat-raised-button class="nuevo-button" matStepperNext (click)="stepOneNext()"
            [disabled]="formsAreValid()"><mat-icon>keyboard_arrow_right</mat-icon>Siguiente</button>
          <button mat-raised-button mat-dialog-close color="warn" style="height: 45px;"
            (click)="cerrar()"><mat-icon>cancel</mat-icon>Cancelar</button>
        </div>
      </form>
    </mat-step>

    <mat-step [stepControl]="comprobantesForm" label="Comprobantes de pago">
      <div class="my-3">
        <div class="text-center">
          <h2 class="text-uppercase">{{ montoACobrar == 0 ? 'Su trámite no tiene ningún costo' : 'Registra tus
            comprobantes de pago'}}</h2>
        </div>
        <div *ngIf="montoACobrar > 0" style="font-size: 1rem;">

          Costo del trámite: <span class="">Bs. {{montoACobrar}}.-</span><br>
          Total pagado: <span class="">Bs. {{importeTotalComprobantes +importeTotalONLINE}}.-</span>

          <div style="font-weight: 600; color: #ed2a56;">
            Saldo a pagar: <span class="">Bs. {{ (montoACobrar-importeTotalComprobantes > 0) ?
              montoACobrar-(importeTotalComprobantes+importeTotalONLINE) : '0'}}.-</span>
          </div>
        </div>
        <!-- <div class="col">Saldo a pagar: <span class="fw-bold" style="color: crimson;">Bs.
            {{montoACobrar - importeTotalComprobantes}}.-</span></div> -->
      </div>

      <div *ngIf="comprobantesCPT.length > 0">

        <div class="" style="display: flex;justify-content: space-between;">
          <div class="d-flex align-items-end">
            <p style="font-weight: 300; font-size: 1rem;">Comprobantes de pago por PPTE</p>
          </div>
        </div>

        <table>
          <tbody>
            <tr *ngFor="let comprobanteCPT of comprobantesCPT">
              <div class="comprobante-row">
                <td>
                  <mat-form-field appearance="outline">
                    <mat-label>Número de documento</mat-label>
                    <input matInput type="text" [value]="comprobanteCPT.numero_documento" readonly disabled
                      class="input-disabled">
                  </mat-form-field>
                </td>
                <td>
                  <mat-form-field appearance="outline">
                    <mat-label>Código CPT</mat-label>
                    <input matInput type="text" [value]="comprobanteCPT.cpt" readonly disabled class="input-disabled">
                  </mat-form-field>
                </td>
                <td>
                  <mat-form-field appearance="outline">
                    <mat-label>Fecha</mat-label>
                    <input matInput type="text" [value]="comprobanteCPT.fecha_alta" readonly disabled
                      class="input-disabled">
                  </mat-form-field>
                </td>
                <td>
                  <mat-form-field appearance="outline">
                    <mat-label>Importe Bs.</mat-label>
                    <input matInput type="text" [value]="comprobanteCPT.importe - 1" readonly disabled
                      class="input-disabled">
                  </mat-form-field>
                </td>

                <td></td>
              </div>
            </tr>
          </tbody>
        </table>

        <div class="row mt-2 mb-5 justify-content-end">
          <div class="col fw-semibold" style="display:inline-flex;align-items: center;justify-content: end;">
            <span>&nbsp;Importe total CPT: <span class="fw-bold">Bs. {{ importeTotalCPT }}.-</span></span>
          </div>
        </div>
      </div>

      <div *ngIf="comprobantesOnline.length > 0" style="font-size: 0.8rem;">
        <div class="" style="display: flex;justify-content: space-between;">
          <div class="d-flex align-items-end text-uppercase">
            <p style="font-weight: 300; font-size: 1rem;">Pago por Pasarela de Pagos Online</p>
          </div>
        </div>

        <table>
          <tbody>
            <tr *ngFor="let comprobanteOnline of comprobantesOnline">
              <div class="row">

                <div class="col-md-3">
                  <mat-form-field appearance="outline" class="header">
                    <mat-label>Número de documento</mat-label>
                    <input matInput type="text" [value]="comprobanteOnline.numero_documento" readonly>
                  </mat-form-field>
                </div>

                <div class="col-md-3">
                  <mat-form-field appearance="outline" class="header">
                    <mat-label>Fecha</mat-label>
                    <input matInput type="text" [value]="comprobanteOnline.fecha_pago_pasarela" readonly>
                  </mat-form-field>
                </div>
                <div class="col-md-3">
                  <mat-form-field appearance="outline" class="header">
                    <mat-label>Tipo de trámite</mat-label>
                    <input matInput type="text" [value]="comprobanteOnline.nombre_tipo_tramite" readonly>
                  </mat-form-field>
                </div>
                <div class="col-md-3">
                  <mat-form-field appearance="outline" class="header">
                    <mat-label>Importe Bs.</mat-label>
                    <input matInput type="text" [value]="comprobanteOnline.monto_pagado - 1" readonly>
                  </mat-form-field>
                </div>

              </div>
            </tr>
          </tbody>
        </table>

        <div class="row mt-2 mb-5 justify-content-end">
          <div class="col fw-semibold" style="display:inline-flex;align-items: center;justify-content: end;">
            <span>&nbsp;IMPORTE TOTAL DE PAGO ONLINE: <span class="fw-bold">Bs. {{ importeTotalONLINE }}.-</span></span>
          </div>
        </div>
      </div>

      <div [formGroup]="comprobantesForm" style="font-size: 0.8rem;">
        <div class="my-3" style="display: flex;justify-content: space-between;"
          *ngIf="comprobantesBancarios.value.length > 0">
          <div class="d-flex align-items-end text-uppercase">
            <p style="font-weight: 300; font-size: 1rem;">Comprobantes de pago por boleta</p>
          </div>

          <div class="d-flex align-items-center">
            <button mat-raised-button class="nuevo-button" (click)="agregarComprobante()"
              matTooltip="Agregar comprobante">
              <mat-icon>add_card</mat-icon>
              &nbsp;Agregar comprobante
            </button>
          </div>
        </div>

        <div *ngIf="comprobantesBancarios.value.length > 0; then nonEmptyBlock else emptyBlock"></div>

        <ng-template #nonEmptyBlock>
          <div class="table-responsive">
            <table>
              <tbody formArrayName="comprobantes"
                *ngFor="let comprobanteForm of comprobantesBancarios.controls; let i=index;">
                <!-- <pre>{{comprobanteForm.value | json}}</pre> -->
                <tr [formGroupName]="i">
                  <!--  <div class="comprobante-row" -->
                  <div class="comprobante-row"
                    [ngClass]="{'registro-eliminado': comprobanteForm.value.accion == 'eliminar'}">
                    <td>
                      <mat-form-field appearance="outline">
                        <mat-label>Entidad bancaria</mat-label>
                        <mat-select formControlName="banco" [disabled]="comprobanteForm.value.id_estado_comprobante==0"
                          [readonly]="comprobanteForm.value.id_estado_comprobante==0">
                          <mat-option [value]="banco.idBanco" *ngFor="let banco of bancos">{{banco.nombre}}</mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field appearance="outline">
                        <mat-label>Tipo de transacción</mat-label>
                        <mat-select formControlName="tipoTransaccion"
                          (selectionChange)="enableCuenta($event, comprobanteForm)"
                          [disabled]="comprobanteForm.value.id_estado_comprobante==0"
                          [readonly]="comprobanteForm.value.id_estado_comprobante==0">
                          <mat-option [value]="tipoComprobante.idTipo" *ngFor="let tipoComprobante of tipoComprobantes">
                            {{tipoComprobante.nombre}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </td>
                    <td>
                      <mat-form-field appearance="outline">
                        <mat-label>Cuenta</mat-label>
                        <input matInput type="text" formControlName="cuenta"
                          [disabled]="comprobanteForm.value.id_estado_comprobante==0"
                          [readonly]="comprobanteForm.value.id_estado_comprobante==0">
                      </mat-form-field>

                      <mat-form-field appearance="outline">
                        <mat-label>Nro. comprobante</mat-label>
                        <input matInput type="number" formControlName="comprobante" min="0"
                          [disabled]="comprobanteForm.value.id_estado_comprobante==0"
                          [readonly]="comprobanteForm.value.id_estado_comprobante==0">
                      </mat-form-field>
                    </td>
                    <td>
                      <mat-form-field appearance="outline">
                        <mat-label>Importe Bs.</mat-label>
                        <input matInput type="number" min="0" formControlName="importe"
                          (change)="verificarImporteTotal()" [disabled]="comprobanteForm.value.id_estado_comprobante==0"
                          [readonly]="comprobanteForm.value.id_estado_comprobante==0">
                      </mat-form-field>

                      <mat-form-field appearance="outline">
                        <mat-label>Fecha</mat-label>
                        <input matInput type="date" formControlName="fecha" [max]="todayDate"
                          [disabled]="comprobanteForm.value.id_estado_comprobante==0"
                          [readonly]="comprobanteForm.value.id_estado_comprobante==0">
                      </mat-form-field>
                    </td>
                    <td>
                      <mat-form-field appearance="outline">
                        <mat-label>Glosa</mat-label>
                        <input matInput class="text-uppercase" type="text" formControlName="glosa"
                          [disabled]="comprobanteForm.value.id_estado_comprobante==0"
                          [readonly]="comprobanteForm.value.id_estado_comprobante==0">
                      </mat-form-field>
                    </td>
                    <td>
                      <div *ngIf="comprobanteForm.value.accion!=accionEliminar">

                        <!-- <div class="icon-block">
                        <button mat-icon-button
                          [ngClass]="comprobanteForm.value.base64 == null ? 'file-not-uploaded' : 'file-uploaded'"
                          (click)="adjuntarDocumento(comprobanteForm, j)" matTooltip="{{ comprobanteForm.value.base64 == null ? 'Adjuntar comprobante y guardar' : 'Cambiar
                          comprobante' }}">
                          <mat-icon style="font-size:20px;">{{ comprobanteForm.value.base64 == null ?
                            'attach_file' : 'attach_file'}}</mat-icon>
                        </button>
                      </div> -->


                        <!-- <div class="icon-block">
                        <label class="file-icon" matTooltip="Eliminar documento"
                          (click)="eliminarDocumento(docAdicionalForm, j)">
                          <mat-icon class="delete-icon">delete_outline</mat-icon>
                        </label>
                      </div> -->

                        <!-- <div class="icon-block"> -->
                        <!-- <label for="attachedFile{{i}}"
                          [ngClass]="comprobanteForm.value.base64 == null ? 'file-not-uploaded' : 'file-uploaded'"
                          matTooltip="Adjuntar comprobante" *ngIf="!comprobanteForm.value.base64">
                          <mat-icon >attach_file</mat-icon>
                        </label> -->
                        <!-- </div> -->
                        <!-- <label for="attachedFile{{i}}" class="file-icon" matTooltip="Adjuntar comprobante"
                        *ngIf="!comprobanteForm.value.base64">
                        <mat-icon style="color: #34b0a6">attach_file</mat-icon>
                      </label> -->

                        <div>&nbsp;
                          <label for="attachedFile{{i}}"
                            [ngClass]="comprobanteForm.value.base64 == null ? 'file-not-uploaded' : comprobanteForm.value.id_estado_comprobante==0 ? 'file-disabled' : 'file-uploaded'"
                            matTooltip="{{ comprobanteForm.value.base64 ? 'Actualizar comprobante' : 'Adjuntar comprobante' }}">
                            <mat-icon [disabled]="comprobanteForm.value.id_estado_comprobante==0" height="26"
                              width="26">attach_file</mat-icon>
                          </label>
                          <!-- <small style="font-size:10px;">Cargado</small> -->
                        </div>

                        <!-- <div class="icon-block"> -->
                        <button mat-icon-button (click)="verDocumento(comprobanteForm)" matTooltip="Ver comprobante"
                          [disabled]="comprobanteForm.value.base64 == null">
                          <img class="color-print" src="assets/svg/visibility_white.svg" />
                        </button>
                        <!-- </div> -->

                        <button mat-icon-button (click)="eliminarComprobante(i, comprobanteForm)"
                          matTooltip="Eliminar comprobante" [disabled]="comprobanteForm.value.id_estado_comprobante==0">
                          <img class="color-delete" src="assets/svg/delete_forever.svg" />
                        </button>

                        <input type="file" name="attachedFile{{i}}" id="attachedFile{{i}}"
                          (change)="adjuntarArchivo($event, comprobanteForm)" style="display: none;" accept=".pdf"
                          [disabled]="comprobanteForm.value.id_estado_comprobante==0">

                      </div>
                      <div style="display: flex; align-items: center;"
                        *ngIf="comprobanteForm.value.accion==accionEliminar">
                        <button mat-icon-button (click)="cancelarEliminacion(comprobanteForm)"
                          matTooltip="Cancelar eliminación de comprobante">
                          <mat-icon>library_add_check</mat-icon>
                        </button>
                      </div>
                    </td>
                  </div>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="row my-3 justify-content-end">
            <div class="col fw-semibold" style="display:inline-flex;align-items: center;justify-content: end;">
              <span>&nbsp;IMPORTE TOTAL DE COMPROBANTES: <span class="fw-bold" style="font-weight: 600;">Bs.
                  {{importeTotalComprobantes}}.-</span></span>
            </div>
          </div>
        </ng-template>

        <ng-template #emptyBlock>
          <div
            style="display:flex; flex-direction:column; align-items:center; justify-content:center; height:225px; margin-top: 5rem;">
            <img [src]="montoACobrar == 0 ? 'assets/images/no-cost.png' : 'assets/images/empty-vouchers.png'"
              alt="Ningun comprobante de pago cargado">
            <span style="font-size:11px;color:#838383; margin-top:1rem;">
              {{ montoACobrar == 0 ? 'Presione el botón "Continuar" para terminar' : 'Aún no ha agregado ningún
              comprobante de pago' }}
            </span>
            <button mat-raised-button class="nuevo-button" aria-label="Agregar comprobante"
              *ngIf="!importeDBEstaCompleto" (click)="agregarComprobante()">
              <mat-icon>add_photo_alternate</mat-icon>
              &nbsp;Agregar comprobante
            </button>
            <br>
            <button mat-raised-button class="nuevo-button" matStepperNext (click)="guardarComprobantes()"
              *ngIf="importeDBEstaCompleto">
              {{ montoACobrar == 0 ? 'Continuar' : textoBoton }}
            </button>

            <button mat-raised-button matStepperPrevious class="edit-button"
              *ngIf="!importeDBEstaCompleto">Anterior</button>
            <button mat-raised-button mat-dialog-close color="warn" style="height: 45px;" (click)="cerrar()"
              *ngIf="importeDBEstaCompleto"><mat-icon>cancel</mat-icon>Cancelar</button>
          </div>
        </ng-template>

        <div class="row mt-5 mb-3 justify-content-between" *ngIf="comprobantesBancarios.value.length > 0">
          <!-- <div class="row mt-5 mb-3 justify-content-between" *ngIf="importeTotal > 0"> -->
          <!--div class="col fs-5">Costo del trámite: <span class="fw-bold">Bs. {{montoACobrar}}.-</span></div-->
          <div class="col fs-5" style="display:inline-flex;align-items: center;justify-content: end;">
            <mat-icon style="color:#28e075;" *ngIf="importeEstaCompleto">verified</mat-icon>
            <span class="text-uppercase">&nbsp;Importe total: <span class="fw-bold">Bs. {{importeTotal}}.-</span></span>
          </div>
        </div>

        <div mat-dialog-actions class="mt-3 mb-1" *ngIf="comprobantesBancarios.value.length > 0">

          <button mat-raised-button class="justify-content-end edit-button" *ngIf="!importeDBEstaCompleto"
            matStepperPrevious>
            <mat-icon>keyboard_arrow_left</mat-icon>Anterior
          </button>
          <!-- <button mat-flat-button matStepperPrevious class="mx-5" *ngIf="!importeDBEstaCompleto">Atrás</button> -->

          <button mat-raised-button class="nuevo-button" matStepperNext (click)="guardarComprobantes()"
            [disabled]="comprobantesForm.invalid || comprobantesBancarios.value.length == 0">
            <mat-icon>save</mat-icon>{{textoBoton}}</button>
          <button mat-raised-button color="warn" style="height: 45px;" mat-dialog-close (click)="cerrar()"
            *ngIf="importeDBEstaCompleto">
            <mat-icon>cancel</mat-icon>Cancelar</button>
        </div>
      </div>
    </mat-step>
  </mat-horizontal-stepper>

  <div class="pago" [ngClass]="importeEstaCompleto ? 'pago-completo' : 'pago-incompleto'">
    <span>{{ importeEstaCompleto ? (montoACobrar == 0 ? 'TRAMITE SIN COSTO' : 'PAGO COMPLETO') : 'PAGO INCOMPLETO'
      }}</span>
  </div>


</div>