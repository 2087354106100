import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { UsuarioService } from "src/app/modules/auth/services/usuario.service";
import Swal from "sweetalert2";
import { Router } from "@angular/router";
import { lastValueFrom } from "rxjs";

@Component({
  selector: "app-seleccionar-registro",
  templateUrl: "./seleccionar-registro.component.html",
  styleUrls: ["./seleccionar-registro.component.scss"],
})
export class SeleccionarRegistroComponent implements OnInit {
  loading: boolean = false;
  public numeroForm = this.fb.group({
    registro: ["", Validators.required],
  });

  constructor(
    private router: Router,
    private usuarioService: UsuarioService,
    private fb: FormBuilder
  ) { }
  eliminarEspacios() {
    const campo1 = this.numeroForm.get('registro');
    if (campo1.value) {
      campo1.setValue(campo1.value.trim());
    }   
  }
  async ngOnInit() {
/*     try {
      var estaAutenticado = await this.usuarioService.validarToken().toPromise();
    } catch (error) {
      Swal.fire("Error", error.error.mensaje, "error");
      if (error.error.mensaje == "Token requerido" || error.error.mensaje == "Token expirado") {
        this.router.navigateByUrl("/");
      }
    }

    if (!estaAutenticado)
      this.router.navigateByUrl("/"); */
  }

  async continuar() {
    this.loading = true;
    // Validamos el formulario
    if (this.numeroForm.invalid) {
      return;
    }

    if (this.numeroForm.value.registro.length != 14) {
      Swal.fire("Error", "N° Registro incorrecto", "error");
      return;
    }

/*     try {
      var estaAutenticado = await this.usuarioService.validarToken().toPromise();
    } catch (error) {
      Swal.fire("", error.error.mensaje, "error");
      this.router.navigateByUrl("/");
      return;
    }

    if (!estaAutenticado)
      this.router.navigateByUrl("/"); */
//console.log('antes de api seleccionarRegistroAdministrado')
    try {
      var resp = await lastValueFrom(this.usuarioService.seleccionarRegistroAdministrado(this.numeroForm.value.registro))
      console.log('despues de api seleccionarRegistroAdministrado')
       //en resp tenemos si el servicio nos valido correctamente los datos
   // if (resp.datos){
      if(this.usuarioService.usuario.idReg.length>0){
       
        this.router.navigateByUrl("/registro/ver");
      }else{
        
        this.router.navigateByUrl("/dashboard");
        Swal.fire({
          toast: true,
          position: "top-right",
          showConfirmButton: false,
          imageUrl: "assets/images/iconofinal.png",
         // icon: 'success',
         // timerProgressBar,
          timer: 2000,
          title: 'BIENVENIDO(A) A ESTADO DIGITAL 6',
          customClass: {
            image: 'snack-bar-color'
          }
        })  
    
      }
  //  }
    } catch (error) {      
      this.router.navigateByUrl("/");
      console.log(error)
      Swal.fire("Error", error.error.mensaje, "error");
      return;
    }

   
  }
}
