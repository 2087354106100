<div class="auth-container__body">
  <div class="body__lead">
    <!-- <h2 class="body__title">Número de Registro</h2> -->
   
  </div>
<!--   <div class="spinner-container" *ngIf="loading">
    <mat-spinner></mat-spinner>
  </div> -->
  <div class="auth-container__form">
    <form [formGroup]="numeroForm" (ngSubmit)="continuar()" autocomplete="off">
      <p class="body__legend">Ingrese número de registro digital</p>
      <mat-form-field appearance="outline" class="header">
        <mat-label>N° de Registro</mat-label>
        <input id="btnSearch" matInput placeholder="N° de registro" formControlName="registro" name="registro" class="text-uppercase"
          autocomplete="true" onkeydown="if (event.keyCode == 13) document.getElementById('btnSearch').click()" maxlength=14 (input)="eliminarEspacios()" >
        </mat-form-field>
      <button mat-raised-button color="primary" class="button-login" type="submit" [disabled]="numeroForm.invalid">
        Continuar
      </button>
    </form>
  </div>
</div>

<div class="auth-container__footer">
  <div class="auth-container__footer footer__option">
    ¿Todavia no eres un administrado?
    <a routerLink="/dashboard" >Ingresar como invitado</a><br>
    Volver a
    <a routerLink="/login" > Inicio sesión</a>
  </div>
</div>
