import { Injectable } from '@angular/core';
import { get } from 'lodash';
import { Usuario } from '../models/usuario.model';

@Injectable({
  providedIn: 'root'
})
export class CredentialsService {

  public renovar:boolean = false

  constructor() { }

  /**
   * Get current token from session storage
   *
   * @returns {string}
   * @memberof CredentialsService
   */
  public getToken(): string {
    return localStorage.getItem('token');
  }

  /**
   * Get Logged user credentials from session storafe
   *
   * @returns {object}
   * @memberof CredentialsService
   */
  public getLoggedUser(): Usuario {
    return JSON.parse(localStorage.getItem('usuario'));
  }

  /**
   * Set TokenAuth Credentials to session storage
   *
   * @template T
   * @param {T} payload
   * @memberof CredentialsService
   */
  public setTokenAuth<T>(payload: T): void {
    //localStorage.setItem('token', get(payload, 'token', null));
    //localStorage.setItem('token', payload.toString());
//    console.log(payload);
    localStorage.setItem('token', payload.toString());
  }


  /**
   * Set User Credentials to session storage
   *
   * @template T
   * @param {T} payload
   * @memberof CredentialsService
   */
  public setUserAuth<T>(payload: T): void {
    localStorage.setItem('usuario', JSON.stringify(payload));
  }

  public setUserPermissions<T>(payload: T): void {
    localStorage.setItem('permissions', JSON.stringify(payload));
  }

  public getUserPermissions(): object {
    return JSON.parse(localStorage.getItem('permissions'));
  }

  /**
   * Remove User Credentials from session storage
   *
   * @return void
   * @memberof CredentialsService
   */
  public removeCredentials(): void {
    localStorage.removeItem('token');
    localStorage.removeItem('usuario');
    localStorage.removeItem('menus');
    localStorage.removeItem('ruta');
  }
}
