import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseHttpService } from 'src/app/core/services/_base-http.service';
/**
 * Inyectable que permite la comunicacion con el api backend de aduana
 */
@Injectable({
  providedIn: 'root'
})
export class AduanaService {
  /**
   * url de servico backend de aduana
   */
  localUrl = 'licencias-previas/aduana';
  /**
   * Constructor del listado de aduana
   */
  constructor(
          /**
   * metodo de servicio http
   */
    private baseHttpService: BaseHttpService) { }
   /**
   * Método que obtiene el listado aduana
   */ 
  public obtenerTodos(): Observable<any> {
    return this.baseHttpService.get(`${this.localUrl}/obtenertodos`);
  }
   /**
   * Método que obtiene el listado aduana
   */ 
  public get(id: string|number): Observable<any> {
    return this.baseHttpService.get(`${this.localUrl}/show/${id}`);
  }
   /**
   * Método que crea datos de aduana
   */ 
  public crear(data: any): Observable<any>{
    return this.baseHttpService.post(`${this.localUrl}/crear`, data);
  }
   /**
   * Método que actualiza datos de aduana
   */ 
  public actualizar(id: string|number, data: any): Observable<any> {
    return this.baseHttpService.put(`${this.localUrl}/modificar/` + id, data);
  }
   /**
   * Método que elimina datos de aduana 
   */ 
  public eliminar(id: string|number): Observable<any> {
    return this.baseHttpService.delete(`${this.localUrl}/eliminar/` + id);
  }
}