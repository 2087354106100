import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ActivatedRoute, Params } from '@angular/router';

import Swal from 'sweetalert2';
import { UsuarioService } from 'src/app/modules/auth/services/usuario.service';


@Component({
  selector: 'app-restablecerc',
  templateUrl: './restablecerc.component.html',
  styleUrls: ['./restablecerc.component.scss']
})
export class RestablecercComponent implements OnInit {
  public hide1 = true;
  public hide2 = true;
  public forma: FormGroup;
  public token: string;


  constructor(private router: Router,
    private fb: FormBuilder,
    private usuarioService: UsuarioService,
    private rutaActiva: ActivatedRoute) { }

  async ngOnInit() {
    //verificamos si el token es valido aun
    this.token = this.rutaActiva.snapshot.params.parametro;
    if (!this.token) {
      Swal.fire('Error', 'Datos incorrectos', 'error');
      this.router.navigateByUrl('/login');
      return;
    }

    try {
      var resp = await this.usuarioService.existeToken(this.token).toPromise();
    } catch (error) {
      Swal.fire('Error', error.error.mensaje, 'error');
      this.router.navigateByUrl('/login');
      return;
    }

    if (resp)
      console.log('Token revisado');

    this.forma = new FormGroup({
      claveNueva: new FormControl(null, Validators.required),
      claveconfirmacion: new FormControl(null, Validators.required),
      condiciones: new FormControl(false)
    }, { validators: this.sonIguales('claveNueva', 'claveconfirmacion') });
  }

  sonIguales(campo1: string, campo2: string) {

    return (group: FormGroup) => {
      let pass1 = group.controls[campo1].value;
      let pass2 = group.controls[campo2].value;
      if (pass1 === pass2) {
        return null;
      }
      return {
        sonIguales: true
      };
    };
  }

  async restablecerC() {
    if (this.forma.invalid) {
      return;
    }
    try {
      var resp = await this.usuarioService
        .restablecerClave({ claveNueva: this.forma.value.claveNueva, idUsuario: '' })
        .toPromise();
    } catch (error) {
      Swal.fire('', error.error.mensaje, 'error');
      return;
    }

    Swal.fire('', resp.mensaje, 'info');
    this.router.navigateByUrl('/login');
  }
}
