import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseHttpService } from 'src/app/core/services/base-http.service';

@Injectable({
  providedIn: 'root'
})
export class EnvaseService {

  localUrl = 'parametricas/envase';

  constructor(private baseHttpService: BaseHttpService) { }

  public obtenerTodos(): Observable<any> {
    return this.baseHttpService.getSistema(`${this.localUrl}/obtenertodos`);
  }

  public get(id: string|number): Observable<any> {
    return this.baseHttpService.get(`${this.localUrl}/show/${id}`);
  }

  public crear(data: any): Observable<any>{
    return this.baseHttpService.postSistema(`${this.localUrl}/crear`, data);
  }

  public modificar(id: string|number, data: any): Observable<any> {
    return this.baseHttpService.put(`${this.localUrl}/modificar/` + id, data);
  }

  public eliminar(id: string|number): Observable<any> {
    return this.baseHttpService.delete(`${this.localUrl}/eliminar/` + id);
  }
}
