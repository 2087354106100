
<mat-card>
    <div>
      <mat-card-title class="card-header">{{ titulo }}</mat-card-title>
    </div>
    <mat-card-content>
        <iframe #iframe class="iframe-tamano" [src]="url" allowfullscreen ></iframe>
        
       <!--  <p>URL actual: {{ url }}</p> -->
    </mat-card-content>
  
    <div mat-dialog-actions class="mt-3 mb-1">
      <button mat-raised-button color="warn" (click)="cancelar()">
        <mat-icon>cancel</mat-icon> Cerrar
      </button>
    </div>
  </mat-card>