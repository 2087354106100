import { FormularioCodigoActivacionComponent } from './../../components/formulario-codigo-activacion/formulario-codigo-activacion.component';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { UsuarioService } from 'src/app/modules/auth/services/usuario.service';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-logindgsc',
  templateUrl: './logindgsc.component.html',
  styleUrls: ['./logindgsc.component.scss']
})
export class LogindgscComponent implements OnInit {
  public isProduction = environment.production
  public hide = true;
  public registro_num: string = '';
  public nombre: string = '';

  loginForm = this.fb.group({
    cuenta: ['', [Validators.required,Validators.pattern(/^(?!\s)(?!.*\s$)(?!.*').*$/)]],
    clave: ['', Validators.required],
  });

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private usuarioService: UsuarioService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.usuarioService.limpiarSesion();
  }
  // Función para eliminar espacios en blanco
  eliminarEspacios() {
    const campo1 = this.loginForm.get('cuenta');
    if (campo1.value) {
      campo1.setValue(campo1.value.trim());
    }
    const campo2 = this.loginForm.get('clave');
    if (campo2.value) {
      campo2.setValue(campo2.value.trim());
    }
  }
  async login() {
    if (this.loginForm.invalid)
      return;

    try {
      var datos = await this.usuarioService.loginDGSC(this.loginForm.value).toPromise();
    } catch (error) {
      console.log('error');
      if (error.error.mensaje == 'Inicie sesión con ciudadanía digital') {
        this.router.navigateByUrl('/');
      }
      Swal.fire('Error', error.error.mensaje, 'error');
      return;
    }

    this.router.navigateByUrl('/seleccionar-registro');
  }

  solicitarCodigoActivacion() {
    this.dialog.open(FormularioCodigoActivacionComponent)
  }

}
