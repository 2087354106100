<button mat-icon-button class="close-button" [mat-dialog-close]="true" (click)="cancelar()">
    <mat-icon class="close-icon">close</mat-icon>
</button>

<div class="row mt-2">
    <div class="col text-center">
        <h2 >{{titulo}}</h2>        
    </div>
    <div><p style="font-size: 13px;">{{subtitulo}}</p></div>
    <div style="text-align: left;"><span style="font-size: 13px; font-weight: 500;">* {{documentTitle}} *</span></div>
</div>
<div class="row">
    <div class="col" style="height: 370px;">
        <app-pdf-viewer [source]="source" [base64]="base64" [url]="url"></app-pdf-viewer>
    </div>
</div>
<div class="row" *ngIf="pdfBase64Array.length > 0">
    <div class="col text-center">
        <button mat-flat-button class="mx-5" (click)="previousNextPdf(-1)" style="font-weight: normal;font-size:12px;color: #237871;">
            <mat-icon>keyboard_double_arrow_left</mat-icon>
            <span >Anterior</span>
        </button>
        <button mat-flat-button class="mx-5" (click)="previousNextPdf(1)" style="font-weight: normal;font-size:12px;color: #237871;">
            Siguiente
            <mat-icon>keyboard_double_arrow_right</mat-icon>
        </button>
    </div>
</div>

<div class="row">
    <div class="col text-end">
        <button mat-flat-button class="mx-5" (click)="cancelar()">Cancelar</button>
        <button mat-flat-button color="primary" (click)="aceptar()"  *ngIf="textoBoton!='nada'">{{textoBoton}}</button>
    </div>
</div>