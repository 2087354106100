import { Parametricas } from 'src/app/core/enums/parametricas.enums';
import { UsuarioService } from 'src/app/modules/auth/services/usuario.service';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';
import Swal from 'sweetalert2';
import { lastValueFrom } from 'rxjs';
import { CodigoVerificacionService } from '../../services/codigo-verificacion.service';

@Component({
  selector: 'app-formulario-codigo-activacion',
  templateUrl: './formulario-codigo-activacion.component.html',
  styleUrls: ['./formulario-codigo-activacion.component.scss']
})
export class FormularioCodigoActivacionComponent implements OnInit {

  @ViewChild("stepper") stepper

  requestCodeForm = this.formBuilder.group({
    email: [null, [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]]
  })

  activationCodeForm = this.formBuilder.group({
    codigo: [null, Validators.required],
    idUsuario: [null, Validators.required]
  })

  constructor(
    private usuarioService: UsuarioService,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<FormularioCodigoActivacionComponent>,
    public codigoVerificacionService: CodigoVerificacionService
  ) { }

  ngOnInit(): void {
    this.dialogRef.updateSize('450px', '575px')
    this.dialogRef.disableClose = true
  }

  async solicitarCodigo() {
    console.log('Solicitando nuevo codigo de activacion')
    const data = Object.assign({ tipoCodigo: Parametricas.ACTIVACION }, this.requestCodeForm.value)

    try {
      var codigoResp = await this.codigoVerificacionService.solicitarCodigo(data)
    } catch (error) {
      return
    }

    this.activationCodeForm.patchValue({ idUsuario: codigoResp.id_usuario })
    this.stepper.next()
  }

  async verificarCodigo() {
    console.log('Verifyng')

    if (this.activationCodeForm.invalid)
      return

    // Enviar al Backend para verificar el codigo de activacion
    Swal.fire({ title: 'Espere por favor', text: 'Verificando codigo', icon: 'info', allowOutsideClick: false })
    Swal.showLoading()

    try {
      await lastValueFrom(this.usuarioService.activarUsuarioNuevo(this.activationCodeForm.value))
    } catch (error) {
      Swal.fire({ text: error.error.mensaje, icon: 'warning', confirmButtonText: 'Aceptar' })
      return
    }

    Swal.fire({ title: 'Usuario activado y verificado correctamente', text: 'Por favor inicie sesión con su usuario y contraseña', icon: 'success', confirmButtonText: 'Aceptar' })
    this.dialogRef.close()
  }

}
