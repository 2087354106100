<div class="auth-container__body"> 

        <div class="modo-prueba d-flex justify-content-center" *ngIf="!isProduction">
            <span style="cursor:default">
               ENTORNO DE PRUEBAS<br>
               &nbsp; MODO DESARROLLO
            </span>
        </div>    
   
    <div class="auth-container__form">
        <form [formGroup]="loginForm" novalidate (ngSubmit)="login()" autocomplete="off">
            
            <p >Ingrese sus credenciales de acceso</p> 
            <mat-form-field appearance="outline"  class="header">
                <mat-label>Usuario de Acceso</mat-label>
                <input matInput placeholder="Usuario de Acceso" formControlName="cuenta" (input)="eliminarEspacios()"
                    autocomplete="true">
            </mat-form-field>

            <mat-form-field appearance="outline"  class="header">
                <mat-label>Contraseña de Acceso</mat-label>
                <input [type]="hide ? 'password' : 'text'" matInput placeholder="Contraseña de Acceso"
                    formControlName="clave" name="clave" autocomplete="off" (input)="eliminarEspacios()">
                <button type="button" mat-icon-button matSuffix (click)="hide = !hide"
                    [attr.aria-label]="'Ocultar clave'" [attr.aria-pressed]="hide">
                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
            </mat-form-field>

            <button mat-raised-button  class="button-login nuevo-button" type="submit" [disabled]="loginForm.invalid">
                INGRESAR
            </button>
        </form>
    </div>
</div>

<div class="auth-container__footer">
    <div class="footer__option">
        ¿No tiene cuenta?
        <!-- <a routerLink="/verifica">Habilita tu cuenta</a> -->
        <a routerLink="/registro-usuario">Regístrate</a>
    </div>
    <div class="footer__option">
        ¿Olvido su contraseña?
        <a routerLink="/recuperar-clave">Restablecer</a>
    </div>
    <div class="footer__option">
        <a (click)="solicitarCodigoActivacion()">Habilita tu cuenta</a>
    </div>
    <br>
    <div class="row">
    <div class="footer__option">
        <iframe width="185" height="104" src="https://www.youtube.com/embed/IEMkYG4I_w4?si=3nakCSHjoYuGX0xG&amp;start=5" title="Creación de Usuario" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </div>
    <div class="footer__option">
        <mat-icon>subscriptions</mat-icon>
        <a href="https://www.youtube.com/@DireccionGeneraldeSustanciasCo" target="_blank" style="color: #34b0a6">
            <small style="color: #34b0a6">Otros Videotutoriales</small>
        </a>
    </div>
    <!-- 
        <div class="col-7">
        <a href="assets\manual\manual_usuario_sircof_v1.0.pdf" download style="color: #34b0a6">
            <small style="color: #34b0a6">Descargar manual de usuario</small>
        </a>
    </div> -->
    </div>
</div>