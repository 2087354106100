<mat-toolbar>
    <div class="modo-prueba" *ngIf="dgsc">
        <span style="cursor:default">
            ENTORNO DE PRUEBAS DGSC
        </span>
    </div>

    <button mat-icon-button (click)="drawer.toggle()">
        <mat-icon style="color: #34b0a6;">dashboard</mat-icon>
        <!--  <img src="assets/images/svg/app-menu-svgrepo.svg" alt="" > -->
        <!--  <mat-icon>more_vert</mat-icon> -->
    </button>

    <div class="dashboard-logo">
        <a routerLink="/dashboard">
            <img src="assets/images/iconofinal.png" alt="SIRCOF" class="dashboard-logo__image" width="40" height="40" />
        </a>
    </div>

    <a routerLink="/dashboard" class="dashboard-logo__title"><strong>ED-6 Oficina Virtual</strong> </a>
    <span class="example-spacer"></span>

    <span matTooltip="Sesión iniciada con Ciudadania Digital" class="mx-3" *ngIf="isLoggedCD"
        style="cursor:default">CD</span>

    <div class="mat-toolbar__username">
        <ng-container *ngIf="usuario.rz && usuario.num; else showCiAndCorreo">
            <strong>
                {{ (usuario.nombre === usuario.rz) ? (usuario.nombre) : usuario.nombre + ' (' + usuario.cuenta + ')' }}
                <br>
                {{ usuario.rz }}
                <br>
                {{ usuario.num }} ({{ usuario.of }})
            </strong>
        </ng-container>
        <ng-template #showCiAndCorreo>
            <strong>{{ usuario.nombre }} <br> {{ usuario.ci }} <br> {{ usuario.correo }}</strong>
        </ng-template>
    </div>

    <div class="toolbar__initial-background" [matMenuTriggerFor]="perfil">
        <div class="toolbar__initial-user" matTooltip="Opciones de Sesión">
            <span>{{_usuarioService.usuario.nombre.charAt(0)}}</span>
            <mat-icon style="color: #fff; font-size: 20px">arrow_drop_down</mat-icon>
        </div>
    </div>

    <mat-menu #perfil="matMenu" class="profile-menu">
        <!-- <button mat-menu-item routerLink="/home/dashboard">
      <mat-icon aria-hidden="false">account_circle</mat-icon>
      <span>Perfil</span>
    </button> -->
        <!--         <button mat-menu-item routerLink="/cambiar-clave/{{this.router.url}}" *ngIf="!isLoggedCD">
            <mat-icon aria-hidden="false">vpn_key</mat-icon>
            <span>Cambiar Contraseña</span>
        </button> -->


        <!--  <div style="text-align: center; margin:0.8em;">
            <img mat-card-image src="assets/svg/account-avatar-profile-user-6-svgrepo-com.svg" alt="" height="25"
                width="25">
            <div style="text-align: center;">
                <span style="font-size: 0.7rem;">
                    {{usuario.nombre}}<br>{{usuario.rz}} ({{usuario.num}})</span>
            </div>
        </div>
    
    
        <mat-divider></mat-divider> -->
        <button mat-menu-item (click)="cambiarClaveUsuario()" *ngIf="!isLoggedCD">
            <mat-icon aria-hidden="false">settings</mat-icon>
            <span>Cambiar Contraseña</span>
        </button>
        <button mat-menu-item (click)="_usuarioService.logout()">
            <mat-icon>power_settings_new</mat-icon>
            <span>Salir</span>
        </button>
    </mat-menu>
</mat-toolbar>


<mat-drawer-container>

    <mat-drawer mode="side" #drawer class="sidenav-container" fixedInViewport
        [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="(isHandset$ | async) ? 'over' : 'side'"
        [opened]="(isHandset$ | async) === false" color="mat-blue">

        <mat-nav-list>
            <br>
            <a mat-list-item class="menu__item--parent my-4" routerLink="/dashboard" routerLinkActive="activo">
                <mat-icon aria-hidden="false" class="sidebar__icon">desktop_mac</mat-icon>
                <span routerLink="/dashboard">Inicio</span>
            </a>
            <a mat-list-item class="menu__item--parent my-4" (click)="buscarRegistro()" *ngIf="verFormRegistro">
                <mat-icon aria-hidden="false" class="sidebar__icon">article</mat-icon>
                <span>Ver Formulario de Registro</span>
            </a>
            <a mat-list-item class="menu__item--parent my-4" (click)="buscarCertificadoRegistro()" *ngIf="verFormRegistro">
                <mat-icon aria-hidden="false" class="sidebar__icon">picture_as_pdf</mat-icon>
                <span>Ver Certificado de Registro</span>
            </a>
            <div *ngFor="let menu of menus">
                <a mat-list-item (click)="clickMenu(menu)" class="menu__item--parent"
                    routerLinkActive="menu.mostrar ? 'activo' : null">
                    <mat-icon aria-hidden="false" class="sidebar__icon">{{menu.img}}</mat-icon>
                    <span style="margin-right: 1rem">{{menu.nombre}}</span>
                    <span style="display: inline-flex; position: absolute; right: 0;">
                        <mat-icon style="font-size: 20px; height: 20px;">{{ menu.mostrar ? 'expand_more' :
                            'chevron_right' }}</mat-icon>
                    </span>
                </a>
                <div *ngIf="menu.submenu.length > 0 && menu.mostrar">
                    <a mat-list-item *ngFor="let submenu of menu.submenu" class="submenu__item--child"
                        [routerLink]="submenu.ruta" [queryParams]="submenu.params" routerLinkActive="activo">
                        <span>{{submenu.nombre}}</span>
                    </a>
                </div>
            </div>
        </mat-nav-list>

    </mat-drawer>

    <mat-drawer-content>
        <div class="main-container">
            <router-outlet></router-outlet>
        </div>
    </mat-drawer-content>

</mat-drawer-container>