import { NavbarComponent } from './navbar/navbar.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { MaterialModule } from 'src/app/material.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PinFDComponent } from './pin-fd/pin-fd.component';
import { TransaccionPagoComponent } from './transaccion-pago/transaccion-pago.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VisorPdfDialogoComponent } from './visor-pdf-dialogo/visor-pdf-dialogo.component';
import { VisorPdfMultipleComponent } from './visor-pdf-multiple/visor-pdf-multiple.component';
import { CargaDocumentosComponent } from './carga-documentos/carga-documentos.component';
import { CargaImagenesComponent } from './carga-imagenes/carga-imagenes.component';
import { NavbarPdfComponent } from './navbar-pdf/navbar-pdf.component';
import { TipoEntidadComponent } from './tipo-entidad/tipo-entidad.component';
import { TokenAppComponent } from './token-app/token-app.component';
import { PagoLibelulaComponent } from './pago-libelula/pago-libelula.component';
import { EditarDocumentosComponent } from './editar-documentos/editar-documentos.component';
import { PrimeraImportacionComponent } from './primera-importacion/primera-importacion.component';
import { PrimeraComercializacionComponent } from './primera-comercializacion/primera-comercializacion.component';
import { ImportacionComercializacionComponent } from './importacion-comercializacion/importacion-comercializacion.component';


@NgModule({
  declarations: [
    PageNotFoundComponent,
    PinFDComponent,
    TransaccionPagoComponent,
    VisorPdfDialogoComponent,
    VisorPdfMultipleComponent,
    NavbarComponent,
    CargaDocumentosComponent,
    CargaImagenesComponent,
    NavbarPdfComponent,
    TipoEntidadComponent,
    TokenAppComponent,
    PagoLibelulaComponent,
    EditarDocumentosComponent,
    PrimeraImportacionComponent,
    PrimeraComercializacionComponent,
    ImportacionComercializacionComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ],
  exports: [
    PageNotFoundComponent,
    PinFDComponent,
    TransaccionPagoComponent,
    VisorPdfDialogoComponent,
    CargaDocumentosComponent,
    PrimeraImportacionComponent,
    PrimeraComercializacionComponent,
    ImportacionComercializacionComponent,
  ],
})
export class CoreComponentsModule { }
