import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import {  MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { SustanciasService } from '../../../services/sustancias.service'
import { lastValueFrom } from 'rxjs'
import Swal from "sweetalert2"
import { AutorizacionPreviaImportacionesService } from '../../../services/autorizacion-previa-importaciones.service';
/**
 * Componente que muestra los tipos de solicitudes
 */
@Component({
  selector: 'app-seleccionar-tipo-sustancia',
  templateUrl: './seleccionar-tipo-sustancia.component.html',
  styleUrls: ['./seleccionar-tipo-sustancia.component.scss']
})
export class SeleccionarTipoSustancia implements OnInit {
  /**
  * variable de seleccion de tipo de solicitud
  */
  seleccion: number
  /**
  * variable de seleccion de tipo de sustancia
  */
  disabled:boolean=true
  /**
  * variable de seleccion de tipo de sustancia
  */
  disabledSus:boolean=true
  /**
  * variable de hidrocarburos
  */
  hidrocarburos=1
  /**
  * variable de otras sustancias
  */
  otras=2
  /**
  * Constructor del los tipos de solicitudes importacion, exportacion, produccion y ampliacion
  */
  subtipoTramite : number = 0
  constructor( 
  /**
  * objeto dialogRef para el tipo de solicitudes 
  */
  public dialogRef: MatDialogRef<SeleccionarTipoSustancia>,
  /**
  * Constructor  que permite consumir los servicios de sustancias
  */
  private sustanciasService: SustanciasService,
  
  @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    this.dialogRef.updateSize('auto', 'auto')
    this.subtipoTramite = this.data?.subtipoTramite
    this.getCantidadSustancias()
    console.log(this.subtipoTramite);
    
  }

    async getCantidadSustancias() {
      let idTramite = this.data?.idTramite
      

      if(this.subtipoTramite == 23) {
        try {
          var resp = await lastValueFrom(this.sustanciasService.obtenerTipoHidro(idTramite))
        } catch (error) {
          Swal.fire("", error.error.mensaje, "error");
          return
        }
      } else {
        try {
          var resp = await lastValueFrom(this.sustanciasService.obtenerTipoSustancias())
        } catch (error) {
          Swal.fire("", error.error.mensaje, "error");
          return
        }
      }
      if(resp.datos.resultHidrocarburos.count>0){
        this.disabled=false
      }
      if(resp.datos.resultSustancias.count>0){
        this.disabledSus=false
      } 
    }  

  cancel(): void {
    this.dialogRef.close({ res: false });
  }
}
