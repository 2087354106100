import { SustanciaModel } from './Sustancia.model'
/**
 * Interface que representa las sustancias AP de una solicitud AP
 */
export class SustanciaAPModel {
    /**
   * id_sustancia_ap de sustancias
   */  
  id_sustancia_ap?: number;
    /**
   * id_unidad_medida de sustancias
   */  
  id_unidad_medida?: number;
    /**
   * id_envase de sustancias
   */  
  id_envase?: number;
    /**
   * id_autorizacion_previa de sustancias
   */  
  id_autorizacion_previa?: number;
    /**
   * id_sustancia de sustancias
   */  
  id_sustancia?: number;
    /**
   * cantidad_envase de sustancias
   */  
  cantidad_envase?: number;
    /**
   * cantidad_solicitada de sustancias
   */  
  cantidad_solicitada?: number;
     /**
   * cantidad_solicitada de sustancias
   */  
  cantidad?: number;
    /**
   * cantidad_autorizada de sustancias
   */  
  cantidad_autorizada?: number;
    /**
   * cant_envase de sustancias
   */  
  cant_envase?: number;
    /**
   * saldo_kardex de sustancias
   */  
  saldo_kardex?: number;
     /**
   * eliminado de sustancias
   */  
  eliminado?: boolean;
     /**
   * usuario_alta de sustancias
   */  
  usuario_alta?: number;
     /**
   * usuario_baja de sustancias
   */  
  usuario_baja?: number;
     /**
   * usuario_modificacion de sustancias
   */  
  usuario_modificacion?: number;
     /**
   * fecha_alta de sustancias
   */  
  fecha_alta?: Date;
     /**
   * fecha_baja de sustancias
   */  
  fecha_baja?: Date;
     /**
   * fecha_modificacion de sustancias
   */  
  fecha_modificacion?: Date;
     /**
   * nombre_envase de sustancias
   */  
  nombre_envase?: string;
     /**
   * nombre_unidad_medida de sustancias
   */  
  nombre_unidad_medida?: string;
     /**
   * abreviacion de sustancias
   */  
  abreviacion?: string;
     /**
   * nombre_sustancia de sustancias
   */  
  nombre_sustancia?: string;
     /**
   * nombre_sustancia_primaria de sustancias
   */  
  nombre_sustancia_primaria?: string;
     /**
   * id_sustancia_primaria de sustancias
   */  
  id_sustancia_primaria?: number;
     /**
   * posicion de sustancias
   */  
  posicion: number = 0;
     /**
   * utilizacion de sustancias
   */  
  utilizacion?: string;
     /**
   * porcentaje de sustancias
   */  
  porcentaje?: string;
    /**
   * primaria de sustancias
   */  
  primaria?: boolean;
     /**
   * masa_unidad_medida de sustancias
   */  
  masa_unidad_medida?: boolean;
   /**
   * Constructor que consume los datos del modelo sustancia
   */    
      /**
   *tipo hidro comerciales
   */  
   tipo?:number
   /**
  * codigo indentificador
  */  
 codigo?:string
  constructor(sustancia: SustanciaModel) {
       /**
     * objeto instancia de la variable id_sustancia
     */     
    this.id_sustancia = sustancia.id_sustancia
        /**
     * objeto instancia de la variable tipo
     */     
    this.tipo=sustancia.tipo
        /**
     * objeto instancia de la variable codigo
     */     
    this.codigo=sustancia.codigo
   /**
     * objeto instancia de la variable cantidad_solicitada
     */  
    this.cantidad_solicitada = sustancia.cantidad_solicitada;
     /**
     * objeto instancia de la variable cantidad_solicitada
     */  
     this.cantidad = sustancia.cantidad;
  /**
     * objeto instancia de la variable nombre_unidad_medida
     */  
    this.nombre_unidad_medida = sustancia.nombre_unidad_medida;
    /**
     * objeto instancia de la variable id_unidad_medida
     */  
    this.id_unidad_medida = sustancia.id_unidad_medida;
    /**
     * objeto instancia de la variable abreviacion
     */  
    this.abreviacion = sustancia.abreviacion;
    /**
     * objeto instancia de la variable nombre_sustancia
     */  
    this.nombre_sustancia = sustancia.nombre_sustancia;
  /**
     * objeto instancia de la variable nombre_sustancia_primaria
     */  
    this.nombre_sustancia_primaria = sustancia.nombre_sustancia_primaria;
    /**
     * objeto instancia de la variable id_sustancia_primaria
     */  
    this.id_sustancia_primaria = sustancia.id_sustancia_primaria;
    /**
     * objeto instancia de la variable porcentaje
     */  
    this.porcentaje = sustancia.porcentaje;
    /**
     * objeto instancia de la variable primaria
     */  
    this.primaria = sustancia.primaria;
 /**
     * objeto instancia de la variable masa_unidad_medida
     */  
    this.masa_unidad_medida = sustancia.masa_unidad_medida;
  }
}
