import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { environment } from "../../../environments/environment";

const base_url_sistema = environment.base_url_sistema;

@Injectable({
  providedIn: "root",
})
export class SistemaService {

  constructor(
    private http: HttpClient,
  ) { }

  getFechaSistema(): Observable<any> {
    return this.http.get(`${base_url_sistema}/fecha`);
  }
  getDocPDF(uuid:string): Observable<any> {
    return this.http.get(`${base_url_sistema}/pdf/${uuid}`);
  }  

}
