import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseHttpService } from 'src/app/core/services/_base-http.service';
/**
 * Inyectable que permite la comunicacion con el api backend de sustancias-ap
 */
@Injectable({
  providedIn: 'root'
})
export class SustanciaAPService {
  /**
   * url de servico backend de sustancias-ap
   */
  localUrl = 'licencias-previas/sustancia-ap';
   /**
   * Constructor del listado de sustancias-ap
   */
  constructor(
             /**
   * metodo de servicio http
   */
    private baseHttpService: BaseHttpService) { }
     /**
   * Método que obtiene el listado de sustancias-ap
   */ 
  public obtenerTodos(): Observable<any> {
    return this.baseHttpService.get(`${this.localUrl}/obtenertodos`);
  }
    /**
   * Método get el listado de sustancias-ap
   */ 
  public get(id: string|number): Observable<any> {
    return this.baseHttpService.get(`${this.localUrl}/show/${id}`);
  }
     /**
   * Método que crea el datos de sustancias-ap
   */ 
  public crear(data: any): Observable<any>{
    return this.baseHttpService.post(`${this.localUrl}/crear`, data);
  }
 /**
   * Método que actualiza datos de sustancias
   */ 
  public actualizar(id: string|number, data: any): Observable<any> {
    return this.baseHttpService.put(`${this.localUrl}/modificar/` + id, data);
  }
     /**
   * Método que elimina datos de sustancias-ap
   */ 
  public eliminar(id: string|number): Observable<any> {
    return this.baseHttpService.delete(`${this.localUrl}/eliminar/` + id);
  }
}