<form [formGroup]="forma" novalidate (ngSubmit)="restablecerC()">
    <mat-card class="shipping-card">
      <div>
        <img class="logo" src="../../..assets/images/membreteDGSC.gif" alt="DGSC">
      </div>
      <br>
      <mat-card-header>
        <mat-card-title>Restablecer su contraseña</mat-card-title>
        <mat-card-subtitle>Ingrese su nueva contraseña</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>

        <div class="row">
          <div class="col">
            <mat-form-field class="full-width" appearance="outline">
                <mat-label>Contraseña Nueva</mat-label>
                <input matInput placeholder="Contraseña de Usuario" 
                [type]="hide1 ? 'password' : 'text'" 
                autocomplete="nope"
                formControlName="claveNueva">
                <button type="button" mat-icon-button matSuffix (click)="hide1 = !hide1" 
                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide1">
                    <mat-icon>{{hide1 ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field class="full-width" appearance="outline">
                <mat-label>Confirmación de la contraseña</mat-label>
                <input matInput placeholder="Contraseña de Usuario" 
                [type]="hide2 ? 'password' : 'text'" 
                autocomplete="nope"
                formControlName="claveconfirmacion">
                <button type="button" mat-icon-button matSuffix (click)="hide2 = !hide2" 
                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide2">
                    <mat-icon>{{hide2 ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
            <div class="col">
                <button mat-raised-button color="primary" 
                  class="center" type="submit" [disabled]="forma.invalid">
                  Restablecer
                </button>    
            </div>
          </div>
        <div class="row" *ngIf="forma.errors?.sonIguales && !forma.pristine">
            <div class="col">
                <p class="error">
                    Las contraseñas deben de se iguales
                </p>
            </div>
        </div>        

      </mat-card-content>
    </mat-card>
</form>