import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseHttpService } from 'src/app/core/services/base-http.service';
/**
 * Inyectable que permite la comunicacion con el api backend de unidad de medida
 */
@Injectable({
  providedIn: 'root'
})
export class UnidadMedidaService {
 /**
   * url de servico backend de unidad de medida
   */
  localUrl = 'parametricas/unidad-medida';
  /**
   * Constructor del listado de unidad de medida
   */
  constructor(
    /**
   * metodo de servicio http
   */
    private baseHttpService: BaseHttpService) { }
  /**
   * Método que obtiene el listado de unidad de medida
   */ 
  public obtenerTodos(): Observable<any> {
    return this.baseHttpService.getSistema(`${this.localUrl}/obtenertodos`);
  }
  /**ne el listado de unidad de medida
   * Método que obtie
   */ 
/*   public get(id: string|number): Observable<any> {
    return this.baseHttpService.get(`${this.localUrl}/show/${id}`);
  } */

  /* public crear(data: any): Observable<any>{
    return this.baseHttpService.post(`${this.localUrl}/crear`, data);
  } */

/*   public actualizar(id: string|number, data: any): Observable<any> {
    return this.baseHttpService.put(`${this.localUrl}/modificar/` + id, data);
  } */

/*   public eliminar(id: string|number): Observable<any> {
    return this.baseHttpService.delete(`${this.localUrl}/eliminar/` + id);
  } */
}