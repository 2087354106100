import { CodigoVerificacionService } from './../../services/codigo-verificacion.service';
import { lastValueFrom } from 'rxjs';
import { Component, OnInit, ViewChild } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { MatStepper } from '@angular/material/stepper'
import { UsuarioService } from 'src/app/modules/auth/services/usuario.service'
import Swal from 'sweetalert2'
import { RegistroPersonaComponent } from '../registro-persona/registro-persona.component'
import { Parametricas } from 'src/app/core/enums/parametricas.enums';
@Component({
  selector: 'app-registro-usuario',
  templateUrl: './registro-usuario.component.html',
  styleUrls: ['./registro-usuario.component.scss']
})
export class RegistroUsuarioComponent implements OnInit {
  @ViewChild('stepper') stepper
  isLinear = true
  firstFormGroup: FormGroup
  secondFormGroup: FormGroup
  thirdFormGroup: FormGroup

  personaRegistrada: any
  idUsuario: number
  isEditable: boolean = true
  firstStepComplete: boolean = false
  secondStepComplete: boolean = false
  thirdStepComplete: boolean = false
  isFormCompleted: boolean = false

  passwordNoSonIguales: boolean = false
  hide = true

  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private usuarioService: UsuarioService,
    public codigoVerificacionService: CodigoVerificacionService
  ) { }

  ngOnInit(): void {

    this.firstFormGroup = this.formBuilder.group({
      correo: ['', Validators.required],
    })

    this.secondFormGroup = this.formBuilder.group({
      correo: ['', [Validators.required, Validators.email, Validators.pattern('^[a-zA-Z0-9.@_\-]+$')]],
      cuenta: ['', [Validators.required, Validators.pattern('^[a-z0-9]+$')]],
      password: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern('^(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')
      ]],
      passwordConfirm: ['', [
        Validators.required,
        Validators.minLength(8),
      ]],
    })

    this.thirdFormGroup = this.formBuilder.group({
      codigo: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
      idUsuario: [null, Validators.required]
    })
    /* if (this.secondFormGroup.invalid) { 
      this.isEditable = false;
   } */



    this.registrarPersona()
  }

  registrarPersona(): void {
    const dialogRef = this.dialog.open(RegistroPersonaComponent, {
      disableClose: true,
      // tiposDocumento: "NACIONAL | EXTRANJERO" tpoValidacion: "FULL | BASIC"
      data: { tiposDocumento: 'NACIONAL', tipoValidacion: 'BASIC', popupObligatorio: false },
    })

    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result) {
          //          console.log('RESP USUER:', result)
          this.personaRegistrada = result.persona // persona: { idPersona: idPersona, correo: body.correoPersonal },
          this.firstStepComplete = result.ok
        }
      },
      error: () => { }
    })
  }

  toSecondStep(stepper: MatStepper) {
    //    console.log('this.personaRegistrada', this.personaRegistrada)

    // this.firstStepComplete = true
    if (this.firstStepComplete) {
      this.secondFormGroup.patchValue({ correo: this.personaRegistrada.correoPersonal })
      stepper.next()
    }
  }

  toThirdStep(stepper: MatStepper) {
    console.log('this.personaRegistrada', this.personaRegistrada)

    // this.firstStepComplete = true
    if (this.firstStepComplete) {
      this.secondFormGroup.patchValue({ correo: this.personaRegistrada.correoPersonal })
      stepper.next()
    }
  }
  async registrarUsuario() {
    // Eliminamos los espacios del inicio y del final de la cuenta y el correo
    this.secondFormGroup.patchValue({
      correo: this.secondFormGroup.value.correo.trim(),
      cuenta: this.secondFormGroup.value.cuenta.trim()
    })

    // Validamos el correo
    if (this.secondFormGroup.controls.correo.invalid) {
      let mensaje = 'Para el correo solo se permite letras, numeros, los caracteres "-" y "_" pero sin espacios'

      if (this.secondFormGroup.controls.correo.value === "") {
        mensaje = "Ingrese un correo electrónico"
      }

      Swal.fire({ title: 'Correo inválido', text: mensaje, icon: 'warning', confirmButtonText: 'Aceptar' })
      return
    }

    // Validamos la cuenta
    if (this.secondFormGroup.controls.cuenta.invalid) {
      let mensaje = "Para la cuenta solo se permite letras minusculas, numeros y sin espacios"

      if (this.secondFormGroup.controls.cuenta.value === "") {
        mensaje = "Ingrese un nombre de usuario"
      }

      Swal.fire({ title: 'Nombre de usuario inválido', text: mensaje, icon: 'warning', confirmButtonText: 'Aceptar' })
      return
    }


    // Validamos el formulario
    if (this.secondFormGroup.invalid) {
      Swal.fire({ title: 'Debe completar el formulario', text: 'Revise si el formulario esta completo por favor', icon: 'warning', confirmButtonText: 'Aceptar' })
      return
    }

    this.passwordNoSonIguales = false
    //    console.log(this.secondFormGroup.value)
    if (this.secondFormGroup.value.password !== this.secondFormGroup.value.passwordConfirm) {
      Swal.fire({ title: 'Revise el formulario', text: 'Las contraseñas no coinciden', icon: 'warning', confirmButtonText: 'Aceptar' })
      this.passwordNoSonIguales = true
      return
    }
    // Enviar al Backend para guardar los datos de Usuario
    const datosUsuario = this.secondFormGroup.value

    datosUsuario.idPersona = this.personaRegistrada.idPersona
    //datosUsuario.correo = this.personaRegistrada.correo
    datosUsuario.correo = this.secondFormGroup.value.correo

    //    console.log('USUARIO', datosUsuario)
    Swal.fire({
      title: 'Espere',
      text: 'Registrando datos de usuario',
      icon: 'info',
      allowOutsideClick: false
    })
    Swal.showLoading()
    try {
      var resp = await lastValueFrom(this.usuarioService.registrarUsuario(datosUsuario))
    } catch (error) {
      Swal.fire('', error.error.mensaje, 'warning')
      return
    }

    this.secondStepComplete = true
    this.idUsuario = resp.datos.idUsuario
    Swal.fire({ title: '', text: resp.mensaje, icon: 'success' }).then((res) => {
      this.thirdFormGroup.patchValue({ idUsuario: this.idUsuario })
      this.stepper.next()
    })
  }

  async solicitarCodigo() {

    const data = {
      tipoCodigo: Parametricas.ACTIVACION,
      idUsuario: this.idUsuario,
      email: this.secondFormGroup.value.correo
    }

    try {
      await this.codigoVerificacionService.solicitarCodigo(data)
    } catch (error) {
      return
    }
  }

  async activarUsuarioNuevo() {

    if (this.thirdFormGroup.invalid) {
      //console.log('invalid') 
      return
    }


    // Enviar al Backend para guardar los datos de Usuario
    // const datosVerificacion = this.thirdFormGroup.value
    this.thirdFormGroup.patchValue({ idUsuario: this.idUsuario })
    Swal.fire({ title: 'Espere', text: 'Verificando código', icon: 'info', allowOutsideClick: false })
    Swal.showLoading()
    var resp
    try {
      resp = await lastValueFrom(this.usuarioService.activarUsuarioNuevo(this.thirdFormGroup.value))
    } catch (error) {
      Swal.fire('', error.error.mensaje, 'warning')
      return
    }

    this.thirdStepComplete = true
    Swal.fire({ title: '', text: resp.mensaje, icon: 'success' }).then((res) => {
      this.isFormCompleted = true
      this.isEditable = false
      this.stepper.next()
    })
  }
}
