<form [formGroup]="formDocumentos">

  <div class="row modal__header" *ngIf="subtipoTramite != 19">
    <div class="col">
      <div class="modal__title">Documentos obligatorios</div>
      <div class="modal__subtitle">Debe adjuntar los documentos que se detallan a continuación:</div>
      <div class="modal__subtitle">
        <small>
          <span style="color: red">*</span>El tamaño máximo del documento no debe ser mayor a 7Mb.
        </small>
      </div>
    </div>
  </div>

  <div class="my-3">
    <table class="table table-list-documentos">
      <tbody formArrayName="documentos_obligatorios"
        *ngFor="let docObligatorioForm of documentosObligatorios.controls; let i=index">
        <tr [formGroupName]="i">
          <td>
            <mat-form-field appearance="outline" style=" width: 250px;">
              <mat-label>Nombre del documento</mat-label>
              <textarea matInput placeholder="Nombre del documento" class="description-textarea text-uppercase"
                [disabled]="true"
                title="{{ docObligatorioForm.value.descripcion }}">{{ docObligatorioForm.value.descripcion }}</textarea>
            </mat-form-field>
          </td>
          <td>
            <mat-form-field appearance="outline" class="input-date">
              <mat-label>No. de documento/CITE</mat-label>
              <input matInput type="text" placeholder="Identificador" maxlength="30"
                pattern="^(?!\s)(?!.*\s$)(?!.*').*$" formControlName="identificador" autocomplete="off">
              <mat-error *ngIf="docObligatorioForm.get('identificador').hasError('pattern')">No se permiten espacios
                al inicio o al final y caracteres especiales</mat-error>
            </mat-form-field>
          </td>
          <td>
            <mat-form-field appearance="outline" class="input-date" (click)="picker.open()" style="cursor: pointer">
              <mat-label>Fecha de Emisión</mat-label>
              <input matInput [matDatepicker]="picker" formControlName="fecha_emision" style="cursor: pointer"
                [max]="today" readonly>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker touchUi #picker></mat-datepicker>
              <mat-error *ngIf='docObligatorioForm.controls["fecha_emision"].hasError("required")'>Campo
                requerido</mat-error>
            </mat-form-field>
          </td>
          <td>
            <mat-form-field appearance="outline" class="input-date" (click)="pickerF.open()" style="cursor: pointer">
              <mat-label>Fecha Finalización</mat-label>
              <input matInput [matDatepicker]="pickerF" formControlName="fecha_fin"
                [min]="docObligatorioForm.value.fecha_emision" style="cursor: pointer"
                [disabled]="docObligatorioForm.value.fecha_emision === ''" readonly>
              <mat-datepicker-toggle matSuffix [for]="pickerF"></mat-datepicker-toggle>
              <mat-datepicker touchUi #pickerF></mat-datepicker>
              <mat-error *ngIf='docObligatorioForm.controls["fecha_fin"].hasError("required")'>Campo
                requerido</mat-error>
            </mat-form-field>
          </td>
          <td>
            <div class="file-name">

              <div class="icon-block">

                <button mat-icon-button style="width: 30px; height: 38px;"
                  [ngClass]="docObligatorioForm.value.base64 == null ? 'file-not-uploaded' : 'file-uploaded'"
                  (click)="adjuntarDocumento(docObligatorioForm, i)"
                  matTooltip="{{ docObligatorioForm.value.base64 == null ? 'Adjuntar archivo y guardar' : '¿Actualizar archivo?' }}">
                  <mat-icon style="font-size:18px;">{{ docObligatorioForm.value.base64 == null ? 'attach_file' :
                    'attach_file' }}</mat-icon>
                </button>
              </div>

              <div class="icon-block">
                <button mat-icon-button (click)="verDocumento(docObligatorioForm)" matTooltip="Ver documento"
                  [disabled]="docObligatorioForm.value.base64 == null">
                  <img class="color-print" src="assets/svg/visibility_white.svg" />
                </button>
              </div>

              <input type="file" name="{{ DOCUMENTO_OBLIGATORIO + '-' + i }}" id="{{ DOCUMENTO_OBLIGATORIO + '-' + i }}"
                (change)="adjuntarArchivo($event, docObligatorioForm);" style="display: none;" accept=".pdf">
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="row modal__header">
    <div class="col-md-8">
      <div *ngIf="subtipoTramite == 19">
        <mat-card-title class="alert d-flex align-items-center fade show"><mat-icon>info</mat-icon>
          <div [innerHTML]="alertAdicionales"></div>
        </mat-card-title>
      </div>
      <div class="modal__title">Documentos adicionales</div>
      <div class="modal__subtitle">{{mensajeAdicionales}}</div>
      <div class="modal__subtitle">
        <small>
          <span style="color: red">*</span>El tamaño máximo del documento no debe ser mayor a 7Mb.
        </small>
      </div>
    </div>
    <div class="col text-end">
      <button mat-stroked-button color="primary-outline" class="my-3" aria-label="Agregar documento adicional"
        (click)="agregarDocumento()">
        <mat-icon>add_task</mat-icon>
        &nbsp;Agregar documento
      </button>
    </div>
  </div>

  <div class="my-3">
    <table class="table table-list">
      <tbody formArrayName="documentos_adicionales"
        *ngFor="let docAdicionalForm of documentosAdicionales.controls; let j=index;">
        <tr [formGroupName]="j">
          <td>
            <button mat-icon-button color="primary" matTooltip="subir posición"
              (click)="subirPosicion(docAdicionalForm.value, j, j-1)" [disabled]="j - 1 < 0">
              <mat-icon>arrow_drop_up_icon</mat-icon>
            </button>
            <button mat-icon-button color="primary" matTooltip="bajar posición"
              (click)="subirPosicion(docAdicionalForm.value, j, j+1)"
              [disabled]="j + 1 > documentosAdicionales.controls.length - 1">
              <mat-icon>arrow_drop_down_icon</mat-icon>
            </button>
          </td>
          <td>
            <mat-form-field appearance="outline" style=" width: 250px;">
              <mat-label>Nombre del documento</mat-label>
              <textarea matInput type="text" class="text-uppercase" maxlength="255" pattern="^(?!\s)(?!.*\s$)(?!.*').*$"
                formControlName="descripcion" [readonly]="docAdicionalForm.get('guardado').value"></textarea>
              <mat-error *ngIf="docAdicionalForm.get('descripcion').hasError('pattern')">No se permiten espacios al
                inicio o al final y caracteres especiales</mat-error>
            </mat-form-field>
          </td>
          <td>
            <mat-form-field appearance="outline" class="input-date">
              <mat-label>No. de identificador</mat-label>
              <input matInput type="text" placeholder="Identificador" maxlength="30"
                pattern="^(?!\s)(?!.*\s$)(?!.*').*$" formControlName="identificador" autocomplete="off"
                [readonly]="docAdicionalForm.get('guardado').value">
              <mat-error *ngIf="docAdicionalForm.get('identificador').hasError('pattern')">No se permiten espacios al
                inicio o al final y caracteres especiales</mat-error>
            </mat-form-field>
          </td>
          <td>
            <mat-form-field appearance="outline" class="input-date">
              <mat-label>Fecha de Emisión</mat-label>
              <input matInput [matDatepicker]="picker" formControlName="fecha_emision" [max]="today"
                [disabled]="docAdicionalForm.get('guardado').value">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker touchUi #picker></mat-datepicker>
              <mat-error *ngIf='docAdicionalForm.controls["fecha_emision"].hasError("required")'>Campo
                requerido</mat-error>
            </mat-form-field>
          </td>
          <td>
            <mat-form-field appearance="outline" class="input-date">
              <mat-label>Fecha Finalización</mat-label>
              <input matInput [matDatepicker]="pickerF" formControlName="fecha_fin"
                [min]="docAdicionalForm.value.fecha_emision"
                [disabled]="docAdicionalForm.get('guardado').value ? docAdicionalForm.get('guardado').value : docAdicionalForm.value.fecha_emision === ''"
                readonly>
              <mat-datepicker-toggle matSuffix [for]="pickerF"></mat-datepicker-toggle>
              <mat-datepicker touchUi #pickerF></mat-datepicker>
              <mat-error *ngIf='docAdicionalForm.controls["fecha_fin"].hasError("required")'>Campo
                requerido</mat-error>
            </mat-form-field>
          </td>

          <td>
            <div class="file-name">
              <div class="icon-block" *ngIf="docAdicionalForm.value.verificado != 1">
                <button mat-icon-button style="width: 30px; height: 38px;"
                  [ngClass]="docAdicionalForm.value.base64 == null ? 'file-not-uploaded' : 'file-uploaded'"
                  (click)="adjuntarDocumento(docAdicionalForm, j)"
                  matTooltip="{{ docAdicionalForm.value.base64 == null ? 'Adjuntar archivo y guardar' : '¿Actualizar archivo?' }}">
                  <mat-icon style="font-size:18px;">{{ docAdicionalForm.value.base64 == null ?
                    'attach_file' : 'attach_file'}}</mat-icon>
                </button>
              </div>

              <div class="icon-block">
                <button mat-icon-button (click)="verDocumento(docAdicionalForm)" matTooltip="Ver documento"
                  [disabled]="docAdicionalForm.value.base64 == null">
                  <img class="color-print" src="assets/svg/visibility_white.svg" />
                </button>
              </div>

              <button mat-icon-button color="primary" matTooltip="Editar información"
                (click)="editarInfoDoc(docAdicionalForm)" *ngIf="docAdicionalForm.value.verificado != 1">
                <img class="color-edit" src="assets/svg/drive_file_rename_outline_white.svg" />
              </button>

              <div class="icon-block" *ngIf="docAdicionalForm.value.verificado != 1">
                <label class="file-icon" matTooltip="Eliminar documento"
                  (click)="eliminarDocumento(docAdicionalForm, j)">
                  <img class="color-delete" src="assets/svg/delete_forever.svg" />
                </label>
              </div>

              <input type="file" name="{{ DOCUMENTO_ADICIONAL + '-' + j }}" id="{{ DOCUMENTO_ADICIONAL + '-' + j }}"
                (change)="adjuntarArchivo($event, docAdicionalForm)" style="display: none;" accept=".pdf">
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <mat-card-actions *ngIf="tipoTramite === 12">

    <mat-card-title class="alert d-flex align-items-center fade show fondo-personalizado" *ngIf="this.idEstado === 601">
      <mat-icon>info</mat-icon>
      <div [innerHTML]="alertAceptar"></div>
      <img class="color-verified" src="assets/svg/phone_iphone_white.svg" style="margin-left: 10px;" />
    </mat-card-title>


    <div class="row justify-content-between">
      <button mat-raised-button class="col-md-3 nuevo-button" (click)="finalizarDocumentos()">
        <mat-icon>done</mat-icon>Aceptar
      </button>

      <button mat-raised-button class="col-md-3" color="warn" style="height: 45px;" [mat-dialog-close]="false">
        <mat-icon>cancel</mat-icon> Cancelar
      </button>
    </div>
  </mat-card-actions>

  <mat-card-actions class="d-flex justify-content-end" *ngIf="tipoTramite !== 12">
    <button mat-raised-button class="nuevo-button" (click)="finalizar()">
      <mat-icon>done</mat-icon>Aceptar y terminar
    </button>
    <button mat-raised-button color="warn" style="height: 45px;" [mat-dialog-close]="false">
      <mat-icon>cancel</mat-icon> Cancelar
    </button>
  </mat-card-actions>
</form>