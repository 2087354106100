export class Parametricas {

  public static meses = [
    { nombre: 'Enero', id: 1 },
    { nombre: 'Febrero', id: 2 },
    { nombre: 'Marzo', id: 3 },
    { nombre: 'Abril', id: 4 },
    { nombre: 'Mayo', id: 5 },
    { nombre: 'Junio', id: 6 },
    { nombre: 'Julio', id: 7 },
    { nombre: 'Agosto', id: 8 },
    { nombre: 'Septiembre', id: 9 },
    { nombre: 'Octubre', id: 10 },
    { nombre: 'Noviembre', id: 11 },
    { nombre: 'Diciembre', id: 12 },
  ]

  public static maxFileSize: number = 52428800        //4194304 // Tamaño en bytes 1Mb por default
  public static maxImageSize: number = 5242880 // Tamaño en bytes 1Mb por default

  public static costoPorPagoPPTE: number = 1 // Costo por pagar atraves de PPTE "Plataforma de Pagos de Tramites del Estado"
  public static costoPorPagarPasarela: number = 1 // Costo por pagar atraves de pasarela de pagos Libelula
  /***botones */

  public static NUEVO: string = 'Nuevo Tramite'
  public static BUSCAR: string = 'Buscar...'
  public static oficinas = [
    { nombre_oficina: 'CENTRAL', id_oficina: 0 },
    { nombre_oficina: 'LA PAZ', id_oficina: 1 },
    { nombre_oficina: 'ORURO', id_oficina: 2 },
  ]
  public static declaracion: string = 'JURO LA VERACIDAD DE LOS DATOS CONSIGNADOS EN EL PRESENTE FORMULARIO, ENTENDIENDO QUE SU FALSEDAD PODRÍA DAR LUGAR A RESPONSABILIDAD PENAL, EN CASO DE HABER DECLARADO, PRESENTADO DATOS Y/O DOCUMENTACIÓN FALSOS O ERRÓNEOS, ASIMISMO SE HACE CONSTAR QUE REALIZARÉ ACTIVIDADES CON SUSTANCIAS QUÍMICAS CONTROLADAS CONFORME A NORMATIVA LEGAL VIGENTE, BAJO EXCLUSIVA RESPONSABILIDAD DE MI PERSONA.'
  public static authTitle = 'ESTADO DIGITAL'
  public static authSubtitle = 'DIRECCIÓN GENERAL DE SUSTANCIAS CONTROLADAS'
  public static CREAR: string = 'crear'
  public static ACTUALIZAR: string = 'actualizar'
  public static ELIMINAR: string = 'eliminar'
  public static AGREGAR_EXISTENTE: string = 'agregar_existente'

  public static CODIGO_PAIS_BOLIVIA = 25

  public static NACIONALIDAD_BOLIVIANA_ID = 1
  public static NACIONALIDAD_BOLIVIANA_NOMBRE = 'Boliviano'
  public static NACIONALIDAD_BOLIVIANA_ABBR = 'BO'

  public static NACIONALIDAD_EXTRANJERA_ID = 2
  public static NACIONALIDAD_EXTRANJERA_NOMBRE = 'Extranjero'
  public static NACIONALIDAD_EXTRANJERA_ABBR = 'EX'

  // Tipos de codigo de verificacion
  public static ACTIVACION = 1
  public static RESET_PASSWORD = 2

  // Tipos de pago
  public static PAGO_POR_BOLETAS = 0
  public static PAGO_POR_PPTE = 1
  ///datos pasarela de pagos libelula
  public static TITULO = 'PASARELA DE PAGOS ONLINE'
  ///mensajes ap
  public static MesSustancia = 'Por favor, <strong>"seleccione al menos una sustancia"</strong> y complete todos los <strong>(* campos obligatorios).</strong>';
  public static MesDocumento = 'Por favor, adjunte los requisitos necesarios y complete los <strong>(* campos obligatorios).</strong>';
  public static Mesampliacion = ' Por favor, introduzca el número de resolución administrativa para proceder con el llenado del formulario y complete los <strong> (* campos obligatorios).</strong>';
  public static MesNotificacion = ' Por favor, introduzca el número de resolución administrativa <strong>"Importación o Exportación"</strong> para proceder con el llenado del formulario y complete los <strong> (* campos obligatorios).</strong>';
  ///mensajes registro
  public static MesDatosRegistro = ' Revise sus datos y haga clic en '
  public static MesPrincipal = 'Por favor, ingrese los datos de <strong>"Licencia de Funcionamiento"</strong> y complete los <strong> (* campos obligatorios).</strong> '
  public static MesSucu = ' Por favor, complete los datos de la sucursal, rubro y los <strong> (* campos obligatorios).</strong>'
  public static MesCriterio = '  Por favor, ingrese los criterios requeridos: <strong>NIT o CI (documento de identidad)</strong>, para verificar su existencia.'
  public static MesSustanciaReg = ' Por favor, agregue la(s) <strong>sustancia(s)</strong> y complete los <strong> (* campos obligatorios).</strong>'
  public static MesSeleccionSus = '  Por favor, <strong> "seleccione o marque" </strong> las actividades que estén relacionadas con la sustancia.'
  public static MesSeleccionAct = 'Por favor, seleccione al menos una <strong>"actividad"</strong> relacionada a la sustancia antes de guardar.'
  public static MesRepresentantes = 'Puede presionar <strong>"Siguiente"</strong> si no cuenta con <strong>"Apoderado(s)"</strong> y así continuar con el proceso de registro.'
  public static MesPoder = ' Por favor, seleccione y agregue el <strong>"Poder Notarial"</strong> y complete los <strong> (*campos obligatorios).</strong>'
  public static MesMaquinaria = ' Puede presionar <strong>"Siguiente"</strong> si no cuenta con <strong>"Maquinaria(s)"</strong> y así continuar con el proceso de registro.'
  public static MesAgrMaquinaria = ' Por favor, complete los <strong> (* campos obligatorios).</strong>'
  public static MesSucursal = '  Puede presionar <strong>"Siguiente"</strong> si no cuenta con <strong>"Sucursales Locales"</strong> y así <strong>"Finalizar"</strong> con el proceso de registro.'
  public static MesAgrSucursal = 'Por favor, ingrese los datos de <strong> "Licencia de Funcionamiento" </strong> y complete los <strong> (* campos obligatorios).</strong>'
  public static MesSocios = 'Por favor, agregue al menos un <strong> "MAE/Socio o Propietario" </strong> y complete los <strong> (* campos obligatorios).</strong>'
  //anu dev
  public static MesCampos = ' Por favor, complete los <strong> (* campos obligatorios).</strong>'
  //compras 
  public static mesCompra = '<strong>Excepción:</strong>  Al seleccionar la casilla de Excepción, estara aprovechando al máximo su cupo (adelantando el cupo de 4 meses).'
  public static mesCompra2 = 'Seleccionar para realizar la compra adicional de gasolina/diésel.'
  // Tipos de documento
  public static OBLIGATORIO = 'OBLIGATORIO'
  public static ADICIONAL = 'ADICIONAL'

  public static ANIO_MINIMO_DE_DECLARACION = 2005

}

export const Tipos_de_registro = {
  REGISTRO_NUEVO: 1,
  EDICION_REGISTRO_NUEVO: 2,
  EDICION_REGISTRO_DATOS_SIMPLES: 3,
  MIGRACION: 4,
  ACTUALIZACION_INFORMACION:19,
  ACTULIZACION_NUEVO_4911 : 20,
  PRIMERA_RENOVACION_ED6 : 21,
  PRIMERA_REHABILITACION_ED6 : 22,
  REGISTRO_NUEVO_5271 : 23,
  RENOVACION : 15,
  EDICION_RENOVACION: 661
}

export const CAMPOS_PERMITIDOS_PARA_EDITAR = {
  EDICION_REGISTRO_DATOS_SIMPLES: {
    REGISTRO_UNICO: [],
    SUCURSAL_PRINCIPAL: ['telefono', 'correo'],
    REPRESENTANTE_LEGAL: ['direccion', 'telefono', 'correo'],
    REPRESENTANTE_TECNICO: ['direccion', 'telefono', 'correo', 'profesion', 'cargo', 'tipo_relacion_empresa'],
    SUSTANCIA: [],
    SUCURSAL_LOCAL: [],
    SUCURSAL_LOCAL_ENCARGADO: ['profesion', 'cargo', 'relacion_empresa'],
    LICENCIA_DE_FUNCIONAMIENTO: [],
    MAQUINARIA: []
  },
  EDICION_RENOVACION: {
    REGISTRO_UNICO: [],
    SUCURSAL_PRINCIPAL: ['telefono', 'correo'],
  }
}

export const Tipos_de_actualizacion = {
  sustancia: {
    INCLUSION: 1,
    EXCLUSION: 2,
    ACTUALIZACION_DE_ACTIVIDADES: 3,
    ACTUALIZACION_DE_CANTIDAD: 4,
  },
  sucursal: {
    INCLUSION: 1,
    EXCLUSION: 2,
    ACTUALIZACION_DE_DATOS: 3,
  },
  representante_legal: {
    INCLUSION: 1,
    EXCLUSION: 2,
    ACTUALIZACION_DE_DATOS: 3,
    CAMBIO_DE_REPRESENTANTE: 4
  },
  representante_tecnico: {
    INCLUSION: 1,
    EXCLUSION: 2,
    ACTUALIZACION_DE_DATOS: 3,
    CAMBIO_DE_REPRESENTANTE: 4
  },
  maquinaria: {
    INCLUSION: 1,
    EXCLUSION: 2
  },
  inhabilitacion: {
    EXCLUSION: 1
  }
}
export const Tipo_dato = {
  SUCURSAL_PRINCIPAL: 0,
  SUCURSAL_LOCAL: 1,
  SUSTANCIA_REGISTRO: 2,
  MAQUINARIA: 3,
  APODERADO: 4,
  SUCURSAL_LOCALES: 5
}

export const REGIMEN = {
  GENERAL: 'RÉGIMEN GENERAL',
  TRIBUTARIO_SIMPLIFICADO: 'RÉGIMEN TRIBUTARIO SIMPLIFICADO (RTS)',
  SISTEMA_TRIBUTARIO_INTEGRADO: 'SISTEMA TRIBUTARIO INTEGRADO (STI)',
  AGROPECUARIO_UNIFICADO: 'RÉGIMEN AGROPECUARIO UNIFICADO (RAU)',
  RÉGIMEN_SIETE: 'RÉGIMEN SIETE (RT-SIETE)',
  CERTIFICADO_NP: 'CERTIFICADO DE NO IMPONIBILIDAD'
}

export const estadoListRegistro = {
  COMPLETO: 'COMPLETO',
  INCOMPLETO: 'INCOMPLETO'
}

export const TIPO_ADMINISTRADO = {
  COOPERATIVA_MINERA : 1,
  PERSONA_NATURAL :  2,	
  UNIPERSONAL : 3,
  SOCIEDAD_COMERCIAL : 4,
  EMPRESA_PÚBLICA : 5,
  ENTIDAD_PÚBLICA : 6,
  ASOCIACIONES : 7,
  FUNDACIONES : 8
}

export const costoNuevoRegistro = {
  COSTO: 50,
  COSTO_SUSTANCIA: 50,
  COSTO_SUCURSAL: 50,
  COSTO_MAQUINARIA: 50
}

