export const environment = {
    production: false,
    // base_url: 'https://edapi.mingobierno.gob.bo/dgsc_api/api/v1/externo', 
    // base_url_sistema:'https://edapi.mingobierno.gob.bo/dgsc_api/api/v1',
    // base_url_backend:'https://edapi.mingobierno.gob.bo/dgsc_api',
    // base_urlFD: 'http://localhost:4402'

    /**de prueba */
    base_url: 'https://api.ed6.dgsc.gob.bo/api/v1/externo',
    base_url_sistema: 'https://api.ed6.dgsc.gob.bo/api/v1',
    base_url_backend: 'https://api.ed6.dgsc.gob.bo',
    base_urlFD: 'http://localhost:4402'


    /**local */
    // base_url: 'http://165.172.24.61:8200/api/v1/externo',
    // base_url_sistema: 'http://165.172.24.61:8200/api/v1',
    // base_urlFD: 'https://localhost:8000',
    // base_url_backend: 'wss://edapi.mingobierno.gob.bo'

};


