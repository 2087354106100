import { lastValueFrom } from 'rxjs';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { EnvaseService } from "../../../../../core/services/parametricas/envase.service";
import { AgregarEnvasesComponent } from '../agregar-envases/agregar-envases.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
/**
 * Componente que permite la gestion de envases
 */
@Component({
  selector: 'app-agregar-envases-lote',
  templateUrl: './agregar-envases-lote.component.html',
  styleUrls: ['./agregar-envases-lote.component.scss']
})
export class AgregarEnvasesLoteComponent implements OnInit {
  /**
    * formulario envases lote por sustancia
    */
  public envaseSustanciaForm: FormGroup;
  /**
   * variable envases
   */
  public envases: any;
  /**
   * variable envases agregados por sustancia
   */
  public envasesAgregados: any[] = [];
  /**
     * variable de unidad de medida
     */
  private unidadMedidaSustancia: boolean;
  /**
    * Constructor del listado o cargado de envases lote por sustancia
    */
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public criterioBusqueda: string = ''
  public columnasEnvases: string[] = ['numero', 'nombres', 'capacidad', 'abreviacion', 'acciones']
  public listEnvases = new MatTableDataSource<any>([])

  constructor(
    public dialog: MatDialog,
    /**
    * objeto que permite asociar el id de sustancia con envases lote
    */
    @Inject(MAT_DIALOG_DATA) public data: any,
    /**
    * objeto dialogRef para el formulario de gestion de envases
    */
    private _formBuilder: FormBuilder,
    /**
    * objeto dialogRef para gestion los objetos MatDialog
    */
    public dialogRef: MatDialogRef<AgregarEnvasesLoteComponent>,
    /**
    * Constructor que crea los servicios de envases
    */
    private envaseService: EnvaseService,
  ) {
    /**
    * formulario array de envases agregados
    */
    this.envaseSustanciaForm = this._formBuilder.group({
      envases: this._formBuilder.array([]),
    })
  }
  /**
   * Inicializa las funciones de unidad de medida y envases
  */
  ngOnInit(): void {
    this.unidadMedidaSustancia = this.data.masa_unidad_medida;
    this.envasesAgregados = this.data.envases;
    this.getEnvases();
  }

  ngAfterViewInit() {
    this.listEnvases.paginator = this.paginator;
    this.listEnvases.sort = this.sort;
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.listEnvases.filter = filterValue.trim().toLowerCase();

    if (this.listEnvases.paginator) {
      this.listEnvases.paginator.firstPage();
    }
  }
  /**
       * Inicializa la función da cargado de unidad de medida
    */
  async getEnvases() {
    try {
      this.envases = await lastValueFrom(this.envaseService.obtenerTodos())
      console.log("enases 1", this.envases);
      this.envases = this.envases.datos.filter(envase => envase.masa == this.unidadMedidaSustancia);
      console.log("enases 2", this.envases);
      this.listEnvases.data = <any>this.envases
    } catch (error) {

    }

    this.envasesAgregados.forEach((envase) => {
      const envaseFound = this.envases.find(element => element.id_envase == envase.id_envase);
      if (envaseFound) {
        const envaseAP = {
          envaseJson: envaseFound,
          cantidad_envase: envase.cantidad_envase,
        };
        this.agregaEnvase(envaseAP);
      }
    });
  }
  /**
        * formulario array de envases
     */
  get envasesSustancias() {
    return this.envaseSustanciaForm.controls['envases'] as FormArray;
  }
  /**
        * funcion de cargado en formato json de los envases agregados
     */
  agregaEnvase(envase: any = null) {
    console.log("envase", envase);
    let envaseJson = '';
    let cantidad_envase = '';

    if (envase != null) {
      envaseJson = envase.envaseJson || envase;
      cantidad_envase = envase.cantidad_envase;
    }
    console.log("envaseJson", envaseJson);
    const envaseForm = this._formBuilder.group({
      envase: [envaseJson, Validators.required],
      cantidad_envase: [cantidad_envase, Validators.required],
    });
    this.envasesSustancias.push(envaseForm);
  }

  /**
    * funcion de eliminado de indice de los envases agregados
     */
  remove(i: number) {
    this.envasesSustancias.removeAt(i);
  }
  /**
        * funcion de cerrar el dialogo de envases por lote
     */
  cerrarDialog() {
    this.dialogRef.close();
  }
  /**
        * funcion de guardado de envase en dilogo importacion, exportacion, produccion y ampliacion
     */
  guardarEnvase() {
    console.log("this.envaseSustanciaForm.invalid", this.envaseSustanciaForm.value);
    if (this.envaseSustanciaForm.invalid) {
      Swal.fire('', '¡Complete campos requeridos, por favor!', 'warning');
      return;
    }
    // if (this.envaseSustanciaForm.value == '' 
    //   ||this.envaseSustanciaForm.value == undefined) {
    //   Swal.fire('', 'Datos requeridos', 'warning');
    //   return;
    // }
    this.dialogRef.close({
      envaseSustanciaForm: this.envaseSustanciaForm.value
    })
  }

  /**
         * funcion para validar el input ingresan número
      */
  validarFormatoNumero(event: any) {

    let key;
    if (event.type === 'paste') {
      key = event.clipboardData.getData('text/plain');

    } else {
      key = event.keyCode;
      key = String.fromCharCode(key);
    }
    const regex = /[0-9]|\./;
    if (!regex.test(key)) {
      event.returnValue = false;
      if (event.preventDefault) {
        event.preventDefault();
      }
    }
  }
  /**
       * funcion para abrir el compornente de agregar envases
    */
  agregarEnvases(): void {
    const dialogRef = this.dialog.open(AgregarEnvasesComponent, {
      disableClose: true,

    })
    dialogRef.afterClosed().subscribe(result => {


      // this.getEnvases();
    });
  }

  async agregarEnvase(envase) {
    console.log("modifa", envase);
  }
}