import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './pages/login/login.component';
import { LogindgscComponent } from './pages/logindgsc/logindgsc.component';
import { RegistroUsuarioComponent } from './pages/registro-usuario/registro-usuario.component';
import { RecuperarClaveComponent } from './pages/recuperarclave/recuperar-clave.component';
import { SeleccionarRegistroComponent } from './pages/seleccionar-registro/seleccionar-registro.component';
import { LogincdComponent } from './pages/logincd/logincd.component';
import {PagoExitosoComponent} from './pages/pago-exitoso/pago-exitoso.component';
import { RestablecercComponent } from './components/restablecerc/restablecerc.component';
import { CambiarClaveComponent } from './pages/cambiar-clave/cambiar-clave.component';
import { NotificacioncdComponent } from './pages/notificacioncd/notificacioncd.component';
import { AuthLayoutComponent } from 'src/app/modules/auth/components/auth-layout/auth-layout.component';


const routes: Routes = [
  { path: 'registro-usuario', component: RegistroUsuarioComponent },
  { path: 'recuperar-clave', component: RecuperarClaveComponent },
  { path: 'pago-exitoso', component: PagoExitosoComponent },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      { path: 'login', component: LogindgscComponent },
     // { path: 'pago-exitoso', component: PagoExitosoComponent },
      { path: 'login-dgsc', component: LogindgscComponent },
      { path: 'seleccionar-registro', component: SeleccionarRegistroComponent },
      { path: 'login-cd', component: LogincdComponent },
      { path: 'logincd', component: LogincdComponent },
      { path: 'notificacioncd', component: NotificacioncdComponent },
      { path: 'restablece/:parametro', component: RestablecercComponent },
      { path: 'cambiar-clave/:ruta', component: CambiarClaveComponent },
      { path: '', component: LogindgscComponent },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
