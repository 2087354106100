import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { lastValueFrom } from 'rxjs';
import { Parametricas } from 'src/app/core/enums/parametricas.enums'
import Swal from 'sweetalert2';
import { DocumentosAdicionalesService } from '../../services/documentos-adicionales.service';


@Component({
  selector: 'app-editar-documentos',
  templateUrl: './editar-documentos.component.html',
  styleUrls: ['./editar-documentos.component.scss']
})
export class EditarDocumentosComponent implements OnInit {
  today: Date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
  public formulario: FormGroup;
  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA)
    public data: { datosDocs : any },
    private documentosAdicionalesService: DocumentosAdicionalesService,
    public dialog: MatDialogRef<EditarDocumentosComponent>,
  ) {
    this.formulario = this.formBuilder.group({
      idTramite : [''],
      id_documento_adicional: ['',],
      descripcion: ['', Validators.required],
      identificador: ['',Validators.required],
      fecha_emision: ['',Validators.required],
      fecha_fin: ['',],
      accion : ['']
    });
  }

  ngOnInit(): void {
    this.getDatos(this.data)
  }

  getDatos(doc){
    const datos = doc.datosDocs || ''
    this.formulario.patchValue({
      idTramite : datos.idTramite,
      id_documento_adicional: datos.id_documento_adicional,
      descripcion : datos.descripcion,
      identificador : datos.identificador,
      fecha_emision : datos.fecha_emision,
      fecha_fin : datos.fecha_fin,
      accion  : datos && datos.id_estado_documento_adicional ? Parametricas.ACTUALIZAR : Parametricas.CREAR || null
    })
    console.log("formulariooo",this.formulario.value);
    
  }

  async guardarDocumento() {
    if (this.formulario.invalid) {
      Swal.fire({ text: 'Debe completar el formulario', icon: 'warning', confirmButtonText: 'Aceptar' })
      return
    }

    // Verificamos que la fecha de emision y vencimiento sean correctas
    let fechaEmision = new Date(this.formulario.value.fecha_emision)
    let fechaVencimiento = new Date(this.formulario.value.fecha_fin)

    // Validamos las fechas (Que la fecha de vencimiento sea posterior a la fecha de emision)
    // if (moment(fechaEmision).isAfter(fechaVencimiento, 'day')) {
    //   Swal.fire({ text: 'La fecha de vencimiento no puede ser inferior a la fecha de emision!', icon: 'warning', confirmButtonText: 'Aceptar' })
    //   return
    // }

    // ENVIAR DIRECTAMENTE AL BACKEND PA Q SE VAYA GUARDANDO UNO POR UNO LOS ARCHIVOS
    try {
      var resp = await lastValueFrom(this.documentosAdicionalesService.actualizarDocumentoAdicional(this.formulario.value))
      console.log("resp");
    } catch (error) {
      Swal.fire({ text: error.error.mensaje, icon: 'warning', confirmButtonText: 'Aceptar' })
      return
    }
    if (resp.codigo == 1) {
      this.dialog.close(resp);
    }
    Swal.fire({ text: 'Documento guardado correctamente', icon: 'success', confirmButtonText: 'Aceptar' })
    return
  }

}
