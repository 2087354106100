import { lastValueFrom } from 'rxjs';
import { TramiteService } from 'src/app/core/services/tramite.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { Parametricas } from 'src/app/core/enums/parametricas.enums';
import { RegistroService } from 'src/app/modules/registros/services/registro.service';
import { ImagenService } from '../../services/imagen.service'
import { Tipo_dato } from 'src/app/core/enums/parametricas.enums'

@Component({
  selector: 'app-carga-imagenes',
  templateUrl: './carga-imagenes.component.html',
  styleUrls: ['./carga-imagenes.component.scss']
})
export class CargaImagenesComponent implements OnInit {

  idTramite: number = 0;
  id: number = null;
  tipo: number = null;
  datosRegistro: number = null;
  enableSave: boolean = false;
  formImagenes: FormGroup;
  mensajeFotos = ''
  idImagen = null

  constructor(
    private tramiteService: TramiteService,
    private imagenService: ImagenService,
    private formBuilder: FormBuilder,
    private registroService: RegistroService,
    public dialogRef: MatDialogRef<CargaImagenesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.dialogRef.updateSize('870px', '680px')
    this.dialogRef.disableClose = true;

    this.formImagenes = this.formBuilder.group({
      imagenes_adicionales: this.formBuilder.array([])
    });

    // Recibimos los documentos obligatorios como parametro de entrada al componente
    this.idTramite = this.data.idTramite;
    this.id = this.data?.id ? this.data.id : null;
    this.tipo = this.data?.tipo ? this.data.tipo : null
    this.datosRegistro = this.data?.datosRegistro ? this.data.datosRegistro : null
    this.obtenerImagenes()
  }

  async obtenerImagenes() {
    let arrayVacio = (arreglo) => !Array.isArray(arreglo) || arreglo.length === 0;
    let tipoFotografia = ''
    try {
      let imagenResponse = await lastValueFrom(this.registroService.obtenemosImagenAdicionalesRegistros({
        idTramite: this.idTramite,
        id: this.id,
        tipo: this.tipo,
        datosRegistro: this.datosRegistro
      }))

      if (this.tipo == Tipo_dato.SUSTANCIA_REGISTRO) {
        tipoFotografia = 'tanques mayor a 5000 litros',
          this.mensajeFotos = 'Minimo 2 fotografías panorámicas del tanque de almacenaje que supere los 5.000 litros'

      }
      if (this.tipo == Tipo_dato.MAQUINARIA) {
        tipoFotografia = 'maquinaria propia'
        this.mensajeFotos = 'Minimo 2 fotografías, frontal y lateral de vehículos, maquinarias, aeronaves, barcazas, equipos'
      }
      if (this.tipo == Tipo_dato.SUCURSAL_LOCALES || this.tipo == Tipo_dato.SUCURSAL_LOCAL) {
        tipoFotografia = 'almacenaje de sustancia'
        this.mensajeFotos = 'Minimo 2 fotografías panorámicas del lugar donde se almacenarán las sustancias'
      }
      if (arrayVacio(imagenResponse.datos.imagenes)) {
        /*  Swal.fire({ 
           title: 'FOTOGRAFÍAS', 
           icon: 'warning',
           html: 
           '<p>Cargar fotografias de </p>'+
           tipoFotografia,
           confirmButtonText: 'Aceptar' }) */
      }

      for (let image of imagenResponse.datos.imagenes) {
        this.agregarImagenAdicional(image)
      }
    } catch (error) {
      console.log(error)
      Swal.fire({ text: error.error.mensaje, icon: 'warning', confirmButtonText: 'Aceptar' })
      return
    }
  }

  get imagenesAdicionales() {
    return this.formImagenes.controls['imagenes_adicionales'] as FormArray;
  }

  agregarImagenAdicional(imagenForm = null) {
    const imagenAdicionalForm = this.formBuilder.group({
      archivo: [imagenForm?.ruta ? imagenForm?.ruta : '', null],
      tipoArchivo: [imagenForm?.tipoArchivo ? imagenForm?.tipoArchivo : '', Validators.required],
      descripcion: [imagenForm?.descripcion ? imagenForm?.descripcion : '', Validators.required],
      base64: [imagenForm?.base64 ? imagenForm?.base64 : '', Validators.required],
      guardado: [imagenForm?.base64 ? true : false, null],
      idTramite: [this.idTramite, Validators.required],
      id: [this.id, null],
      idImagen: [imagenForm?.id_imagen_sucursal_local || imagenForm?.id_imagen_sustancia_registro
        || imagenForm?.id_imagen_maquinaria, null],
      tipo: [this.tipo, null]
    });

    if (imagenAdicionalForm.value.guardado)
      imagenAdicionalForm.controls['descripcion'].disable()
    this.imagenesAdicionales.push(imagenAdicionalForm);
  }

  openFileBrowser(index) {
    document.getElementById('imagen-' + index).click()
  }

  adjuntarArchivo(event: any, form: FormGroup, index: number): void {
    if (event.target.files.length > 0) {
      form.patchValue({
        base64: ''
      });

      const size = event.target.files[0].size;
      const fileType = event.target.files[0].type;
      const fileName = event.target.files[0].name;

      if (fileType !== 'image/jpg' && fileType !== 'image/jpeg' && fileType !== 'image/png') {
        Swal.fire({ text: 'Solo se aceptan archivos en formato .jpeg, jpg o .png', icon: 'warning', confirmButtonText: 'Aceptar' });
        return;
      }

      if (size > Parametricas.maxImageSize) {
        Swal.fire({ text: 'El archivo no puede tener mas de ' + Parametricas.maxImageSize / 1024 / 1024 + 'Mb de tamaño', icon: 'warning', confirmButtonText: 'Aceptar' });
        return;
      }

      form.patchValue({
        archivo: fileName
      });

      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = async (ev: any) => {
        const docBase64 = ev.target.result;

        document.getElementById('image-box-' + index).style.backgroundImage = "url('" + ev.target.result + "')"

        form.patchValue({
          base64: docBase64,
          tipoArchivo: fileType
        });
      };
    }
  }

  async guardarImagenAdicional(form: FormGroup) {
    if (form.invalid) {
      Swal.fire({ text: 'Debe agregar una imagen y su descripción', icon: 'warning', confirmButtonText: 'Aceptar' });
      return;
    }
    // Enviar al backend pa' q se vaya guardando uno por uno cada imagen
    try {
      var respImagen = await lastValueFrom(this.registroService.guardarImagenAdicionales(form.value));
      form.patchValue({
        guardado: true
      });
    } catch (error) {
      console.log(error);
      Swal.fire({ text: error.error.mensaje, icon: 'warning', confirmButtonText: 'Aceptar' });
      return;
    }
    this.idImagen = respImagen.datos.idImagen
    const ultimoFormGroup = this.imagenesAdicionales.at(this.imagenesAdicionales.length - 1) as FormGroup;
    ultimoFormGroup.controls['idImagen'].setValue(this.idImagen);
    form.controls['descripcion'].disable();
    Swal.fire({ text: 'Se adjuntó una nueva imagen a su tramite', icon: 'success', confirmButtonText: 'Aceptar' })

    form.controls['descripcion'].disable();
    Swal.fire({ text: 'Se adjuntó una nueva imagen a su tramite', icon: 'success', confirmButtonText: 'Aceptar' })
  }

  async eliminarImagen(imagenAdicionalForm: FormGroup, index: number) {
    console.log("imagenAdicionalForm", imagenAdicionalForm);
    if (imagenAdicionalForm.value.guardado) {
      // Enviamos al backend para eliminarlo
      try {
        await lastValueFrom(this.registroService.eliminarImagenAdicionalesRegistros({
          idTramite: imagenAdicionalForm.value.idTramite,
          id: imagenAdicionalForm.value.id,
          idImagen: imagenAdicionalForm.value.idImagen,
          tipo: imagenAdicionalForm.value.tipo
        }))

        Swal.fire({ text: 'Imagen eliminada correctamente', icon: 'success', confirmButtonText: 'Aceptar' })
      } catch (error) {
        console.log(error)
        Swal.fire({ text: error.error.mensaje, icon: 'warning', confirmButtonText: 'Aceptar' })
        return
      }
    }
    this.imagenesAdicionales.removeAt(index);
  }

  finalizar() {
    let filesNotSaved = 0;
    if (this.imagenesAdicionales.value.length < 2) {
      Swal.fire({ text: '¡Por favor, debe adjuntar al menos 2 fotografías!', icon: 'warning', confirmButtonText: 'Aceptar' })
      return
    }
    console.log('IMAGES', this.imagenesAdicionales.value);
    for (let imagenAdicional of this.imagenesAdicionales.value) {
      if (!imagenAdicional.guardado) {
        filesNotSaved++;
      }
    }

    if (filesNotSaved == 0) {
      // Devolvemos true porque no existen documentos sin guardar
      this.dialogRef.close({
        success: true,
        listaImagenes: this.imagenesAdicionales.value
      });
    } else {
      Swal.fire({
        text: 'Algunas imagenes no fueron guardadas, desea salir de todos modos',
        icon: 'warning',
        confirmButtonText: 'Aceptar',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        reverseButtons: true
      })
        .then((result) => {
          if (result.isConfirmed) {
            // Devolvemos true porque ya se guardo todo correctamente
            this.dialogRef.close({
              success: true,
              listaImagenes: this.imagenesAdicionales.value
            });
          } else {
            return;
          }
        });
    }
  }

}
