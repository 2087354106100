import { VisorPdfDialogoComponent } from 'src/app/core/components/visor-pdf-dialogo/visor-pdf-dialogo.component'
import { MatDialog } from '@angular/material/dialog'
import { Injectable } from '@angular/core'
import { lastValueFrom, Observable } from 'rxjs'
import Swal from 'sweetalert2'
import { BaseHttpService } from 'src/app/core/services/base-http.service'

@Injectable({
  providedIn: 'root'
})
export class CodigoVerificacionService {

  constructor(
    private baseHttpService: BaseHttpService,
    public pdfModalDialog: MatDialog
  ) { }

  // Solicitud de nuevo codigo de activacion
  async solicitarCodigo(data) {
    console.log('Service::: Solicitar nuevo codigo de activacion',data)
   
    Swal.fire({
      title: 'Espere por favor',
      text: 'Solicitando nuevo codigo de activacion',
      icon: 'info',
      allowOutsideClick: false
    })
    Swal.showLoading()

    try {
      var codigoResp = await lastValueFrom(this.baseHttpService.post("usuario/solicitar-codigo-activacion", data))
    } catch (error) {
      Swal.fire({ text: error.error.mensaje, icon: 'warning', confirmButtonText: 'Aceptar' })
      throw error.error.mensaje
    }

    Swal.fire({ text: 'Se envió un nuevo código de activación a su correo', icon: 'success', confirmButtonText: 'Aceptar' })

    return codigoResp.datos.usuario
  }

}
