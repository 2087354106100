<mat-card class="mat-card-container">
  <mat-card-title class="title">TIPO DE SUSTANCIA</mat-card-title> <br>
  <mat-card-subtitle class="subtitle"><strong>SELECCIONE EL TIPO DE SUSTANCIA</strong></mat-card-subtitle>
  <mat-card-content style="font-size: 0.79rem;">
    <mat-radio-group [(ngModel)]="seleccion" required >
      <div>
        <mat-radio-button disabled={{disabled}} [value]="this.hidrocarburos">Hidrocarburos</mat-radio-button>
      </div>
      <div>
        <mat-radio-button disabled={{disabledSus}} [value]="this.otras">Otras Sustancias</mat-radio-button>
      </div>
    </mat-radio-group>  
  </mat-card-content>
  <div mat-dialog-actions class="mt-3 mb-1">

    <mat-card-actions class="d-flex justify-content-end">
      <button mat-raised-button class="nuevo-button" [disabled]="!seleccion" [mat-dialog-close]="seleccion">
        <mat-icon>done</mat-icon>Aceptar
      </button>
      <button mat-raised-button color="warn" style="height: 45px;" (click)="cancel()">
        <mat-icon>cancel</mat-icon> Cancelar
      </button>
    </mat-card-actions>
  </div>
</mat-card>
