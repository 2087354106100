import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UsuarioService } from 'src/app/modules/auth/services/usuario.service';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-logincd',
  templateUrl: './logincd.component.html',
  styleUrls: ['./logincd.component.scss']
})
export class LogincdComponent implements OnInit {
  code: string;
  state: string;
  nonce: string;
  session_state: string;
  error: string;

  constructor(private rutaActiva: ActivatedRoute,
    private router: Router,
    private usuarioService: UsuarioService,
  ) { }

  async ngOnInit() {
    this.code = this.rutaActiva.snapshot.queryParams.code;
    this.state = this.rutaActiva.snapshot.queryParams.state;
    this.session_state = this.rutaActiva.snapshot.queryParams.session_state;

    this.nonce = this.rutaActiva.snapshot.queryParams.nonce;
    this.error = this.rutaActiva.snapshot.queryParams.error;

    if (this.error) {
      if (this.error == 'access_denied')
        Swal.fire('Información', 'El usuario ha cancelado la solicitud o se interrumpio el flujo.', 'info');
      else
        Swal.fire('Información', this.error, 'info');
      
      let ruta=localStorage.getItem("ultimaRuta") || "/";

      this.router.navigateByUrl(ruta);
      return;
    }

    //formato de error cuando cancelan el login CD
    /*
    https://dev.dgsc.gob.bo/login-cd?
    error=access_denied
    &error_description=End-User%20aborted%20interaction
    &state=8F-.6k9fIkAF2W4utC6x52zNIKmr31vYOyHcVfR4Kvhr0L5eTv
    &session_state=ifUX-v4ykCzfHMBRMqhun7gsp2r7wTMahEBjq5WA6bU.SrwT_itd6yU
    */


    //Ejemplo de respuesta correta
    /*
https://dev.dgsc.gob.bo/login-cd?code=pInYLg2~aD-KqQ39.6Y-SU6VCy~KgIod4WRlDosz2WWYarHzm3&state=pInYLg2~aD-KqQ39.6Y-SU6VCy~KgIod4WRlDosz2WWYarHzm3&session_state=l0XqpnOt3carEWfw89DI8mHmDdYgBLV-jF4y-_u0Uo0

    code: "nkTzADLRRq-8cHlVPwuWPZ-Wf8cEjYiGsNPSDKhP79j", 
    state: "8F-.6k9fIkAF2W4utC6x52zNIKmr31vYOyHcVfR4Kvhr0L5eTv", 
    session_state: "l0XqpnOt3carEWfw89DI8mHmDdYgBLV-jF4y-_u0Uo0" 
*/
    //si llegamos aqui-> todo OK, tenemos datos recibidos-> los validaremos con el backend
    try {
      var resp = await this.usuarioService.verificarCD(this.code, this.state, this.session_state ).toPromise();
    } catch (error) {
      Swal.fire('Error', error.error.mensaje, 'error');
      this.router.navigateByUrl(error.error.ruta);
    }
    console.log('resppppppppppppppppppp');
    console.log(resp);
    if(!resp) {
      Swal.fire('Error', 'Se tienen problemas con la autentificación', 'error');
      return;
    }
    // si todo es OK->pedimos el token de acceso y de identidad
    if (resp.codigo == 1) {
      console.log('OKKKKKKKK');
      this.router.navigateByUrl(resp.datos.ruta);
    } else {
      console.log('errrrrrrrrrrrrr');
      Swal.fire('Información', resp.mensaje, 'info');
      this.router.navigateByUrl(resp.datos.ruta);
    }
  }
}
