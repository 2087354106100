import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UsuarioService } from 'src/app/modules/auth/services/usuario.service';

import Swal from 'sweetalert2';
import { TramiteService } from 'src/app/core/services/tramite.service';

@Component({
  selector: 'app-notificacioncd',
  templateUrl: './notificacioncd.component.html',
  styleUrls: ['./notificacioncd.component.scss']
})
export class NotificacioncdComponent implements OnInit {
  finalizado: string;
  estado: string;
  mensaje: string;
  linkVerificacion: string;
  linkVerificacionUnico: string;
  transactionCode: string;
  requestUuid: string;
  uuidBlock: string;

  constructor(private rutaActiva: ActivatedRoute,
    private router: Router,
    private tramiteService: TramiteService,
  ) { }

  async ngOnInit() {
    console.log(this.rutaActiva.snapshot.queryParams);
    this.finalizado = this.rutaActiva.snapshot.queryParams.finalizado;
    this.estado = this.rutaActiva.snapshot.queryParams.estado;
    this.mensaje = this.rutaActiva.snapshot.queryParams.mensaje;
    this.linkVerificacion = this.rutaActiva.snapshot.queryParams.linkVerificacion;
    this.linkVerificacionUnico = this.rutaActiva.snapshot.queryParams.linkVerificacionUnico;
    this.transactionCode = this.rutaActiva.snapshot.queryParams.transactionCode;
    this.requestUuid = this.rutaActiva.snapshot.queryParams.requestUuid;
    this.uuidBlock = this.rutaActiva.snapshot.queryParams.uuidBlockchain;


    if (this.finalizado == "false") {
      if (this.mensaje.length > 0) {
        Swal.fire('Información', this.mensaje, 'info');
      } else {
        Swal.fire('Información', 'El usuario ha cancelado la solicitud o se interrumpio el flujo.', 'info');
      }

      var ultimaRuta = '';
      ultimaRuta = localStorage.getItem('ultimaRuta');
      console.log('ultimaRuta',ultimaRuta)
      if (ultimaRuta.length > 0) {
        this.router.navigateByUrl(ultimaRuta);
      } else {
        //por defecto
        this.router.navigateByUrl('/registro/ver');
      }
      return;
    }

    //si llegamos aqui-> todo OK, tenemos datos recibidos-> los validaremos con el backend
    try {
      var resp = await this.tramiteService
        .guardarDocumentoCD(this.linkVerificacion, this.linkVerificacionUnico, this.transactionCode, this.requestUuid, this.uuidBlock)
        .toPromise();
    } catch (error) {
      Swal.fire('Información', error.error.mensaje, 'error');
      this.router.navigateByUrl('/dashboard');
      return;
    }

    // si todo es OK->pedimos el token de acceso y de identidad
    console.log('guardarDocAprobado');
    console.log(resp);

    Swal.fire(resp.mensaje, '', 'success');
    //this.router.navigateByUrl(resp.datos.ruta);
    ultimaRuta = '';
    ultimaRuta = localStorage.getItem('ultimaRuta');
    console.log('ultimaRuta',ultimaRuta)
    if (ultimaRuta.length > 0) {
      this.router.navigateByUrl(ultimaRuta);
    } else {
      //por defecto
      this.router.navigateByUrl('/registro/ver');
    }
    return;    
  }
}
