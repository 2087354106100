<mat-card class="mat-card-container" >
    <mat-card-title class="title">DISTINCIÓN DE ENTIDAD</mat-card-title> <br>
    <mat-card-subtitle class="subtitle"><strong>SELECCIONE TIPO DE DISTINCIÓN</strong></mat-card-subtitle>
    <mat-card-content>
      <mat-radio-group [(ngModel)]="seleccionEntidad" required>
        <div>
          <mat-radio-button *ngFor="let entidad of entidades" [value]="entidad.id_tipo_registro" class="radio-button-with-linebreak">
            {{ entidad.nombre_tipo_registro }}
          </mat-radio-button>
        </div>
       
      </mat-radio-group>
    </mat-card-content>
    <mat-divider></mat-divider><br>
    
    <div *ngIf="seleccionEntidad==1">
      <!-- <mat-card-title class="title">TIPO DE PERSONERÍA</mat-card-title> <br> -->
      <mat-card-subtitle class="subtitle"><strong>SELECCIONE EL TIPO DE ADMINISTRADO</strong></mat-card-subtitle>
      <mat-card-content >
        <mat-radio-group [(ngModel)]="seleccionPersoneria" required>  
            <div>
                <mat-radio-button  *ngFor="let personeria of personerias" [value]="personeria.id_tipo_personeria"                
                class="radio-button-with-linebreak" >
                  {{ personeria.nombre_tipo_personeria }}<br>
                </mat-radio-button>
            </div>     
        </mat-radio-group>
    </mat-card-content>
    </div>
    <div *ngIf="seleccionEntidad==2">
      <!-- <mat-card-title class="title">TIPO DE PERSONERÍA</mat-card-title> <br> -->
      <mat-card-subtitle class="subtitle"><strong>SELECCIONE EL TIPO DE ADMINISTRADO</strong></mat-card-subtitle>
      <mat-card-content >
        <mat-radio-group [(ngModel)]="seleccionPersoneria" required>        
            <div>
                <mat-radio-button *ngFor="let personeria0 of personerias0" [value]="personeria0.id_tipo_personeria" 
                class="radio-button-with-linebreak"> {{ personeria0.nombre_tipo_personeria }}</mat-radio-button>
            </div>         
        </mat-radio-group>
    </mat-card-content>
    </div>
    <div mat-dialog-actions class="mt-3 mb-1">
  
      <mat-card-actions>
        <button mat-raised-button color="primary" [disabled]="!(seleccionEntidad && seleccionPersoneria)" [mat-dialog-close]="[seleccionEntidad,seleccionPersoneria]">
          <mat-icon>done</mat-icon>Aceptar
        </button>
        <button mat-raised-button color="warn" (click)="cancel()">
          <mat-icon>cancel</mat-icon> Cancelar
        </button>
     <!--    <button mat-stroked-button color="simple-outline" (click)="retornar()">
          <mat-icon>keyboard_return</mat-icon> Retornar
        </button> -->
      </mat-card-actions>
    </div>
  </mat-card>
  