import { VisorPublicoComponent } from './core/utils/visor-publico/visor-publico.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PageNotFoundComponent } from './core/components/page-not-found/page-not-found.component';
import { AuthRoutingModule } from './modules/auth/auth-routing.module';

const routes: Routes = [
  /* { path: 'login', loadChildren: () => import(`./modules/auth/auth.module`).then(m => m.AuthModule) }, */
  { path: 'pdf/:uuid', component: VisorPublicoComponent },
  { path: 'registro', loadChildren: () => import(`./modules/registro/registro.module`).then(m => m.RegistroModule) },
  { path: 'descargo', loadChildren: () => import(`./modules/descargos/descargos.module`).then(m => m.DescargosModule) },
  { path: 'dashboard', loadChildren: () => import(`./modules/dashboard/dashboard.module`).then(m => m.DashboardModule) },
  { path: 'reportes', loadChildren: () => import(`./modules/reportes/reportes.module`).then(m => m.ReportesModule) },
  { path: 'licencias-previas', loadChildren: () => import('./modules/licencias-previas/licencias-previas.module').then(m => m.LicenciasPreviasModule) },
  { path: 'laboratorio', loadChildren: () => import('./modules/laboratorio/laboratorio.module').then(m => m.LaboratorioModule) },
  { path: 'tramite', loadChildren: () => import('./modules/tramites/tramites.module').then(m => m.TramitesModule) },
  { path: 'compras', loadChildren: () => import('./modules/compras/compras.module').then(m => m.ComprasModule) },
  { path: 'hojas', loadChildren: () => import('./modules/hojas/hojas.module').then(m => m.HojasModule) },
  { path: 'entrega', loadChildren: () => import('./modules/entrega/entrega.module').then(m => m.EntregaModule) },
  { path: 'destruccion', loadChildren: () => import('./modules/destruccion/destruccion.module').then(m => m.DestruccionModule) },
  { path: 'viajes', loadChildren: () => import('./modules/viajes/viajes.module').then(m => m.ViajesModule) },
  {
    path: 'registros',
    loadChildren: () =>
      import('./modules/registros/registros.module').then(
        (m) => m.RegistrosModule
      ),
  },
  /*   {path: '',redirectTo:'auth',pathMatch: 'full'}, */
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: false, relativeLinkResolution: 'legacy' }),
    /*  RouterModule.forRoot(routes), */
    AuthRoutingModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
