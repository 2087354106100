import { Component, Input, OnInit } from '@angular/core';
import { UsuarioService } from 'src/app/modules/auth/services/usuario.service';
@Component({
  selector: 'app-header-info',
  templateUrl: './header-info.component.html',
  styleUrls: ['./header-info.component.scss']
})
export class HeaderInfoComponent implements OnInit {

 // @Input() headerLeftInfo: any;
 // @Input() headerRightInfo: any;

  constructor(  public _usuarioService: UsuarioService,) { }

  ngOnInit(): void {
  }

}
