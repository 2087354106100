<div class="row">
  <div class="col-12">
    <mat-card-title style="text-align: center; font-size: 20px;">SELECCIÓN DE ENVASES &nbsp;&nbsp;

    </mat-card-title>
    <!-- <mat-card-subtitle class="subtitle"><strong>SELECCIONAR EL TIPO Y CANTIDAD DE ENVASE.</strong></mat-card-subtitle>          -->

  </div>

</div>
<div class="row mat-elevation-z8">
  <div class="row">
    <mat-form-field class="col-md-9">
      <mat-label>Buscar Envase</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Cisterna" #input>
    </mat-form-field>

    <button class="col-md-3 nuevo-button" mat-raised-button matTooltip="Agregar en caso de no exitir envase"
      style="font-size: 13px" (click)="agregarEnvases()">
      <mat-icon class="color-verde-2">add</mat-icon>Nuevo Tipo Envase
    </button>
  </div>

  <table mat-table [dataSource]="listEnvases" class="table table-list">
    <ng-container matColumnDef="numero">
      <th mat-header-cell *matHeaderCellDef>Nro.</th>
      <td mat-cell *matCellDef="let element; let i = index">
        {{ i + 1}}
      </td>
    </ng-container>


    <ng-container matColumnDef="nombres">
      <th mat-header-cell *matHeaderCellDef>Envases</th>
      <td mat-cell *matCellDef="let element" class="simple-success">
        {{ element.nombre_envase }}
      </td>
    </ng-container>

    <ng-container matColumnDef="capacidad">
      <th mat-header-cell *matHeaderCellDef>Capacidad</th>
      <td mat-cell *matCellDef="let element" class="simple-success">
        {{ element.capacidad }}
      </td>
    </ng-container>

    <ng-container matColumnDef="abreviacion">
      <th mat-header-cell *matHeaderCellDef>Unidad</th>
      <td mat-cell *matCellDef="let element" class="simple-success">
        {{ element.abreviacion }}
      </td>
    </ng-container>

    <ng-container matColumnDef="acciones">
      <th mat-header-cell *matHeaderCellDef>Acciones</th>
      <td mat-cell *matCellDef="let element; let index = i" (click)="agregaEnvase(element)"
        style="width: 60px; padding: 0; text-align: center; cursor: pointer;">
        <button mat-icon-button color="simple-success" aria-label="Seleccione el envase" 
        matTooltip="Seleccionar">
          <mat-icon>task_alt</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnasEnvases"></tr>
    <tr mat-row *matRowDef="let row; columns: columnasEnvases"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">
        No hay datos que coincidan en la lista
      </td>
    </tr>
  </table>
  <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator>
  <!-- <mat-paginator [length]="length" [pageSize]="pageSize" [hidePageSize]="true" [showFirstLastButtons]="true"
      [pageSizeOptions]="pageSizeOptions" (page)="pageChanged($event)" aria-label="Seleccione la pagina">
    </mat-paginator> -->
</div>


<mat-card>
  <form [formGroup]="envaseSustanciaForm">
    <!-- <button mat-raised-button matTooltip="Agregar envases y cantidad" class="nuevo-button" (click)="agregaEnvase()">
          <mat-icon>add</mat-icon>Agregar Envase
        </button> -->

    <div formArrayName="envases">
      <div *ngFor="let env of envasesSustancias.controls; let i = index">
        <div [formGroupName]="i">
          <div class="row">
            <div class="col-md-1">
              <br>
              <button mat-icon-button matTooltip="Eliminar Registro" matTooltipClass="tooltip-background"
                (click)="remove(i)">
                <img class="color-delete" src="assets/svg/delete_forever.svg" />
              </button>
            </div>
            <div class="col-md-6">
              <mat-form-field style="width:100%" class="full-width" appearance="outline">
                <mat-label>Tipo de envase</mat-label>
                <mat-select formControlName="envase" [disabled]="true">
                  <mat-option *ngFor="let envase of envases" [value]="envase">
                    {{envase.nombre_envase}} │ {{envase.capacidad}} │ {{envase.abreviacion}}
                  </mat-option>
                </mat-select>
                <mat-error>Campo requerido</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-5">
              <mat-form-field style="width:100%" class="full-width" appearance="outline">
                <mat-label>Cantidad de envases</mat-label>
                <input matInput type="number" min="1" formControlName="cantidad_envase"
                  (keypress)="validarFormatoNumero($event)">
                <mat-error>Campo requerido</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div> <mat-divider></mat-divider>
      </div>
    </div>
    <br>
    <div mat-dialog-actions class="mt-3 mb-1">
      <button mat-raised-button class="nuevo-button" (click)="guardarEnvase()"><mat-icon>save</mat-icon>
        Guardar</button>&nbsp;
      <button mat-raised-button color="warn" (click)="cerrarDialog()"><mat-icon>cancel</mat-icon> Cancelar</button>
    </div>
  </form>
</mat-card>