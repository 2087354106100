import Swal from 'sweetalert2';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';


export class SwalObject {
  public textos: any;

  constructor(textos?: any, private router?: Router) {
    if (textos) {
      this.textos = textos;
    }
    else {
      this.textos = {
        titulo: "",
        texto: "",
        btnConfirmacion: "",
        btnCancelacion: "",
        tituloConfirmacion: "",
        textoConfirmacion: "",
        tituloCancelacion: "",
        textoCancelacion: ""
      }
    }
  }

  public error(titulo: string, texto: string) {
    Swal.fire({
      icon: 'error',
      title: titulo ? titulo : '',
      text: texto ? texto : 'Algo salio mal!',
    });
  }

  public async cargandoConfirmacion(texto: string, callback: (objeto?: any) => any, data?: any,textoConfirmacion: string = "", invocador: any = null) {
    await Swal.fire({
      title: "",
      text: texto ? texto : "",
      icon: "info",
      allowOutsideClick: false,
      didOpen: async () => {
        Swal.showLoading();
        try {
          await callback(data);

          Swal.close();

          Swal.fire({
            title: "",
            text: textoConfirmacion ? textoConfirmacion : "",
            icon: "success"
          }).then((result) => {
            if (invocador != null) {
              try {
                invocador.dialogRef.close();
              } catch (err) {
                // invocador.router.navigate(['/licencias-previas/autorizacion-previa/lista']);
                invocador.router.navigate(['/licencias-previas/bandeja-licencias-previas/lista']);
              }

            }
            Swal.close();
          });



        } catch (error: unknown) {
          console.log("ERRROOOOORR");
          console.log(error);
          Swal.close();
          if (typeof error === "string") {
            this.error('', error);
          } else if (error instanceof Error) {
            this.error('', error.message);
          } else if (error instanceof HttpErrorResponse) {
            this.error('', error.error.mensaje);
          }

        }
      }
    }).then((result) => {
      console.log("Entro al then");
    });


    console.log("Incio de cargando");


  }

  public async cargando(
    titulo: string,
    texto: string,
    icono: any,
    callback: (objeto?: any) => any, data?: any
  ) {
    await Swal.fire({
      title: titulo ? titulo : "",
      text: texto ? texto : "",
      icon: icono ? icono : "info",
      didOpen: async () => {
        Swal.showLoading();
        try {
          await callback(data);

          Swal.close();
        } catch (error: unknown) {
          Swal.close();
          this.muestraError(error);
        }
      }
    }).then((result) => {
    });
  }

  public muestraError(error: unknown) {
    if (typeof error === "string") {
      this.error('', error);
    } else if (error instanceof Error) {
      this.error('', error.message);
    } else if (error instanceof HttpErrorResponse) {
      this.error('', error.error.mensaje);
    } else {
      this.error('', "Algo salio mal!");
    }
  }

  public eliminacion(data: any, callback: (objeto: any) => any): void {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })

    swalWithBootstrapButtons.fire({
      title: this.textos.titulo ? this.textos.titulo : 'Esta Seguro?',
      text: this.textos.texto ? this.textos.texto : "Usted no podra revertir esta accion!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.textos.btnConfirmacion ? this.textos.btnConfirmacion : 'Si, eliminar!',
      cancelButtonText: this.textos.btnCancel ? this.textos.btnCancel : 'No, cancelar!',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          callback(data);
          swalWithBootstrapButtons.fire(
            this.textos.tituloConfirmacion ? this.textos.tituloConfirmacion : 'Eliminado!',
            this.textos.textoConfirmacion ? this.textos.textoConfirmacion : 'El Registro ha sido eliminado.',
            'success'
          )
        } catch (error: unknown) {
          if (typeof error === "string") {
            this.error('', error);
          } else if (error instanceof Error) {
            this.error('', error.message);
          }
        }
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          this.textos.tituloCancelacion ? this.textos.tituloCancelacion : 'Cancelado',
          this.textos.textoCancelacion ? this.textos.textoCancelacion : 'Se cancelo la eliminacion.',
          'error'
        )
      }
    });
  }

  public guardarCancelar(data: any, callback: (object: any) => any): void {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger',
      },
      buttonsStyling: false
    })

    swalWithBootstrapButtons.fire({
      title: this.textos.titulo ? this.textos.titulo : 'Esta Seguro que desea guardar ?',
      text: this.textos.texto ? this.textos.texto : "Usted no podra revertir esta accion!",
      icon: 'warning',

      showCancelButton: true,
      confirmButtonText: this.textos.btnConfirmacion ? this.textos.btnConfirmacion : 'Si, guardar!',
      cancelButtonText: this.textos.btnCancel ? this.textos.btnCancel : 'No, cancelar!',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          callback(data);
          swalWithBootstrapButtons.fire(
            this.textos.tituloConfirmacion ? this.textos.tituloConfirmacion : 'guardado!',
            this.textos.textoConfirmacion ? this.textos.textoConfirmacion : 'El Registro ha sido guardado.',
            'success'
          )
        } catch (error: unknown) {
          if (typeof error === "string") {
            this.error('', error);
          } else if (error instanceof Error) {
            this.error('', error.message);
          }
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        swalWithBootstrapButtons.fire(
          this.textos.tituloCancelacion ? this.textos.tituloCancelacion : 'Cancelado',
          this.textos.textoCancelacion ? this.textos.textoCancelacion : 'Se cancelo el registro.',
          'error'
        )
      }
    });

  }

  public guardarCancelarRegistro(data : any, callback: (object: any) => any, ruta: any, indicador:any): any {
    //console.log("RUTA SWALLLL",ruta);
    //this.router.navigate(["/registros/registro-nuevo/editar", 4405])
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger',
      },
      buttonsStyling: false
    })

    swalWithBootstrapButtons.fire({
      title: this.textos.titulo ? this.textos.titulo : 'Esta Seguro que desea guardar ?',
      text: this.textos.texto ? this.textos.texto : "Usted no podra revertir esta accion!",
      icon: 'warning',

      showCancelButton: true,
      confirmButtonText: this.textos.btnConfirmacion ? this.textos.btnConfirmacion : 'Si, guardar!',
      cancelButtonText: this.textos.btnCancel ? this.textos.btnCancel : 'No, cancelar!',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          callback(data);
          swalWithBootstrapButtons.fire(
            this.textos.tituloConfirmacion ? this.textos.tituloConfirmacion : 'guardado!',
            this.textos.textoConfirmacion ? this.textos.textoConfirmacion : 'El Registro ha sido guardado.',
            'success'
          )
          
          //indicador.router.navigate(ruta)
          
         //this.router.navigate(["/registros/registro-nuevo/editar", 4405])
        } catch (error: unknown) {
          if (typeof error === "string") {
            this.error('', error);
          } else if (error instanceof Error) {
            this.error('', error.message);
          }
        }
        //indicador.router.navigate([ruta])
        //ruta
        //console.log("RUTAAAA",ruta);
        
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        swalWithBootstrapButtons.fire(
          this.textos.tituloCancelacion ? this.textos.tituloCancelacion : 'Cancelado',
          this.textos.textoCancelacion ? this.textos.textoCancelacion : 'Se cancelo el registro.',
          'error'
        )
      }
      
    });
   
  }


  public eliminarRegistro(data: any,callback: (object: any) => any, accion: any)  {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger',
      },
      buttonsStyling: false
    })
    var datosRegistro : any = {}
    swalWithBootstrapButtons.fire({
      title: "Eliminar Registro",
      text: "¿Esta seguro que desea Eliminar?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: "Sí, eliminar",
      cancelButtonText: "Cancelar",
    })
      .then(resultado => {
        if (resultado.value) {
          // click en "Sí"
          const valorAccion = {
          accion: accion
          }  
          data = Object.assign(data, valorAccion)
          console.log("RESPUESTA",data);

          try {
            callback(data);
          } catch (error) {
            if (typeof error === "string") {
              this.error('', error);
            } else if (error instanceof Error) {
              this.error('', error.message);
            }
          }
        } else {
          datosRegistro = null
          // click en "NO"
        }
      }
    );
  }

   
}
