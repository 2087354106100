export class StepperModel {
  id_tipo_personeria?: number;
  nombre_tipo_personeria?: string;
  nit?: number;
  regimen?: string;
  ci?: string;
  complemento?: string;
  matricula_funda_empresa?: string;
  razon_social?: string;
  tipo_sociedad?: string;
  testimonio?: string;
  numero_registro_nuevo?: string;
  estrategico? : boolean;
  sin_fines_lucro?: boolean;
  empresa :string;
  configuracion: any = {

    '0': {
      id_tipo_personeria: false,
      nombre_tipo_personeria: false,
      nit: false,
      ci: false,
      regimen: false,
      complemento: false,
      matricula_funda_empresa: false,
      razon_social: false,
      tipo_sociedad: false,
      testimonio: false,
      estrategico : false
    },

    /**PERSONA NATURAL */
    '2': {
      id_tipo_personeria: false,
      nombre_tipo_personeria: true,
      nit: true,
      regimen: true,
      ci: true,
      complemento: true,
      matricula_funda_empresa: false,
      razon_social: true,
      tipo_sociedad: false,
      testimonio: false,
      estrategico : false,
      sin_fines_lucro : false
    },

    /**UNIPERSONAL*/
    '3': {
      id_tipo_personeria: true,
      nombre_tipo_personeria: true,
      nit: true,
      regimen: true,
      ci: false,
      complemento: false,
      matricula_funda_empresa: true,
      razon_social: true,
      tipo_sociedad: false,
      testimonio: false,
      estrategico : false,
      sin_fines_lucro : false,
      empresa : true
    },

    /**SOCIEDAD COMERCIAL */
    '4': {
      id_tipo_personeria: true,
      nombre_tipo_personeria: true,
      nit: true,
      regimen: true,
      ci: false,
      complemento: false,
      matricula_funda_empresa: true,
      razon_social: true,
      tipo_sociedad: true,
      testimonio: false,
      estrategico : false,
      empresa : true,
      sin_fines_lucro : false
    },

    /**EMPRESA PUBLICA*/
    '5': {
      id_tipo_personeria: true,
      nombre_tipo_personeria: true,
      nit: true,
      regimen: true,
      ci: false,
      complemento: false,
      matricula_funda_empresa: true,
      razon_social: true,
      tipo_sociedad: true,
      testimonio: false,
      estrategico : true,
      empresa : true
    },

    /**ENTIDAD PUBLICA */
    '6': {
      id_tipo_personeria: true,
      nombre_tipo_personeria: true,
      nit: true,
      regimen: true,
      ci: false,
      complemento: false,
      matricula_funda_empresa: false,
      razon_social: true,
      tipo_sociedad: false,
      testimonio: false,
      estrategico : false
    },

    /**COPEERATIVA MINERA */
    '1': {
      id_tipo_personeria: true,
      nombre_tipo_personeria: true,
      nit: true,
      regimen: true,
      ci: false,
      complemento: false,
      matricula_funda_empresa: false,
      razon_social: true,
      sin_fines_lucro : false,
    },

    /**ASOCIACIONES */
    '7': {
      id_tipo_personeria: true,
      nombre_tipo_personeria: true,
      nit: true,
      regimen: true,
      ci: false,
      complemento: false,
      numero: false,
      razon_social: true,
      sin_fines_lucro : true,
    },

    /**FUNDACIONES */
    '8': {
      id_tipo_personeria: true,
      nombre_tipo_personeria: true,
      nit: true,
      regimen: true,
      ci: false,
      complemento: false,
      numero: false,
      razon_social: true,
      sin_fines_lucro : true,
    },
  };
}
