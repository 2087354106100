import { Component, OnInit } from '@angular/core';
import { UsuarioService } from 'src/app/modules/auth/services/usuario.service';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  cargando = true;
  environment=environment.production

  constructor(private usuarioService: UsuarioService) {
  }

  ngOnInit(): void {
    
  }

  ngAfterViewChecked() {
    this.cargando = false;
  }

  async logincd() {
    try {
      var resp: any = await this.usuarioService.loginCD('/seleccionar-registro').toPromise();
      console.log(resp);
     
    } catch (error) {
      Swal.fire('Error', error.message, 'error');
      return;
    }

    (<any>window).location.href = resp.datos.url;
  }
}
