import { Component, OnInit} from '@angular/core';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { lastValueFrom} from 'rxjs';
import { MatDialogRef} from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { EnvaseService } from "../../../../../core/services/parametricas/envase.service";
import { UnidadMedidaService } from '../../../services/unidad-medida.service';
import { UnidadMedidaModel } from '../../../models/UnidadMedida.model';
/**
* Componente que permite gestionar los envases
*/
@Component({
  selector: 'app-agregar-envases',
  templateUrl: './agregar-envases.component.html',
  styleUrls: ['./agregar-envases.component.scss']
})

export class AgregarEnvasesComponent implements OnInit {
  /**
   * Datos del formulario de envase
   */
  public envaseForm: FormGroup;
  /**
   * Arreglo de los unidades de medida
   */
  public unidadesMedidas: UnidadMedidaModel[] = [];
  /**
   * Constructor que crea los servicios envase y unidad de medida
   */
  constructor(
        /**
    * Constructor instancia el formbuilder
    */
    private formBuilder: FormBuilder,
    /**
    * Constructor que crea los servicios tipo envases
    */
    private envaseService: EnvaseService,
    /**
* objeto dialogRef para gestion los objetos MatDialog
*/
    public dialogRef: MatDialogRef<AgregarEnvasesComponent>,
      /**
    * Constructor que crea los servicios de unidad de medida
    */
    private unidadMedidaService: UnidadMedidaService
  ) {
   /**
    * formulario para la creacion de tipo de envases
    */
    this.envaseForm = this.formBuilder.group({
      id_unidad_medida: ['', null],
      nombre_envase: ['', Validators.pattern(/^(?!\s)(?!.*\s$)(?!.*').*$/)],
      descripcion: ['', Validators.pattern(/^(?!\s)(?!.*\s$)(?!.*').*$/)],
      capacidad: ['', null]
    });
  }
  /**
   * Inicializa el listado de unidad de medida
   */
  ngOnInit(): void {
    this.dialogRef.updateSize('auto', 'auto')
    this.getUnidadesMedidas();
  }
        /**
   * Funcion para eliminar espacio en blanco
   */
        validateEnvase(event: any) {
          const inputValue = event.target.value;
          const trimmedValue = inputValue.trim();
      
          if (inputValue !== trimmedValue) {
            // El valor tiene espacios en blanco al inicio o al final
            this.envaseForm.controls['nombre_envase'].setErrors({ 'spaces': true });
          } else {
            this.envaseForm.controls['nombre_envase'].setErrors(null);
          }
        }
        
        
        eliminarEspacios() {

        const campo1 = this.envaseForm.get('nombre_envase');
        if (campo1.value) {
          campo1.setValue(campo1.value.trim());
        }
        const campo2 = this.envaseForm.get('descripcion');
        if (campo2.value) {
          campo2.setValue(campo2.value.trim());
        }
       
      }
        /**
   * Funcion para no permitir exponente en los inputs
   */
      validarExponente(event: KeyboardEvent) {
        const key = event.key;
    
        // Verificar si la tecla presionada es 'e' o 'E'
        if (key === 'e' || key === 'E') {
          // Cancelar el evento del teclado
          event.preventDefault();
        }
      }
  /**
   * Método que permite guardar un nuevo tipo de envase
   */
  async guardarEnvase() {
    if (this.envaseForm.invalid) {
      Swal.fire('', '¡Complete campos requeridos, por favor!', 'warning');
      return;
    }

    if (this.envaseForm.value.id_unidad_medida == '' || this.envaseForm.value.capacidad == '') {
      Swal.fire('', 'Capacidad y unidad de medida requeridas', 'warning');
      return;
    }
    var respuesta = await Swal.fire({
      title:"¿Desea guardar el registro?", text:"¡No podrás revertir esto! ",icon: "info",
      showCancelButton: true,confirmButtonText: "Si, guardar",
      cancelButtonText: "No, cancelar",
      showCloseButton: true })   
      if (!respuesta.isConfirmed) return
      Swal.fire("", "Espere por favor", "info");
      Swal.fire({
        title: "Espere por favor...",
        html: "¡Almacenando Registro!",
        allowOutsideClick: false,
      });
      Swal.showLoading()

    try {
      await lastValueFrom(this.envaseService.crear(this.envaseForm.value));
    } catch (error) {
      Swal.fire('', error.error.mensaje, 'warning');
      return;
    }
    Swal.close()
    Swal.fire({title:"¡Guardado!", text:"Registro exitoso.",icon: "success",
      confirmButtonText: 'Aceptar',showCloseButton: true})
     
    this.dialogRef.close();

  }
  /**
   * Método que permite obtener el listado de las unidades de medida de la BD
   */
  getUnidadesMedidas(): void {
    this.unidadMedidaService.obtenerTodos().subscribe({
      next: data => {
        this.unidadesMedidas = data.datos;
      },
      error: error => {
      
      }
    });
  }
  /**
   * Método que permite cerrar la ventana devolviendo false como respuesta
   */
  cerrarDialog(): void {
    this.dialogRef.close({ res: false, code: 0 });
  }
}
