import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { tap, map, catchError } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import { Usuario } from "../models/usuario.model";
import { CredentialsService } from "./credentials.service";
import { BaseHttpService } from "../../../core/services/base-http.service";
import { LoginForm } from "../models/login-form.model";

const base_url = environment.base_url;
const base_url_sistema = environment.base_url_sistema;
const base_urlFD = environment.base_urlFD;

@Injectable({
  providedIn: "root",
})
export class UsuarioService {
  public auth2: any;
  public usuario: Usuario;

  constructor(
    private baseHttpService: BaseHttpService,
    private credentialsService: CredentialsService,
    private http: HttpClient,
    private router: Router
  ) {
    this.usuario = credentialsService.getLoggedUser();
  }

  get token(): string {
    return localStorage.getItem('token') || '';
  }

  get uid(): string {
    return this.usuario.id || "";
  }

  get headers() {
    return { headers: { "x-token": this.token } };
  }

  logout() {
    this.limpiarSesion();
    this.router.navigateByUrl("/login");
  }

  limpiarSesion() {
    localStorage.removeItem('token');
    localStorage.removeItem('usuario');
    this.usuario = null;
  }

  validarToken(): Observable<boolean> {
    return this.baseHttpService.get("auth/renovar-token").pipe(
      map((resp: any) => {
       // console.log('actualizando token')
        this.credentialsService.setTokenAuth(resp.datos.token);
        //this.credentialsService.setUserAuth(resp.datos.usuario);
        //this.usuario = resp.datos.usuario;
        return true;
      }),
      catchError((error) => of(false))
    );
  }

  //AUTENTIFICACION
  // Login con Cuenta Institucional DGSC
  loginDGSC(formData: LoginForm) {
    return this.baseHttpService.post("auth/login-dgsc", formData).pipe(
      tap((resp) => {
        this.credentialsService.setTokenAuth(resp.datos.token);
        this.credentialsService.setUserAuth(resp.datos.usuario);
        this.usuario = resp.datos.usuario;
      })
    );
  }

  // Login con Ciudadania Digital
  loginCD(ruta: string) {
    const body = { ruta: ruta };
    return this.baseHttpService.post(`auth/login-cd`, body);
  }

  // Verifica los datos de CD
  verificarCD(code, state, session_state) {
    let httpOptions: any;
    if (this.token.length > 0) {
      httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          authorization: "Bearer " + this.token,
        }),
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          authorization: "error",
        }),
      };
    }

    const body = { code: code, state: state, session_state: session_state };

    return this.http
      .post(`${base_url}/auth/verificar-cd`, body, httpOptions)
      .pipe(
        tap((resp: any) => {
          console.log(resp.datos.ruta);
          //en caso de iniciar sesion la primera vez->guardamos en el storage el token y el usuario
          if (resp.datos.ruta == "/seleccionar-registro" || resp.datos.ruta == "/") {
            this.credentialsService.setTokenAuth(resp.datos.token);
            this.credentialsService.setUserAuth(resp.datos.usuario);
            this.usuario = resp.datos.usuario;
          } else {
            console.log('actualizaremos el token de CD');
            console.log(this.usuario);
            //caso contrario solo actualizaremos el token de CD
            this.credentialsService.setTokenAuth(resp.datos.token);
            this.credentialsService.setUserAuth(resp.datos.usuario);
            this.usuario = resp.datos.usuario;
          }
        })
      );
  }
  seleccionarRegistroAdministrado(registro: string) {
    let formData = { registro: registro };

    return this.baseHttpService.post("auth/seleccionar-registro", formData).pipe(
      tap((respReg) => {
        console.log('RESP USER', respReg.datos.usuario)
        this.credentialsService.setTokenAuth(respReg.datos.token);
        this.credentialsService.setUserAuth(respReg.datos.usuario);
        this.usuario = respReg.datos.usuario;
      })
    );
  }

  // PERSONA 
  registrarPersona(persona: any) {
    return this.baseHttpService.post("persona/", persona);
  }

  //USUARIO 
  registrarUsuario(usuario: any) {
    return this.baseHttpService.post("usuario/", usuario);
  }

  // Solicitud de nuevo codigo de activacion
  solicitarCodigoActivacion(data: any) {
    return this.baseHttpService.post("usuario/solicitar-codigo-activacion", data);
  }

  activarUsuarioNuevo(data: any) {
    // let formData = { codigo: codigo, idUsuario: idUsuario };
    return this.baseHttpService.post("usuario/activar-usuario", data);
  }

  //Usuario que no recuerda su clave puede solicitar restaurarlo
  solicitarRestablecerClave(correo: string) {
    let formData = { correo: correo };
    return this.baseHttpService.post("usuario/solicitar-restablecer-password", formData);
  }

  // Si tiene el codigo correcto->puede cambiar su clave aunq no recuerde el anterior
  restablecerClave(formData: any) {
    return this.baseHttpService.post("usuario/restablecer-password", formData);
  }

  //Usuario autentificado puede cambiar su clave actual por uno nuevo
  cambiarClaveUsuario(formData: any): Observable<any> {
    return this.baseHttpService.post("usuario/cambiar-password", formData);
  }

  guardarDocumentoAD(linkVerificacion, linkVerificacionUnico, transactionCode, requestUuid, uuidBlock) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        authorization: "Bearer " + this.token,
      }),
    };
    const body = { linkVerificacion, linkVerificacionUnico, transactionCode, requestUuid, uuidBlock };
    return this.http.post(`${base_url}/notificacioncd/guardarDocumentoAD`, body, httpOptions);
  }

  //PARAMETROS 
  getPaises() {
    return this.http.get(`${base_url_sistema}/parametro/paises`);
  }

  existeToken(token: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        reset: token,
      }),
    };
    return this.http.put(`${base_url}/login/existeToken`, null, httpOptions);
  }

  getRegistro(numRegistro: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        authorization: "Bearer " + this.token,
      }),
    };
    return this.http.post(`${base_url}/registro/` + numRegistro, null, httpOptions);
  }

  getDescargoResumen(idSusDescargo: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        authorization: "Bearer " + this.token,
      }),
    };
    return this.http.post(`${base_url}/descargo/resumen/` + idSusDescargo, null, httpOptions);
  }

  /*   validaIdv4(idv4: String): Observable<any> {
      return this.http.get(`${base_url}/validaDoc/` + idv4);
    } */

  getTokenData(slot, pin): Observable<any> {
    let parametros = { slot: slot, pin: pin };
    return this.http.post(`${base_urlFD}/api/token/data`, parametros);
  }

}
