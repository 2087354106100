import { Component, Inject, OnInit } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { environment } from "../../../../../environments/environment";
import { Pais } from 'src/app/core/models/pais.model'
import Swal from 'sweetalert2'
import { UsuarioService } from 'src/app/modules/auth/services/usuario.service'
import { ValidadorService } from 'src/app/core/services/validador.service'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { lastValueFrom } from 'rxjs'
import { Parametricas } from 'src/app/core/enums/parametricas.enums'
import { DatePipe } from '@angular/common';

export interface DialogData {
  tiposDocumento: string
  tipoValidacion: string
  tipoPersona: string
  popupObligatorio: string
}

@Component({
  selector: 'app-registro-persona',
  templateUrl: './registro-persona.component.html',
  styleUrls: ['./registro-persona.component.scss']
})
export class RegistroPersonaComponent implements OnInit {
  public prueba: boolean=false
  public registroForm: FormGroup
  public registroRejapForm: FormGroup
  public validacionesDireccion = []
  public validacionesTelefono = []
  public validacionescorreoPersonal = [Validators.email]

  public paises: Pais[] = []
  public tiposDocumento = [
    { nombre: 'CI (SEGIP)', id: 0 },
   { nombre: 'CI/DOC EXTRANJERO', id: 4 },
  ]
  public tiposDocumentoUsuario = [
    { nombre: 'CI (SEGIP)', id: 0 },
  ]
  public tiposComplemento = [
    { nombre: 'Con complemento', id: 1 },
    { nombre: 'Sin complemento', id: 0 },
  ]
  nacionalidadName: string = ''


  constructor(
    private dp: DatePipe,
    private formBuilder: FormBuilder,
    private usuarioService: UsuarioService,
    private _validadorService: ValidadorService,
    public dialogRegistroPersona: MatDialogRef<RegistroPersonaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }


  ngOnInit(): void {
    this.prueba=!environment.production
    this.getPaises()
    //this.pais.id_pais=Parametricas.NACIONALIDAD_BOLIVIANA_ID

    if (this.data.tiposDocumento == 'EXTRANJERO') {
      this.tiposDocumento.push({ nombre: 'PASAPORTE', id: 2 },)
      this.tiposDocumento.push({ nombre: 'CI EXTRANJERO', id: 4 },)
    }
    
    if (this.data.tipoValidacion == 'FULL') {
      this.validacionesTelefono = [Validators.required]
      this.validacionescorreoPersonal = [Validators.required, Validators.email]
    }
    if (this.data.tipoPersona == 'CONDUCTOR') {
      this.validacionesDireccion = [Validators.required]
    }

    if (!this.usuarioService.usuario) {
      this.tiposDocumento = this.tiposDocumentoUsuario
    }

    // this.dialogRegistroPersona.updateSize('80%', '430px')
    this.dialogRegistroPersona.updateSize('auto', '65%')
    if (this.data.popupObligatorio) {
      this.dialogRegistroPersona.disableClose = true
    }
    this.defineReglasValidacion()
  }
     
  defineReglasValidacion() { 
    console.log('defineReglasValidacion')  
    this.registroForm = this.formBuilder.group({
      tipoDocumento: [null, Validators.required],
      numeroDocumento: [null, [Validators.required,Validators.pattern(/^(?!\s)(?!.*\s$)(?!.*').*$/)]],
      complementoVisible: [null, Validators.required],
      complemento: [null,Validators.pattern(/^(?!\s)(?!.*\s$)(?!.*').*$/)],
      paisNacimiento: [Parametricas.CODIGO_PAIS_BOLIVIA, Validators.required],
      nacionalidad: [null, Validators.required],
      nombres: [null,[Validators.required,Validators.pattern(/^(?!\s)(?!.*\s$)(?!.*').*$/)]],
      primerApellido: [null, Validators.pattern(/^(?!\s)(?!.*\s$)(?!.*').*$/)],
      //segundoApellido: [null, Validators.pattern(/^(?!\s)(?!.*\s$)(?!.*).*$/)],
      segundoApellido: [null],
      fechaNacimiento: [null, Validators.required],
      //direccion: [null,[Validators.pattern(/^(?!\s)(?!.*\s$)(?!.*').*$/),Validators.maxLength(100)], this.validacionesDireccion],
      direccion: [null,[Validators.maxLength(100)]],
      telefono: [null, [Validators.required,Validators.minLength(8), Validators.maxLength(8), Validators.pattern('^[0-9]{8}$')]],
      // correoPersonal: [null, [Validators.required, Validators.email,Validators.pattern(/^\S(?:.*\S)?$/)]],
      //correoPersonal: [null, [Validators.required, Validators.email, Validators.pattern(/^[a-zA-Z0-9.@_\-]+$/)]],
      correoPersonal: [null, [Validators.required]],
      tipoPersona: [null, null],
    })
    //console.log(this.registroForm.controls['segundoApellido'].errors);
 /*    this.registroRejapForm = this.formBuilder.group({
      tipoDocumento: [null, Validators.required],
      numeroDocumento: [null, Validators.required],
      complementoVisible: [null, Validators.required],
      complemento: [null],
      numeroRejap: [null, Validators.required]     
    }) */
  }

  async getPaises() {
    try {
      var resp: any = await lastValueFrom(this.usuarioService.getPaises())
    } catch (error) {
      Swal.fire({ text: 'Sucedio un error inesperado', icon: 'warning', confirmButtonText: 'Aceptar' })
      return
    }
    this.paises = resp.datos.paises
    this.nacionalidadName = Parametricas.NACIONALIDAD_BOLIVIANA_NOMBRE
    this.registroForm.patchValue({
      nacionalidad:'BO',
      tipoPersona:Parametricas.NACIONALIDAD_BOLIVIANA_ID
    })    
  }

  seleccionarPais(paisID) {
    let nacionalidadId = Parametricas.NACIONALIDAD_BOLIVIANA_ID
    let nacionalidadAbbr = Parametricas.NACIONALIDAD_BOLIVIANA_ABBR
    this.nacionalidadName = Parametricas.NACIONALIDAD_BOLIVIANA_NOMBRE

    if (paisID.value !== Parametricas.CODIGO_PAIS_BOLIVIA) {
      nacionalidadId = Parametricas.NACIONALIDAD_EXTRANJERA_ID
      nacionalidadAbbr = Parametricas.NACIONALIDAD_EXTRANJERA_ABBR
      this.nacionalidadName = Parametricas.NACIONALIDAD_EXTRANJERA_NOMBRE
    }

    this.registroForm.patchValue({
      nacionalidad: nacionalidadAbbr,
      tipoPersona: nacionalidadId
    })
  }

  toggleComplemento(complementoVisible) {
    this.registroForm.controls.complemento.disable()
    this.registroForm.controls.complemento.clearValidators()

    if (complementoVisible.value !== 0) {
      this.registroForm.controls.complemento.enable()
      this.registroForm.controls.complemento.setValidators([Validators.required])
    }

    this.registroForm.controls.complemento.updateValueAndValidity()
  }
  
  toggleComplementoRejap(complementoVisible) {
    this.registroRejapForm.controls.complemento.disable()
    this.registroRejapForm.controls.complemento.clearValidators()

    if (complementoVisible.value !== 0) {
      this.registroRejapForm.controls.complemento.enable()
      this.registroRejapForm.controls.complemento.setValidators([Validators.required])
    }

    this.registroRejapForm.controls.complemento.updateValueAndValidity()
  }
  formsAreValid() {
    return this.registroForm.valid
  }
  validateInput(event: any) {
    const inputValue = event.target.value;
    const trimmedValue = inputValue.trim();
  
    if (inputValue !== trimmedValue) {
      // El valor tiene espacios en blanco al inicio o al final
      this.registroForm.controls['primerApellido'].setErrors({ 'spaces': true });
    } else {
      this.registroForm.controls['primerApellido'].setErrors(null);
    }
  
  }
  validateSegundoApellido(event: any) {
    const inputValue = event.target.value;
    const trimmedValue = inputValue.trim();
    console.log('inputValue , trimmedValue',inputValue , trimmedValue)

    if (inputValue !== trimmedValue) {
      // El valor tiene espacios en blanco al inicio o al final
      this.registroForm.controls['segundoApellido'].setErrors({ 'spaces': true });
      
    } else {
      this.registroForm.controls['segundoApellido'].setErrors(null);
    }
  }
  validateNombres(event: any) {
    const inputValue = event.target.value;
    const trimmedValue = inputValue.trim();

    if (inputValue !== trimmedValue) {
      // El valor tiene espacios en blanco al inicio o al final
      this.registroForm.controls['nombres'].setErrors({ 'spaces': true });
    } else {
      this.registroForm.controls['nombres'].setErrors(null);
    }
  }
  validateDireccion(event: any) {
    
    const inputValue = event.target.value;
    const trimmedValue = inputValue.trim();
    console.log(inputValue,trimmedValue)
/*
    if (inputValue !== trimmedValue) {
      // El valor tiene espacios en blanco al inicio o al final
      this.registroForm.controls['direccion'].setErrors({ 'spaces': true });
    } else {
      this.registroForm.controls['direccion'].setErrors(null);
    } */
  }
  validateCelular(event: any) {
    const inputValue = event.target.value;
    const trimmedValue = inputValue.trim();

    if (inputValue !== trimmedValue) {
      // El valor tiene espacios en blanco al inicio o al final
      this.registroForm.controls['telefono'].setErrors({ 'spaces': true });
    } else {
      this.registroForm.controls['telefono'].setErrors(null);
    }
  }
  validateCorreo(event: any) {
    const inputValue = event.target.value;
    const trimmedValue = inputValue.trim();

    if (inputValue !== trimmedValue) {
      // El valor tiene espacios en blanco al inicio o al final
      this.registroForm.controls['correoPersonal'].setErrors({ 'spaces': true });
    } else {
      this.registroForm.controls['correoPersonal'].setErrors(null);
    }
  }
  validateCI(event: any) {
    const inputValue = event.target.value;
    const trimmedValue = inputValue.trim();

    if (inputValue !== trimmedValue) {
      // El valor tiene espacios en blanco al inicio o al final
      this.registroForm.controls['numeroDocumento'].setErrors({ 'spaces': true });
    } else {
      this.registroForm.controls['numeroDocumento'].setErrors(null);
    }
  }
  validateCom(event: any) {
    const inputValue = event.target.value;
    const trimmedValue = inputValue.trim();

    if (inputValue !== trimmedValue) {
      // El valor tiene espacios en blanco al inicio o al final
      this.registroForm.controls['complemento'].setErrors({ 'spaces': true });
    } else {
      this.registroForm.controls['complemento'].setErrors(null);
    }
  }
  
  
  async registrar() {
    // Limpiamos los valores de entrada haciendo un trim
    await Object.keys(this.registroForm.value).forEach(key => {
      if (typeof this.registroForm.value[key] === 'string')
        this.registroForm.controls[key].patchValue(this.registroForm.value[key].trim())
    })

    this.validarDatos()

    if ((this.registroForm.value.primerApellido == null || this.registroForm.value.primerApellido == '') &&
      (this.registroForm.value.segundoApellido == null || this.registroForm.value.segundoApellido == '')) {

      this.registroForm.patchValue({
        primerApellido: null,
        segundoApellido: null,
      })

      //this.registroForm.controls.primerApellido.setValidators([Validators.required])
      this.registroForm.controls.primerApellido.updateValueAndValidity()
    } else {
      this.registroForm.controls.primerApellido.clearValidators()
      this.registroForm.controls.primerApellido.updateValueAndValidity()
    }

    this.registroForm.markAllAsTouched()

    if (this.registroForm.invalid) {
      Swal.fire({ text: 'Debe completar el formulario', icon: 'warning', confirmButtonText: 'Aceptar' })
      return
    }

    let datosPersona = this.registroForm.value

    datosPersona.nombres = datosPersona.nombres ? datosPersona.nombres.toUpperCase() : null
    datosPersona.primerApellido = datosPersona.primerApellido ? datosPersona.primerApellido.toUpperCase() : null
    datosPersona.segundoApellido = datosPersona.segundoApellido ? datosPersona.segundoApellido.toUpperCase() : null
    datosPersona.direccion = datosPersona.direccion ? datosPersona.direccion.toUpperCase() : null
    datosPersona.correoPersonal = datosPersona.correoPersonal ? datosPersona.correoPersonal.toLowerCase() : null

    if (datosPersona.complementoVisible === 0)
      datosPersona.complemento = null

    if (typeof (datosPersona.fechaNacimiento) == typeof (new Date())) {
      datosPersona.fechaNacimiento = '' + datosPersona.fechaNacimiento.format('YYYY/MM/DD')
    }

    datosPersona.validacionFull = (this.data.tipoValidacion == 'FULL') ? true : false

    Swal.fire({ title: 'Espere', text: 'Contrastando información', icon: 'info', allowOutsideClick: false })
    Swal.showLoading()

    try {
      var resp = await lastValueFrom(this.usuarioService.registrarPersona(datosPersona))
      datosPersona.idPersona = resp.datos.persona.idPersona
      datosPersona.nombreTipoDocumento = resp.datos.persona.nombreTipoDocumento
    } catch (error) {
      Swal.fire({ html: error.error.mensaje, icon: 'warning', confirmButtonText: 'Aceptar' })
      return
    }

    Swal.fire({ text: resp.mensaje, icon: 'success', confirmButtonText: 'Aceptar' }).then((res) => {
      this.dialogRegistroPersona.close({
        'ok': true,
        'persona': datosPersona
      })
    })
  }
  pruebaDatos(i){
 
  if (i==1){
    this.nacionalidadName='Boliviano'
    var fechaAux=this.dp.transform( new Date(1981,0,23), 'yyyy-MM-dd' );

    this.registroForm.patchValue({
      tipoDocumento:0,
      numeroDocumento: 5233882,
      complementoVisible:0,
      complemento:null,
      paisNacimiento:25,
      nacionalidad:'BO',
      nombres:'JUAN TITO',
      primerApellido: 'FRANCO',
      segundoApellido: 'VARGAS',
      fechaNacimiento: fechaAux,
      direccion: 'AV GUAYACAN S/N',
      telefono: '72086322',
      correoPersonal: 'jtfrancov@gmail.com',
      tipoPersona:Parametricas.NACIONALIDAD_BOLIVIANA_ID
    })
  }
  if (i==2){
    this.nacionalidadName='Boliviano'
    var fechaAux=this.dp.transform( new Date(1977,9,1), 'yyyy-MM-dd' );

    this.registroForm.patchValue({
      tipoDocumento:0,
      numeroDocumento: 4880588,
      complementoVisible:0,
      complemento:null,
      paisNacimiento:25,
      nacionalidad:'BO',
      nombres:'BORIS WILFREDO',
      primerApellido: 'CHOQUE',
      segundoApellido: 'APAZA',
      fechaNacimiento: fechaAux,
      direccion: 'C. 11 NUM 151 Z. ANTOFAGASTA',
      telefono: '70116761',
      correoPersonal: 'boricles1@hotmail.com',
      tipoPersona:Parametricas.NACIONALIDAD_BOLIVIANA_ID
    })
  }  
  if (i==3){
    this.nacionalidadName='Boliviano'
    var fechaAux=this.dp.transform( new Date(1982,0,8), 'yyyy-MM-dd' );

    this.registroForm.patchValue({
      tipoDocumento:0,
      numeroDocumento: 7378745,
      complementoVisible:0,
      complemento:null,
      paisNacimiento:25,
      nacionalidad:'BO',
      nombres:'EDGAR MARCOS',
      primerApellido: 'RAFAEL',
      segundoApellido: 'ARROYO',
      fechaNacimiento: fechaAux,
      direccion: 'CJ. AYOROA N° 777Z. VILLA PABON',
      telefono: '71785694',
      correoPersonal: 'boricles1@hotmail.com',
      tipoPersona:Parametricas.NACIONALIDAD_BOLIVIANA_ID
    })
  }    
  if (i==4){
    this.nacionalidadName='Boliviano'
    var fechaAux=this.dp.transform( new Date(1991,5,3), 'yyyy-MM-dd' );

    this.registroForm.patchValue({
      tipoDocumento:0,
      numeroDocumento: 8003492,
      complementoVisible:0,
      complemento:null,
      paisNacimiento:25,
      nacionalidad:'BO',
      nombres:'NELSON ALVARO',
      primerApellido: 'MORANTE',
      segundoApellido: 'NINA',
      fechaNacimiento: fechaAux,
      direccion: 'CJ. AYOROA',
      telefono: '71785693',
      correoPersonal: 'nelson.morante.nina.07@gmail.com',
      tipoPersona:Parametricas.NACIONALIDAD_BOLIVIANA_ID
    })
  }    
  this.registroForm.controls.complemento.disable()
  this.registroForm.controls.complemento.clearValidators()   
 

 // this.registrar();
/*     this.registroForm = new FormGroup({
      fechaNacimiento: new FormControl( fechaAux, [ Validators.required ] )
  }) */
/*     this.registroForm = this.formBuilder.group({
      tipoDocumento: [0, Validators.required],
      numeroDocumento: [5233882, Validators.required],
      complementoVisible: [0, Validators.required],
      complemento: [null],
      paisNacimiento: [Parametricas.CODIGO_PAIS_BOLIVIA, Validators.required],
      nacionalidad: [1, Validators.required],
      nombres: ['JUAN TITO', Validators.required],
      primerApellido: ['sdfsdf', null],
      segundoApellido: ['sdfsdf', null],
      fechaNacimiento: [this.fechaNac, Validators.required],
      direccion: ['ddddd', this.validacionesDireccion],
      telefono: ['72086322', this.validacionesTelefono],
      correoPersonal: ['jtfrancov@gmail.com', this.validacionescorreoPersonal],
      tipoPersona: [Parametricas.NACIONALIDAD_BOLIVIANA_ID, null],
    }) */


  }
/* 
  DatosRejap(data) {
    if (this.registroForm) {
    
     this.registroRejapForm.patchValue({
      tipoDocumento:data.tipoDocumento,
      numeroDocumento: data.numeroDocumento,
      complementoVisible:data.complementoVisible,
      complemento:data.complemento,     
      numeroRejap:427653,   
      correoPersonal: data.correoPersonal
    })
    }
  } */
  cerrarDialog(): void {
    this.dialogRegistroPersona.close({ res: false, code: 0 });
  }

  validarDatos(){
    let esValido:boolean = true;
    let mensaje:string = "";

/*     if(usuario.Username == null || usuario.Username == undefined || usuario.Username.trim() == ""){
        mensaje = "Usuario Vacio";
        esValido = false;
        break;
    }

    if(usuario.Email == null || usuario.Email == undefined || usuario.Email.trim() == ""){
        mensaje = "Email vacio";
        esValido = false;
        break;
    } */
    if(!this._validadorService.esEmailValido(this.registroForm.value.correoPersonal)){
        mensaje = "Formato Email Incorrecto";
        esValido = false;
    }
  }
}


