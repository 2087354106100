import { Component, OnInit, Inject, ViewChild, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { UsuarioService } from 'src/app/modules/auth/services/usuario.service'
import { PaisService } from '../../services/parametricas/pais.service';
import { AduanaService } from 'src/app/modules/licencias-previas/services/aduana.service';
import { AutorizacionPreviaImportacionesService } from 'src/app/modules/licencias-previas/services/autorizacion-previa-importaciones.service';
import { MatTable } from '@angular/material/table';
import { lastValueFrom } from 'rxjs';
import { SustanciaModel } from 'src/app/modules/licencias-previas/models/Sustancia.model';
import { SustanciaAPModel } from 'src/app/modules/licencias-previas/models/SustanciaAP.model';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AgregarSustanciasComponent } from 'src/app/modules/licencias-previas/components/bandeja-licencias-previas/agregar-sustancias/agregar-sustancias.component';
import Swal from 'sweetalert2';
import { SustanciaAPService } from 'src/app/modules/licencias-previas/services/sustancia-ap.service';
import { UnidadMedidaModel } from 'src/app/modules/licencias-previas/models/UnidadMedida.model';
import { SwalObject } from '../../utils/swal';
import { ActivatedRoute, Router } from "@angular/router";
import { AgregarEnvasesComponent } from 'src/app/modules/licencias-previas/components/bandeja-licencias-previas/agregar-envases/agregar-envases.component';
import { AgregarEnvasesLoteComponent } from 'src/app/modules/licencias-previas/components/bandeja-licencias-previas/agregar-envases-lote/agregar-envases-lote.component';
import { Dias } from 'src/app/modules/licencias-previas/models/Dias.model'
import { Parametricas } from "src/app/core/enums/parametricas.enums"
import { TipoDocumentoGeneralService } from 'src/app/core/services/parametricas/tipoDocumentoGeneral.service';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import * as moment from 'moment';
import { DocumentosAdicionalesService } from '../../services/documentos-adicionales.service';
import { VisorPdfDialogoComponent } from 'src/app/core/components/visor-pdf-dialogo/visor-pdf-dialogo.component';
import { SeleccionarTipoSustancia } from 'src/app/modules/licencias-previas/components/bandeja-licencias-previas/seleccionar-tipo-sustancia/seleccionar-tipo-sustancia.component';

@Component({
  selector: 'app-primera-comercializacion',
  templateUrl: './primera-comercializacion.component.html',
  styleUrls: ['./primera-comercializacion.component.scss']
})
export class PrimeraComercializacionComponent implements OnInit {
  /**
 * vaiable que almacena datos de documentos
 */
  datosDocumentos = null
  /**
 * variable que almacena los requisitos 
 */
  docRequisitos: any[] = []
  /**
 * variable que almacena documentos que almacena los requisitos que corresponden
 */
  docRequisitosCorresponde: any[] = []
  /**
 * variable que almacena documentos segun la comercialización
 */
  docRequisitos13: any[] = []
  /**
 * variable instanciada mensaje de parametricas
 */
  public MesSustancia = Parametricas.MesSustancia
  /**
  * variable instanciada mensaje de parametricas
  */
  public MesDocumento = Parametricas.MesDocumento
  /**
 * variable que continene declaracion de formularios
 */
  public declaracion = Parametricas.declaracion
  /**
    * variable swal para los mensajes
    */
  public swal = new SwalObject()
  /**
  * variable comercializacionForm para el formulario de guardado
  */
  public comercializacionForm: FormGroup
  /**
  * variable formulario de las sustancias
  */
  public sustanciasForm: FormGroup
  /**
  * variable de paises
  */
  public paises: any[] = []
  /**
  * variable de las aduanas formulario comercializacion
  */
  public aduanas: any[] = []
  /**
  * variable que continene las sustancias model
  */
  public sustancias: SustanciaModel[] = []
  /**
  * variable que continene sustancias ap model
  */
  public sustanciasAP: SustanciaAPModel[] = []
  /**
  * variable instanciada formDocumentos
  */
  public formDocumentos: FormGroup
  /**
* variable instanciada formDocumentosCorresponde
*/
  public formDocumentosCorresponde: FormGroup
  /**
   * variable que almacena nombreArchivo
   */
  nombreArchivo: string;
  /**
  * variable que continene las columnas de la tabla
  */
  public columnasSustancias: string[] = [
    "nro", "acciones",
    "nombre_sustancia_primaria",
    "tipo_sustancia",
    "abreviacion"
  ]
  /**
 * variable array de envases
 */
  ArrayEnvase: [];
  /**
 * variable datosMod
 */
  datosMod = null
  /**
 * variable datosSustancia
 */
  datosSustancia = null
  /**
  * variable datosSusReg
  */
  datosSusReg = null
  /**
  * variable id_tramite
  */
  id_tramite? = null
  /**
  * variable capacidad_cant_envase1
  */
  capacidad_cant_envase1: number = 0
  /**
 * variable capacidad_cant_envase2
 */
  capacidad_cant_envase2: number = 0
  /**
* variable de autorizacion previa
*/
  IdAutorizacionPrevia: any
  /**
* variable id_ap
*/
  id_autorizacion_previa = null
  /**
* variable boolean activar
*/
  activar: boolean = false
  /**
 * variable archivosAdjuntados
 */
  archivosAdjuntados: boolean[];
  /**
 * variable archivosAdjuntadosCorresponde
 */
  archivosAdjuntadosCorresponde: boolean[]
  /**
 * variable ultimaRA
 */
  ultimaRA: any
  /**
 * variable raDocumento
 */
  raDocumento: any
  /**
  * variable fecha_ini
  */
  fecha_ini: any
  /**
* variable fecha_fi
*/
  fecha_fi: any
  /**
* variable activarRA
*/
  activarRA: boolean = false
  /**
 * variable conteo
 */
  conteo: number = 0
  /**
  * variable conteoDocumentosTotal
  */
  conteoDocumentosTotal: number = 0
  /**
* variable montop
*/
  // montop: number = 0
  /**
* variable monto
*/
  monto: number = 80
  /**
  * constructor del componente comercializacion
  */
  habilitado: boolean = true

  idTramite: number = 0
  subtipoTramite: number = 0
  idRegistro: number = 0
  razonSocial: string = ''
  @Output() formComercializacionOuput = new EventEmitter<any>();
  constructor(

    private documentosAdicionalesService: DocumentosAdicionalesService,
    /**
* servicio de usuario
*/
    public _usuarioService: UsuarioService,
    /**
* servicio de tipoDocumentoGeneralService
*/
    private tipoDocumentoGeneralService: TipoDocumentoGeneralService,
    /**
* Constructor que crea los servicios de solicitude AP
*/
    private autorizacionpreviaimportacionesService: AutorizacionPreviaImportacionesService,

    /**
* routes router
*/
    private router: Router,
    /**
* ruta activa
*/
    private rutaActiva: ActivatedRoute,
    /**
* Servicio que comunica el componente con datos pais
*/
    private paisService: PaisService,
    /**
  * Servicio que comunica el componente con datos aduana
  */
    private aduanaService: AduanaService,
    /**
* formularioformBuilder
*/
    private formBuilder: FormBuilder,
    /**
* varible para los dialog
*/
    public dialog: MatDialog,

    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PrimeraComercializacionComponent>
  ) {
    /**
   * variable para archivosAdjuntados
   */
    this.archivosAdjuntados = [];
    /**
   * variable para archivosAdjuntadosCorresponde
   */
    this.archivosAdjuntadosCorresponde = [];
    /**
  * fomrmulario comercializacionForm
  */
    this.comercializacionForm = this.formBuilder.group({
      notas_adicionales: ['', [Validators.pattern(/^(?!\s)(?!.*\s$)(?!.*').*$/), Validators.maxLength(1000)]],
    });
    /**
     * Inicializamos Form de sustancias
     */
    this.sustanciasForm = this.formBuilder.group({
      sustanciasArray: this.formBuilder.array([]),

    })
    /**
    * formulario documentosForm para la creacion de nuevo comercializacion
    */
    this.formDocumentos = this.formBuilder.group({
      documentosArray: this.formBuilder.array([]),
    });
    /**
    * formulario formDocumentosCorresponde para la creacion de nuevo comercializacion
    */
    this.formDocumentosCorresponde = this.formBuilder.group({
      documentosCorrespondeArray: this.formBuilder.array([]),
    });
  }
  /**
  * formulario documentosForm para la creacion de nuevo comercializacion
  */
  formsAreValid() {
    return this.comercializacionForm.valid && this.sustanciasArray.valid && this.documentos.valid && this.sustanciasArray.length > 0;
  }
  /**
* validacion de notas_adicionales
*/
  validateNotas(event: any) {
    const inputValue = event.target.value;
    const trimmedValue = inputValue.trim();

    if (inputValue !== trimmedValue) {
      // El valor tiene espacios en blanco al inicio o al final
      this.comercializacionForm.controls['notas_adicionales'].setErrors({ 'spaces': true });
    } else {
      this.comercializacionForm.controls['notas_adicionales'].setErrors(null);
    }
  }
  /**
    * Funcion que inicia las acciones del componente
    */
  ngOnInit(): void {
    this.dialogRef.updateSize('70%', '65%')
    this.dialogRef.disableClose = true

    this.IdAutorizacionPrevia = this.rutaActiva.snapshot.params.datos
    this.idTramite = this.data.idTramite
    this.subtipoTramite = this.data.subtipoTramite
    this.idRegistro = this.data.idRegistro
    this.razonSocial = this.data.razonSocial
    console.log(this.idTramite, this.subtipoTramite, '/-*/*-/*-/*-/*-/*-/*-/-*/*-*-/-*/');


    //////////recibio el id
    if (this.IdAutorizacionPrevia != null && this.IdAutorizacionPrevia != undefined && this.IdAutorizacionPrevia != 0) {
      this.buscarDatos()
    }
    if (this.id_autorizacion_previa == null || this.id_autorizacion_previa == undefined || this.id_autorizacion_previa == 0 || this.id_autorizacion_previa == '') {
      this.activar = false
    }

  }
  /**
  * funcion que permite y valida ver el archivo
  */
  verArchivo(documentoFormGroup: FormGroup) {

    return documentoFormGroup.value.base64 != null && documentoFormGroup.value.id_tipo_documento_general != 12
      ? true
      : false;
  }
  /**
  * funcion que permite y valida ver el archivo si corresponde
  */
  verArchivoCorresponde(documentoCorrespondeFormGroup: FormGroup) {

    return documentoCorrespondeFormGroup.value.base64 != null
      ? true
      : false;
  }
  /**
  * funcion que permite ver la última resolucion administriva
  */
  verUltimaRA(documentoFormGroup: FormGroup) {
    // return documentoFormGroup.value.id_tipo_documento_general == 38 //////DECLARACIÓN DE MERCANCÍA DE IMPORTACIÓN
    return documentoFormGroup.value.id_tipo_documento_general == 12  //////DECLARACIÓN DE MERCANCÍA DE IMPORTACIÓN
      ? true
      : false;
  }
  /**
  * funcion que permite ver archivos sin resolucion administriva
  */
  verSinRA(documentoFormGroup: FormGroup) {
    return documentoFormGroup.value.id_tipo_documento_general == 12 /////DECLARACIÓN DE MERCANCÍA DE IMPORTACIÓN
      ? false
      : true;
  }
  /**
 * Funcion conecta con el servicio pais
 */
  onSelectionChangedOrigen({ value }) {

    if (value == 0) {
      this.comercializacionForm.get('origen').enable();
    } else {
      this.comercializacionForm.get('origen').disable();
    }

  }
  /**
  * funcion que permite adjuntar archivos
  */
  async verDocumentoAdj(documentoFormGroup: FormGroup) {

    this.dialog.open(VisorPdfDialogoComponent, {
      disableClose: true,
      data: { archivoBase64: documentoFormGroup.value.base64, disableCancelButton: true }
    })
  }
  /**
 * funcion que permite adjuntar archivos si corresponde
 */
  async verDocumentoCorrespondeAdj(documentoCorrespondeFormGroup: FormGroup) {

    this.dialog.open(VisorPdfDialogoComponent, {
      disableClose: true,
      data: { archivoBase64: documentoCorrespondeFormGroup.value.base64, disableCancelButton: true }
    })
  }
  /**
  * funcion que permite ver el documento
  */
  async verDocumento(documentoFormGroup: FormGroup) {


    // Si aun no se cargo ningun archivo (Significa que esta en null)
    /* if (!documentoFormGroup.value.base64)
      return */
    Swal.fire("", "Espere por favor", "info");
    Swal.fire({
      title: "Espere por favor...",
      allowOutsideClick: false,
    });
    Swal.showLoading()
    let IdAutorizacionPrevia = documentoFormGroup.value.id_autorizacion_previa

    if (documentoFormGroup.value.adjuntar_archivo_existente)
      IdAutorizacionPrevia = documentoFormGroup.value.IdAutorizacionPrevia

    // Si ya se cargo el archivo pero aun no se trajo el archivo del backend (Modo: edicion)
    //  if (documentoFormGroup.value.base64 == 'data:application/pdf;base64,') {

    try {
      var documentoResp = await lastValueFrom(this.autorizacionpreviaimportacionesService.obtenerDocumentoRA(IdAutorizacionPrevia))
    } catch (error) {

      Swal.fire({ text: error.error.mensaje, icon: 'warning', confirmButtonText: 'Aceptar' })
      return
    }
    Swal.close();
    documentoFormGroup.patchValue({
      base64: documentoResp.datos.documento.base64
    })
    //  }

    this.dialog.open(VisorPdfDialogoComponent, {
      disableClose: true,
      data: { archivoBase64: documentoFormGroup.value.base64, disableCancelButton: true }
    })
  }
  /**
   * funcion que permite adjuntar el archivo
   */
  async adjuntarArchivo(event: any, documentoFormGroup: FormGroup, index: number) {


    if (event.target.files.length > 0) {
      this.nombreArchivo == event.target.files[0].name
      const size = event.target.files[0].size
      const fileType = event.target.files[0].type
      const fileName = event.target.files[0].name

      if (fileType !== 'application/pdf') {
        Swal.fire({ text: '¡Solo es permitido archivos en formato .pdf!', icon: 'warning', confirmButtonText: 'Aceptar' })
        return
      }
      if (size > Parametricas.maxFileSize) {
        Swal.fire({ text: '¡El tamaño del archivo no puede exceder de 1Mb!', icon: 'warning', confirmButtonText: 'Aceptar' })
        return
      }
      const reader = new FileReader()
      reader.readAsDataURL(event.target.files[0])
      reader.onload = async (ev: any) => {
        // this.archivoCargado = ev.target.result;
        let docBase64 = ev.target.result
        //this.archivoURL = this.sanitizer.bypassSecurityTrustResourceUrl(docBase64);
        //Quitamos la cabecera del tipo de archivo data:application/pdf;base64,
        docBase64 = docBase64.substring(28)
        let archivoBinario = window.atob(docBase64)
        //// Verificamos si el archivo esta dañado
        if (archivoBinario.lastIndexOf("%PDF-") !== 0 || archivoBinario.lastIndexOf("%%EOF") === -1) {
          Swal.fire({ text: 'El archivo esta dañado, por favor revise y vuelva a intentarlo', icon: 'warning', confirmButtonText: 'Aceptar' })
          return
        }
        var resp = await Swal.fire({
          title: '¿Está seguro de adjuntar documento? ' + fileName + '?',
          icon: "question",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Aceptar",
          showCancelButton: true,
          cancelButtonText: 'Cancelar',
          reverseButtons: true
        });
        if (!resp.isConfirmed) return;
        documentoFormGroup.patchValue({
          archivo: fileName,
          base64: docBase64,
          nuevo_archivo: true,
          adjuntar_archivo_existente: false,

        })
        this.archivosAdjuntados[index] = true;

        Swal.fire({ text: 'Documento guardado correctamente', icon: 'success', confirmButtonText: 'Aceptar' })
      }
    }
  }
  /**
   * funcion que permite adjuntar el archivo si corresponde
   */
  async adjuntarArchivoCorresponde(event: any, documentoCorrespondeFormGroup: FormGroup, index: number) {


    if (event.target.files.length > 0) {
      this.nombreArchivo == event.target.files[0].name
      const size = event.target.files[0].size
      const fileType = event.target.files[0].type
      const fileName = event.target.files[0].name

      if (fileType !== 'application/pdf') {
        Swal.fire({ text: '¡Solo es permitido archivos en formato .pdf!', icon: 'warning', confirmButtonText: 'Aceptar' })
        return
      }
      if (size > Parametricas.maxFileSize) {
        Swal.fire({ text: '¡El tamaño del archivo no puede exceder de 1Mb!', icon: 'warning', confirmButtonText: 'Aceptar' })
        return
      }
      const reader = new FileReader()
      reader.readAsDataURL(event.target.files[0])
      reader.onload = async (ev: any) => {
        // this.archivoCargado = ev.target.result;
        let docBase64 = ev.target.result
        //this.archivoURL = this.sanitizer.bypassSecurityTrustResourceUrl(docBase64);
        //Quitamos la cabecera del tipo de archivo data:application/pdf;base64,
        docBase64 = docBase64.substring(28)
        let archivoBinario = window.atob(docBase64)
        //// Verificamos si el archivo esta dañado
        if (archivoBinario.lastIndexOf("%PDF-") !== 0 || archivoBinario.lastIndexOf("%%EOF") === -1) {
          Swal.fire({ text: 'El archivo esta dañado, por favor revise y vuelva a intentarlo', icon: 'warning', confirmButtonText: 'Aceptar' })
          return
        }
        var resp = await Swal.fire({
          title: '¿Está seguro de adjuntar documento? ' + fileName + '?',
          icon: "question",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Aceptar",
          showCancelButton: true,
          cancelButtonText: 'Cancelar',
          reverseButtons: true
        });
        if (!resp.isConfirmed) return;
        documentoCorrespondeFormGroup.patchValue({
          archivo: fileName,
          base64: docBase64,
          nuevo_archivo: true,
          adjuntar_archivo_existente: false,

        })
        this.archivosAdjuntadosCorresponde[index] = true;

        Swal.fire({ text: 'Documento guardado correctamente', icon: 'success', confirmButtonText: 'Aceptar' })
      }
    }
  }
  /**
   * funcion que permite agregar envases lote
   */
  agregarEnvasesLote(sustanciaFormGroup: FormGroup): void {
    const masaUnidadMedida = sustanciaFormGroup.get('masa_unidad_medida').value;
    const envases = sustanciaFormGroup.value.envases
    const dialogRef = this.dialog.open(AgregarEnvasesLoteComponent, {
      disableClose: true,
      data: {
        envases: envases,
        masa_unidad_medida: masaUnidadMedida
      },
      width: '60%',
      height: '80%',


    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result)
        return;

      this.ArrayEnvase = result.envaseSustanciaForm.envases;

      var tipo_envase = "";
      var capacidad_cant_envase = 0;
      var envasesSeleccionados = [];

      this.ArrayEnvase.forEach((elemEnvase: any) => {
        capacidad_cant_envase += (elemEnvase.cantidad_envase) * (elemEnvase.envase.capacidad) * (elemEnvase.envase.factor_conversion);
        if (tipo_envase.length == 0) {
          tipo_envase = elemEnvase.cantidad_envase.toLocaleString("es-BO") + ' ' + elemEnvase.envase.nombre_envase + ' de ' + elemEnvase.envase.capacidad + ' ' + elemEnvase.envase.abreviacion;
        } else {
          tipo_envase += ', ' + elemEnvase.cantidad_envase.toLocaleString("es-BO") + ' ' + elemEnvase.envase.nombre_envase + ' de ' + elemEnvase.envase.capacidad + ' ' + elemEnvase.envase.abreviacion;
        }

        const envase = {
          id_envase: elemEnvase.envase.id_envase,
          cantidad_envase: elemEnvase.cantidad_envase
        };

        envasesSeleccionados.push(envase);
      });

      sustanciaFormGroup.patchValue({
        tipo_envase: tipo_envase,
        cantidad_envases: capacidad_cant_envase,
        //cantidad_envases: Number.parseInt(capacidad_cant_envase1+capacidad_cant_envase2+capacidad_cant_envase3+capacidad_cant_envase4+capacidad_cant_envase5+capacidad_cant_envase6+capacidad_cant_envase7+capacidad_cant_envase8).toFixed(5),

        envases: envasesSeleccionados
      });

      if (!!!result) {
        return
      }
    });
  }
  /**
* funcion para cadenas documentos de nuevo 
*/
  get documentos() {
    return this.formDocumentos.controls['documentosArray'] as FormArray;
  }
  /**
* funcion para cadenas documentos de nuevo 
*/
  get documentosCorresponde() {
    return this.formDocumentosCorresponde.controls['documentosCorrespondeArray'] as FormArray;
  }
  /**
 * array sustancias
 */
  get sustanciasArray(): FormArray {
    return this.sustanciasForm.controls['sustanciasArray'] as FormArray
  }
  /**
  * Funcion que permite eliminar las sustancias
  */
  async eliminaSustancia(index, sustancia): Promise<void> {
    var respuesta = await Swal.fire({
      title: "¿Está seguro eliminar el registro?",
      text: "¡No podrás revertir esto! ",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#aaa",
      cancelButtonColor: "#aaa",
      confirmButtonText: "Si, eliminar",
      cancelButtonText: "No, cancelar",
      showCloseButton: true
    })
    if (!respuesta.isConfirmed) return
    Swal.fire("", "Espere por favor", "warning");
    Swal.fire({
      title: "Espere por favor...",
      html: "Eliminando registro",
      allowOutsideClick: false,
    });
    Swal.showLoading();
    this.sustanciasArray.removeAt(index)
    // this.monto = this.montop + this.sustanciasArray.length;
    /*     for (let index = this.documentos.length - 1; index >= 0; index--) {
          const element = this.documentos.value[index];
          if (element.id_sustancia == sustancia) {
            this.documentos.removeAt(index);
            this.conteoDocumentosTotal = 0
    
          }
        } */
    Swal.close();
    Swal.fire(
      "¡Eliminado!",
      "El registro ha sido eliminado correctamente",
      "success"
    );
  }
  /**
    * funcion que permite elimar documentos si corresponde
    */
  async eliminarDocumento(index): Promise<void> {
    var respuesta = await Swal.fire({
      title: "¿Está seguro eliminar el registro?",
      text: "¡No podrás revertir esto! ",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#aaa",
      cancelButtonColor: "#aaa",
      confirmButtonText: "Si, eliminar",
      cancelButtonText: "No, cancelar",
      showCloseButton: true
    })
    if (!respuesta.isConfirmed) return
    Swal.fire("", "Espere por favor", "warning");
    Swal.fire({
      title: "Espere por favor...",
      html: "Eliminando registro",
      allowOutsideClick: false,
    });
    Swal.showLoading();
    this.documentosCorresponde.removeAt(index)
    this.conteo = 0
    Swal.close();
    Swal.fire(
      "¡Eliminado!",
      "El registro ha sido eliminado correctamente",
      "success"
    );
  }
  /**
 * funcion que permite la busqueda de datos
 */
  async buscarDatos() {
    const params = {
      dataEdit: this.IdAutorizacionPrevia
    }
    Swal.fire("", "Espere por favor", "info");
    Swal.fire({
      title: "Espere por favor...",
      allowOutsideClick: false,
    });
    Swal.showLoading()
    try {
      var respuesta = await lastValueFrom(this.autorizacionpreviaimportacionesService.buscarDatos(params))
    } catch (error) {
      Swal.fire('', error.error.mensaje, 'warning')
      this.router.navigateByUrl('/licencias-previas/bandeja-licencias-previas/lista')
      return;
    }


    Swal.close();

    if (!respuesta.datos.datosEditBD[0] || respuesta.datos.datosEditBD[0] === '' || !respuesta.datos.sustancias || respuesta.datos.sustancias === '' || !respuesta.datos.datosBD || respuesta.datos.datosBD === '') {
      this.router.navigateByUrl('/licencias-previas/bandeja-licencias-previas/lista')
    }
    if (respuesta.datos.datosEditBD[0].id_estado != 405) {
      this.router.navigateByUrl('/licencias-previas/bandeja-licencias-previas/lista')
      return
    }
    this.datosMod = respuesta.datos.datosEditBD[0]
    this.datosSustancia = respuesta.datos.sustancias
    this.datosDocumentos = respuesta.datos.documentos
    this.datosSusReg = respuesta.datos.datosBD
    this.id_tramite = this.datosMod?.id_tramite
    this.activar = true
    this.comercializacionForm.controls['notas_adicionales'].setValue(this.datosMod?.notas_adicionales);
    ////////buscar sustancia del la autorizacion
    var capacidad_cant_envase = 0;
    this.datosSustancia.forEach(sustancia => {
      const envases11 = sustancia?.envases
      var cadenaenvases = ''
      envases11.forEach(env => {
        const envases1 = env
        if (!sustancia.envases) {
          this.capacidad_cant_envase2 = 0;
        }
        capacidad_cant_envase += (envases1.cantidad_envase) * (envases1.capacidad) * (envases1.factor_conversion)
        if (cadenaenvases.length == 0) {
          cadenaenvases = envases1.cantidad_envase.toLocaleString("es-BO") + ' ' + envases1.nombre_envase + ' de ' + envases1.capacidad + '' + envases1.abreviacion

        } else {
          cadenaenvases += ', ' + envases1.cantidad_envase.toLocaleString("es-BO") + ' ' + envases1.nombre_envase + ' de ' + envases1.capacidad + '' + envases1.abreviacion
        }
        this.capacidad_cant_envase2 = this.capacidad_cant_envase1 + (capacidad_cant_envase)

      })
      const sustanciaFormGroup = this.formBuilder.group({
        id_sustancia: [sustancia.id_sustancia, null],
        sustancia_primaria: [sustancia.nombre, null],
        sustancia_nombre: [sustancia.sinonimo, null],
        tipo_sustancia: [sustancia.primaria, null],
        unidad_medida: [sustancia.nombre_um, null],
        id_unidad_medida: [sustancia.id_unidad_medida, null],
        cantidad_solicitada: [sustancia.cantidad_solicitada],
        cantidad_registro: [this.datosSusReg?.cantidad, null],
        masa_unidad_medida: [sustancia.masa_unidad_medida, null],
        porcentaje: [sustancia.porcentaje, null],
        saldo_kardex: [sustancia.saldo_kardex],
        cantidad_envases: [this.capacidad_cant_envase2, null],
        tipo_envase: [cadenaenvases, null],
        utilizacion: [sustancia.utilizacion],
        envases: [sustancia.envases, []]

      });
      this.sustanciasArray.push(sustanciaFormGroup);

    })
    this.datosDocumentos.forEach(doc => {
      /*      const FORMAT = "YYYY-MM-DD";
           const fechaInicioDOC = moment(doc.fecha_emision, FORMAT); // Analizar la fecha en formato específico
           if (fechaInicioDOC.isValid()) {
             this.fechaE = fechaInicioDOC.utc().format(FORMAT); // Formatear la fecha en UTC
           } 
           const fechaFinDOC = moment(doc.fecha_fin, FORMAT); // Analizar la fecha en formato específico
           if (fechaFinDOC.isValid()) {
             this.fechaF= fechaFinDOC.utc().format(FORMAT); // Formatear la fecha en UTC
           } */

      const documentoFormGroup = this.formBuilder.group({
        id_tipo_documento_general: [doc.id_tipo_documento_general, null],
        nombre_documento: [doc.descripcion, null],
        descripcion: [doc.descripcion, null],
        identificador: [doc.identificador, [Validators.required, Validators.pattern(/^(?!\s)(?!.*\s$)(?!.*').*$/), Validators.maxLength(30)]],
        fecha_emision: [doc.fecha_emision, Validators.required],
        fecha_fin: [doc.fecha_fin, null],
        archivo: [doc.descripcion, null],
        base64: [doc.base64 ? 'data:application/pdf;base64,' : null, Validators.required],
        nuevo_archivo: [false, null],
        adjuntar_archivo_existente: [false, null],
      })
      documentoFormGroup.patchValue({
        nombre_documento: doc.descripcion,
        descripcion: doc.descripcion,
        identificador: doc.identificador,
        fecha_emision: doc.fecha_emision,
        fecha_fin: doc.fecha_fin,
        base64: doc.base64,
        nuevo_archivo: true,
        adjuntar_archivo_existente: false
      });
      this.documentos.push(documentoFormGroup);
    })
    return
  }
  /**
  * Funcion agregar sustancias
  */
  agregarSustancias(): void {
    const dialogRef = this.dialog.open(AgregarSustanciasComponent, {
      disableClose: true,
      data: {
        sustanciasArray: this.sustanciasArray.value,
        sustanciasAgregadas: [],
        actividad: 5 ///comercializacion
      }
    })
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {

        result.forEach(async sustancia => {
          const sustanciaFormGroup = this.formBuilder.group({
            id_sustancia: [sustancia.id_sustancia, null],
            tipo: [sustancia.tipo, null],
            codigo: [sustancia.codigo, null],
            sustancia_primaria: [sustancia.nombre_sustancia_primaria, null],
            sustancia_nombre: [sustancia.nombre_sustancia, null],
            tipo_sustancia: [sustancia.primaria, null],
            unidad_medida: [sustancia.nombre_unidad_medida, null],
            id_unidad_medida: [sustancia.id_unidad_medida, null],
            masa_unidad_medida: [sustancia.masa_unidad_medida, null],
            porcentaje: [sustancia.porcentaje, null],
            cantidad_envases: [0, null],
            tipo_envase: ['', null],
            envases: [[], null]
          });
          this.sustanciasArray.push(sustanciaFormGroup);
          //this.obtenerUltimaRAxSustancia(sustanciaFormGroup.value)
          this.conteoDocumentosTotal++;
          if (this.conteoDocumentosTotal === 1) {
            this.getDocumentosGeneral()

          }
          //       if (sustanciaFormGroup.value.tipo == 1) {////HIDROCARBUROS         
          // this.conteo++;
          // if (this.conteo === 1) {
          //   this.getDocumentosCorresponde()
          // }
          //   }

        })
        //monto por sustancia
        // this.monto = this.montop + this.sustanciasArray.length;
        if (result !== undefined) {
          this.sustanciasAP = result;
        }

      }

    })
  }

  agregarTipoSustancias(): void {
    var params;
    Swal.fire("", "Espere por favor", "info");
    Swal.fire({
      title: "Espere por favor...",
      allowOutsideClick: false,
    });
    Swal.showLoading()

    const dialogRef = this.dialog.open(SeleccionarTipoSustancia, {
      disableClose: true,
      data: {
        idTramite: this.idTramite,
        subtipoTramite: this.subtipoTramite
      }
    });

    Swal.close();
    dialogRef.afterClosed().subscribe(async (resultado) => {
      if (resultado == undefined || resultado == null || resultado.res == false) {
        this.habilitado = true
        return;
      } else {
        params = {
          id_tramite: this.idTramite,
          tipo: resultado
        }
        var respuesta = await lastValueFrom(this.autorizacionpreviaimportacionesService.obtenerTipoHidrocarburo(params));
        let result = respuesta.datos
        if (result) {
          for (let i = 0; i < result.sustancia.length; i++) {
            const sustanciaFormGroup = this.formBuilder.group({
              id_sustancia: [result.sustancia[i].id_sustancia, null],
              tipo: [result.sustancia[i].tipo, null],
              codigo: [result.sustancia[i].codigo, null],
              sustancia_primaria: [result.sustancia[i].nombre_sustancia_primaria, null],
              sustancia_nombre: [result.sustancia[i].nombre_sustancia, null],
              tipo_sustancia: [result.sustancia[i].primaria, null],
              unidad_medida: [result.sustancia[i].nombre_unidad_medida, null],
              id_unidad_medida: [result.sustancia[i].id_unidad_medida, null],
              masa_unidad_medida: [result.sustancia[i].masa_unidad_medida, null],
              porcentaje: [result.sustancia[i].porcentaje, null],
              cantidad_envases: [0, null],
              tipo_envase: ['', null],
              envases: [[], null]
            });

            this.sustanciasArray.push(sustanciaFormGroup);
            //this.obtenerUltimaRAxSustancia(sustanciaFormGroup.value)
            this.conteoDocumentosTotal++;
            if (this.conteoDocumentosTotal === 1) {
              this.getDocumentosGeneral()

            }
            //       if (sustanciaFormGroup.value.tipo == 1) {////HIDROCARBUROS         
            // this.conteo++;
            // if (this.conteo === 1) {
            //   this.getDocumentosCorresponde()
            // }
            //   }

          }
          //monto por sustancia
          // this.monto = this.montop + this.sustanciasArray.length;
          if (respuesta !== undefined) {
            this.sustanciasAP = respuesta;
          }

        }
      }
    });

    this.habilitado = false
  }

  verBoton(): boolean {
    return this.habilitado ? false : true;
  }
  /**
  * funcion que agrega documentos obligatorios
  */
  agregarDocumentoObligatorio(doc: any = null, id_sustancia: any = null, id_autorizacion_previa: any = null) {
    console.log(doc, 'datos del documento para el tramite de comerializacion');
    if (doc.id_tipo_documento_general != 48) {
      const documentoFormGroup = this.formBuilder.group({
        id_tipo_documento_general: [doc.id_tipo_documento_general, null],
        nombre_documento: [doc.nombre_documento, null],
        descripcion: [doc.nombre_documento, null],
        identificador: ['', [Validators.required, Validators.required, Validators.pattern(/^(?!\s)(?!.*\s$)(?!.*').*$/), Validators.maxLength(30)]],
        fecha_emision: ['', Validators.required],
        fecha_fin: [null],
        archivo: ['', null],
        base64: [ 'data:application/pdf;base64,', Validators.required],
        nuevo_archivo: [false, null],
        adjuntar_archivo_existente: [false, null],
        id_sustancia: [id_sustancia, null],
        id_autorizacion_previa: [id_autorizacion_previa, null]
      })
      this.documentos.push(documentoFormGroup)
    }
  }



  /**
  * funcion que agrega documentos obligatorios si corresponde
  */
  agregarDocumentoCorresponde(doc: any = null, id_sustancia: any = null, id_autorizacion_previa: any = null) {
    const documentoCorrespondeFormGroup = this.formBuilder.group({
      id_tipo_documento_general: [doc.id_tipo_documento_general, null],
      nombre_documento: [doc.nombre_documento, null],
      descripcion: [doc.nombre_documento, null],
      identificador: ['', [Validators.required, Validators.pattern(/^(?!\s)(?!.*\s$)(?!.*').*$/), Validators.maxLength(50)]],
      fecha_emision: ['', Validators.required],
      fecha_fin: [null],
      archivo: ['', null],
      base64: [ 'data:application/pdf;base64,', Validators.required],
      nuevo_archivo: [false, null],
      adjuntar_archivo_existente: [false, null],
      id_sustancia: [id_sustancia, null],
      id_autorizacion_previa: [id_autorizacion_previa, null]
    })
    this.documentosCorresponde.push(documentoCorrespondeFormGroup)

  }
  /**
  * funcion que agrega documentos general
  */
  async getDocumentosGeneral() {
    const paramsTramite = {
      tipoTramite: 50 ///comercializacion
    }
    try {
      var resp = await lastValueFrom(this.tipoDocumentoGeneralService.obtenerTodos(paramsTramite))
    } catch (error) {
      Swal.fire("", error.error.mensaje, "error");
      return;
    }
    this.docRequisitos = resp.datos.documentos
    this.docRequisitos.forEach(doc => {
      this.agregarDocumentoObligatorio(doc)
    })

  }
  /**
  * funcion que agrega documentos obligatorios si corresponde
  */
  async getDocumentosCorresponde() {
    const paramsTramite = {
      tipoTramite: 51 ///comercializacion cuando corresponda
    }
    try {
      var resp = await lastValueFrom(this.tipoDocumentoGeneralService.obtenerTodos(paramsTramite))
    } catch (error) {
      Swal.fire("", error.error.mensaje, "error");
      return;
    }

    this.docRequisitosCorresponde = resp.datos.documentos
    this.docRequisitosCorresponde.forEach(doc => {
      this.agregarDocumentoCorresponde(doc)
    })
  }
  /**
* funcion para buscar los datos por las sustancias y agregar sus documentos si existe una resolucion administrativa
*/
  async obtenerUltimaRAxSustancia(data) {
    const paramsSus = {
      id_sustancia: data.id_sustancia,
    }  //para obtener la ultima resolucion por sustancia      
    try {
      var respuesta = await lastValueFrom(this.autorizacionpreviaimportacionesService.obtenerUltimaRAxSustancia(paramsSus))
      this.ultimaRA = respuesta.datos?.datosSUS[0]
      this.raDocumento = respuesta.datos?.documento

    } catch (error) {
      Swal.fire("", error.error.mensaje, "error");
      return;
    }
    if (respuesta.datos.datosSUS.length > 0) {

      const paramsTramite = {
        tipoTramite: 11 ///DECLARACIÓN DE MERCANCÍA DE IMPORTACIÓN - DIM POR SUSTANCIA
      }
      try {
        var resp = await lastValueFrom(this.tipoDocumentoGeneralService.obtenerTodos(paramsTramite))

        this.docRequisitos = resp.datos.documentos

        this.docRequisitos.forEach(doc => {
          const FORMAT = "YYYY-MM-DD";
          const fechaInicio = moment(this.ultimaRA.fecha_inicio, FORMAT); // Analizar la fecha en formato específico
          if (fechaInicio.isValid()) {
            this.fecha_ini = fechaInicio.utc().format(FORMAT); // Formatear la fecha en UTC
          }
          const fechaFin = moment(this.ultimaRA.fecha_fin, FORMAT); // Analizar la fecha en formato específico
          if (fechaFin.isValid()) {
            this.fecha_fi = fechaFin.utc().format(FORMAT); // Formatear la fecha en UTC
          }

          const documentoFormGroup = this.formBuilder.group({

            id_tipo_documento_general: [doc.id_tipo_documento_general, null],
            nombre_documento: [doc.nombre_documento + ' ' + '(' + this.ultimaRA.nombre + ')', null],
            descripcion: [doc.nombre_documento + ' ' + '(' + this.ultimaRA.nombre + ')', null],
            identificador: [this.ultimaRA.numero_ra, Validators.required],
            fecha_emision: [this.fecha_ini, Validators.required],
            fecha_fin: [this.fecha_fi, null],
            archivo: [doc.nombre_documento + ' ' + '(' + this.ultimaRA.nombre + ')', null],
            base64: [this.raDocumento.base64 ? 'data:application/pdf;base64,' : null, Validators.required],
            nuevo_archivo: [true, null],
            adjuntar_archivo_existente: [false, null],
            id_sustancia: [data.id_sustancia, null],
            id_autorizacion_previa: [this.ultimaRA.id_autorizacion_previa, null]
          })

          const identificadorControl = documentoFormGroup.get('identificador');

          if (identificadorControl.value) {
            identificadorControl.disable();
          } else {
            identificadorControl.enable();
          }
          const fechaInicioControl = documentoFormGroup.get('fecha_emision');

          if (fechaInicioControl.value) {
            fechaInicioControl.disable();
          } else {
            fechaInicioControl.enable();
          }
          const FechaFinControl = documentoFormGroup.get('fecha_fin');

          if (FechaFinControl.value) {
            FechaFinControl.disable();
          } else {
            FechaFinControl.enable();
          }

          documentoFormGroup.patchValue({
            nombre_documento: doc.nombre_documento + ' ' + '(' + this.ultimaRA.nombre + ')',
            descripcion: doc.nombre_documento + ' ' + '(' + this.ultimaRA.nombre + ')',
            identificador: this.ultimaRA.numero_ra,
            fecha_emision: this.fecha_ini,
            fecha_fin: this.fecha_fi,
            base64: this.raDocumento.base64,
            nuevo_archivo: true,
            adjuntar_archivo_existente: false
          });
          this.documentos.push(documentoFormGroup)

        })

      } catch (error) {
        Swal.fire("", error.error.mensaje, "error");
        return;
      }
      //////////
      const paramsTramite13 = {
        tipoTramite: 13 ///FORMULARIO DE CONTROL A LA ÚLTIMA AUTORIZACIÓN DE IMPORTACIÓN
      }
      try {
        var resp13 = await lastValueFrom(this.tipoDocumentoGeneralService.obtenerTodos(paramsTramite13))

        this.docRequisitos13 = resp13.datos.documentos
        this.docRequisitos13.forEach(doc => {
          const documentoFormGroup = this.formBuilder.group({
            id_tipo_documento_general: [doc.id_tipo_documento_general, null],
            nombre_documento: [doc.nombre_documento + ' ' + '(' + this.ultimaRA.nombre_sustancia + ')', null],
            descripcion: [doc.nombre_documento + ' ' + '(' + this.ultimaRA.nombre_sustancia + ')', null],
            identificador: [null, Validators.required],
            fecha_emision: [null, Validators.required],
            fecha_fin: [null],
            archivo: ['', null],
            base64: [ 'data:application/pdf;base64,', Validators.required],
            nuevo_archivo: [false, null],
            adjuntar_archivo_existente: [false, null],
            id_sustancia: [data.id_sustancia, null],
            id_autorizacion_previa: [null]
          })
          this.documentos.push(documentoFormGroup)
        })

      } catch (error) {
        Swal.fire("", error.error.mensaje, "error");
        return;
      }
    }
  }
  /**
  * funcion que permite guardar datos de comercializacion
  */
  async guardarComercializacion() {
    if (this.IdAutorizacionPrevia == null || this.IdAutorizacionPrevia == 0) {
      const params = {
        comercializacionForm: this.comercializacionForm.value,
        sustanciasForm: this.sustanciasForm.value,
        sustanciasArray: this.sustanciasArray.value,
        formDocumentos: this.formDocumentos.value,
        documentos: this.documentos.value,
        formDocumentosCorresponde: this.formDocumentosCorresponde.value,
        documentosCorresponde: this.documentosCorresponde.value,
        tipo_personeria: this._usuarioService.usuario.tipRep,
        id_registro: this.idRegistro,
        solicitante: this.razonSocial,
        id_tramite: this.idTramite
      }


      if (this.sustanciasArray.invalid) {
        Swal.fire('', '¡Complete campos requeridos de SUSTANCIAS, por favor!', 'warning')
        return;
      }

      /*     if (this.sustanciasArray.length < 1 || this.sustanciasArray.length == undefined) {
            Swal.fire('', '¡No se puede crear una autorización previa SIN SUSTANCIAS!', 'warning')
            return
          } */

      try {
        await lastValueFrom(this.autorizacionpreviaimportacionesService.crearPrimeraComercializacion(params))

      } catch (error) {
        Swal.fire('', error.error.mensaje, 'warning');
        return
      }
      // Swal.close()
      Swal.fire({
        title: "¡Guardado!", text: "Registro exitoso.", icon: "success",
        confirmButtonText: 'Aceptar', showCloseButton: true
      }).then(() => {
        // if (res.isConfirmed) {
        //   this.formComercializacionOuput.emit({
        //     tipo: 1
        //   });
        // }
        this.btnGuardar()
      })
      // this.dialogRef.close({
      //   success: true
      // })
    } else if (this.IdAutorizacionPrevia != null || this.IdAutorizacionPrevia != undefined || this.IdAutorizacionPrevia != 0) {

      var params;
      if (this.datosMod.costo > this.datosMod.monto_pagado) {
        params = {
          comercializacionForm: this.comercializacionForm.value,
          sustanciasForm: this.sustanciasForm.value,
          sustanciasArray: this.sustanciasArray.value,
          formDocumentos: this.formDocumentos.value,
          documentos: this.documentos.value,
          IdTramite: this.id_tramite,
          subsanado: false
        };
      } else if ((this.datosMod.monto_pagado >= this.datosMod.costo && (this.datosMod.tipo_pago === 1 || this.datosMod.tipo_pago === 2))) {
        params = {
          comercializacionForm: this.comercializacionForm.value,
          sustanciasForm: this.sustanciasForm.value,
          sustanciasArray: this.sustanciasArray.value,
          formDocumentos: this.formDocumentos.value,
          documentos: this.documentos.value,
          IdTramite: this.id_tramite,
          subsanado: true
        };
      }
      var respuesta = await Swal.fire({
        title: "¿Desea guardar el registro?", text: "¡No podrás revertir esto! ", icon: "question",
        showCancelButton: true, confirmButtonText: "Si, guardar",
        cancelButtonText: "No, cancelar",
        showCloseButton: true
      })
      if (!respuesta.isConfirmed) return
      Swal.fire("", "Espere por favor", "info");
      Swal.fire({
        title: "Espere por favor...",
        html: "¡Almacenando Registro!",
        allowOutsideClick: false,
      });
      Swal.showLoading()
      try {
        await lastValueFrom(this.autorizacionpreviaimportacionesService.actualizarComercializacion(this.IdAutorizacionPrevia, params));
      } catch (error) {
        Swal.fire('', error.error.mensaje, 'warning');
        return;
      }
      Swal.close()
      Swal.fire({
        title: "¡Guardado!", text: "Registro exitoso.", icon: "success",
        confirmButtonText: 'Aceptar', showCloseButton: true
      })
      //  this.router.navigateByUrl('/licencias-previas/bandeja-licencias-previas/lista');

    }
  }


  async btnGuardar() {
    this.formComercializacionOuput.emit({
      tipo: 1
    });
  }

}
