import { DocumentosAdicionalesService } from './../../services/documentos-adicionales.service';
import { VisorPdfDialogoComponent } from 'src/app/core/components/visor-pdf-dialogo/visor-pdf-dialogo.component'
import { lastValueFrom } from 'rxjs'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Component, Inject, NgZone, OnInit } from '@angular/core'
import { FormGroup, FormBuilder, FormArray, Validators, FormControl } from '@angular/forms'
import Swal from 'sweetalert2'
import { Parametricas } from 'src/app/core/enums/parametricas.enums'
import * as moment from 'moment'
import { EditarDocumentosComponent } from '../editar-documentos/editar-documentos.component';
//import { EditarDocumentosComponent } from '../editar-documentos/editar-documentos.component';


@Component({
  selector: 'app-carga-documentos',
  templateUrl: './carga-documentos.component.html',
  styleUrls: ['./carga-documentos.component.scss']
})
export class CargaDocumentosComponent implements OnInit {

  docObligatorios: any[] = [] // Array para recibir los documentos Obligatorios
  docAdicionales: any[] = [] // Array para recibir los documentos Adicionales
  idTramite: number = 0
  idEstadoTramite: number
  enableSave: boolean = false

  formDocumentos: FormGroup
  idDocumentoAdicionalEncontrado: number = -1

  DOCUMENTO_OBLIGATORIO: any
  DOCUMENTO_ADICIONAL: any

  campo: any = null
  idCampo: any = null
  today: Date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
  fechaEmision: any = null
  tipoTramite: any = null
  subtipoTramite: any = 0
  idEstado: any = null
  verArchivo: boolean = false;

  mensajeAdicionales = 'Puede adjuntar los documentos que considere necesarios'
  alertAdicionales = 'DEBERÁ AGREGAR TODOS LOS REQUISITOS PRESENTADOS EN SU ANTERIOR REGISTRO'
  alertAceptar = 'SI SE ADJUNTÓ TODOS LOS DOCUMENTOS REQUERIDOS SE DEBERÁ SOLICITAR EL TRAMITE '

  constructor(private ngZone: NgZone,
    private documentosAdicionalesService: DocumentosAdicionalesService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CargaDocumentosComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.dialogRef.updateSize('65%', '70%')
    this.dialogRef.disableClose = true

    this.DOCUMENTO_OBLIGATORIO = Parametricas.OBLIGATORIO
    this.DOCUMENTO_ADICIONAL = Parametricas.ADICIONAL

    this.idTramite = this.data && this.data.idTramite ? this.data.idTramite : null

    // Esstos 2 campos son opcionales para poder filtrar por un tipo de id
    // (En realidad mas que todo para registro)
    this.campo = this.data?.campo ? this.data.campo : null
    this.idCampo = this.data?.idCampo ? this.data.idCampo : null
    this.subtipoTramite = this.data?.subtipoTramite ? this.data.subtipoTramite : null
    this.tipoTramite = this.data?.tipoTramite ? this.data.tipoTramite : null
    this.idEstado = this.data?.idEstado ? this.data.idEstado : null

    this.formDocumentos = this.formBuilder.group({
      documentos_obligatorios: this.formBuilder.array([]),
      documentos_adicionales: this.formBuilder.array([])
    })

    this.obtenerDocumentos()
    if (this.subtipoTramite == 19) {
      this.mensajeAdicionales = 'Documentos presentados en su trámite renovación o trámite nuevo registro'
    } else {
      this.mensajeAdicionales = 'Puede adjuntar los documentos que considere necesarios'
    }
  }
  actualizarFormulario() {
    this.ngZone.runOutsideAngular(() => {
      this.obtenerDocumentos()
    });
  }

  get documentosObligatorios() {
    return this.formDocumentos.controls['documentos_obligatorios'] as FormArray
  }

  get documentosAdicionales() {
    return this.formDocumentos.controls['documentos_adicionales'] as FormArray
  }

  async obtenerDocumentos() {
    try {
      let paramsDoc = {
        idTramite: this.idTramite,
        campo: this.campo,
        idCampo: this.idCampo
      }
      var resp = await lastValueFrom(this.documentosAdicionalesService.obtenerDocumentosObligatoriosYAdicionales(paramsDoc))
      console.log("RESP", resp);

    } catch (error) {
      Swal.fire({ text: error.error.mensaje, icon: 'warning', confirmButtonText: 'Aceptar' })
      return
    }

    this.docObligatorios = resp.datos.documentosObligatorios
    this.docAdicionales = resp.datos.documentosAdicionales
    this.idEstadoTramite = resp.datos.idEstadoTramite

    this.docObligatorios.forEach((doc) => {
      this.agregarDocumento(doc, this.DOCUMENTO_OBLIGATORIO)
    })

    this.docAdicionales.forEach((doc) => {
      this.agregarDocumento(doc, this.DOCUMENTO_ADICIONAL)
    })
  }

  async adjuntarDocumento(documentoForm: FormGroup, index: number) {
    this.idDocumentoAdicionalEncontrado = -1

    if (documentoForm.controls['descripcion'].invalid) {
      Swal.fire({ text: 'Debe ingresar el nombre del documento', icon: 'warning', confirmButtonText: 'Aceptar' })
      return
    }

    if (documentoForm.controls['identificador'].invalid || documentoForm.controls['fecha_emision'].invalid) {
      Swal.fire({ text: 'Debe ingresar el identificador y la fecha de emisión', icon: 'warning', confirmButtonText: 'Aceptar' })
      return
    }

    // Si es un documento No obligatorio (Adicional)
    if (!documentoForm.value.obligatorio) {
      // Verificamos que dos o mas documentos no contengan la misma descripcion de un documento adicional
      let indexFound = -1
      for (let index = 0; index < this.documentosAdicionales.value.length; index++) {
        const docAdicional = this.documentosAdicionales.value[index];

        let docs = [...this.documentosAdicionales.value]
        docs.splice(index, 1)

        indexFound = docs.findIndex((doc) => doc.descripcion.trim().toLowerCase() == docAdicional.descripcion.trim().toLowerCase())
        // if (indexFound != -1) {
        //   Swal.fire({ text: 'El nombre del documento se encuentra duplicado', icon: 'warning', confirmButtonText: 'Aceptar' })
        //   break
        // }
      }
      // if (indexFound != -1)
      //   return
    }

    try {
      // Verificamos si el documento adicional ya existe en la DB
      var respVerificacion = await this.verificarDocumento(documentoForm)
      console.log("respVerificacion", respVerificacion);

    } catch (error) {
      return
    }

    if (respVerificacion.codigo != 2) {
      // Significa que no encontro ningun documento - seleccionamos un nuevo documento
      this.seleccionarNuevoDocumento(documentoForm, index)

      // Swal.fire({ text: 'No se encontro ningun archivo similar', icon: 'info', confirmButtonText: 'Seleccionar nuevo archiuvo' })
      //   .then(resp => {
      //     this.seleccionarNuevoDocumento(documentoForm, index)
      //     return
      //   })

      return
    }

    /**
     * coment buscar bd docuemntos existentes
     * const documentoEncontrado = respVerificacion.datos.docAdicional
     * */

    const documentoEncontrado = respVerificacion.datos
    console.log("documentosss", documentoEncontrado);

    // Mostramos los archivos encontrados
    const respDocumentosEncontrados = await this.mostrarArchivosEncontrados(documentoEncontrado)
    //const respDocumentosEncontrados = null
    console.log("documentos encontrados", respDocumentosEncontrados);

    if (!respDocumentosEncontrados)
      return

    // Si el usuario decidio volver a enviar otro documento
    if (respDocumentosEncontrados != 2) {
      // Abrimos el cuadro de dialogo para seleccionar un archivo
      this.seleccionarNuevoDocumento(documentoForm, index)
      return
    }

    // Relacionamos el documento que se encontro y se acepto relacionar al tramite
    await this.relacionarDocumento(documentoForm, documentoEncontrado)
  }

  async verificarDocumento(documentoForm: FormGroup) {

    // Verificamos si el documento adicional ya existe en la DB
    try {

      /**
       * //coment buscar bd docuemntos existentes
       * var verificacionResp = await lastValueFrom(this.documentosAdicionalesService.verificarDocumento({
          idTramite: this.idTramite,
          identificador: documentoForm.value.identificador,
          fecha_emision: documentoForm.value.fecha_emision,
          descripcion: documentoForm.value.descripcion
        }))
        */


      var verificacionResp = { codigo: 1, datos: {}, mensaje: "No se encontro ningun documento" }
      console.log("verificacionResp::::", verificacionResp);

    } catch (error) {
      Swal.fire({ text: error.error.mensaje, icon: 'warning', confirmButtonText: 'Aceptar' })
      throw error.error.mensaje
    }



    return verificacionResp
  }

  seleccionarNuevoDocumento(documentoForm: FormGroup, index: number) {
    var idDocumento = documentoForm.value.tipo + '-' + index
    document.getElementById(idDocumento).click()
    return
  }

  async mostrarArchivosEncontrados(documento: any) {
    this.idDocumentoAdicionalEncontrado = documento.id_documento_adicional

    await Swal.fire({ text: `Se encontro un documento con los mismos datos`, icon: 'info', confirmButtonText: 'Ver documento' })

    const dialogPdf = this.dialog.open(VisorPdfDialogoComponent, {
      data: {
        archivoBase64: documento.base64,
        titulo: 'Documento encontrado',
        subtitulo: "",
        textoBoton: "Seleccionar nuevo documento",
        botonesExtra: [
          {
            texto: "Agregar a mi tramite",
            color: "secondary",
            code: 2
          }
        ]
      }
    })

    const result = await lastValueFrom(dialogPdf.afterClosed())

    if (!result || !result.res)
      return null

    return result.code
  }

  async relacionarDocumento(documentoForm: FormGroup, documentoEncontrado: any) {
    documentoForm.patchValue({
      base64: 'data:application/pdf;base64,',
      id_documento_adicional_encontrado: documentoEncontrado.id_documento_adicional,
      nuevo_archivo: false,
      adjuntar_archivo_existente: true
    })

    await this.guardarDocumento(documentoForm)
  }

  adjuntarArchivo(event: any, documentoForm: FormGroup): void {

    if (event.target.files.length > 0) {
      Swal.fire({
        title: "Espere por favor...",
        text: "Cargando documento",
        icon: 'info',
        allowOutsideClick: false,
      });
      // documentoForm.patchValue({
      //   base64: null,
      //   nuevo_archivo: false
      // })

      const size = event.target.files[0].size
      const fileType = event.target.files[0].type
      const fileName = event.target.files[0].name

      if (fileType !== 'application/pdf') {
        Swal.fire({ text: 'Solo se aceptan archivos en formato .pdf', icon: 'warning', confirmButtonText: 'Aceptar' })
        return
      }

      if (size > Parametricas.maxFileSize) {
        Swal.fire({ text: 'El archivo no puede tener mas de 50Mb de tamaño', icon: 'warning', confirmButtonText: 'Aceptar' })
        return
      }

      const reader = new FileReader()
      reader.readAsDataURL(event.target.files[0])
      reader.onload = async (ev: any) => {
        let docBase64 = ev.target.result

        //Quitamos la cabecera del tipo de archivo data:application/pdf;base64,
        docBase64 = docBase64.substring(28)
        let archivoBinario = window.atob(docBase64)

        // // Verificamos si el archivo esta dañado
        if (archivoBinario.lastIndexOf("%PDF-") !== 0 || archivoBinario.lastIndexOf("%%EOF") === -1) {
          Swal.fire({ text: 'El archivo esta dañado, por favor revise y vuelva a intentarlo', icon: 'warning', confirmButtonText: 'Aceptar' })
          return
        }

        documentoForm.patchValue({
          archivo: fileName,
          base64: docBase64,
          nuevo_archivo: true,
          adjuntar_archivo_existente: false
        })

        await this.guardarDocumento(documentoForm)

      }
      Swal.showLoading()
    }

  }

  agregarDocumento(doc: any = null, tipoDocumento: string = this.DOCUMENTO_ADICIONAL) {
    console.log("doc", doc);
    const documentoForm = this.formBuilder.group({
      archivo: ['', null],
      descripcion: [doc && doc.descripcion ? doc.descripcion : '', Validators.required],
      identificador: [doc && doc.identificador ? doc.identificador : '', Validators.required],
      fecha_emision: [doc && new Date(doc.fecha_emision) ? doc.fecha_emision : '', Validators.required],
      fecha_fin: [doc && new Date(doc.fecha_fin) ? doc.fecha_fin : null],
      base64: [doc && doc.uuidv4 ? 'data:application/pdf;base64,' : null, Validators.required],
      guardado: [doc && doc.nombre_archivo ? true : false, null],
      obligatorio: [doc && doc.obligatorio ? doc.obligatorio : false, null],


      idTramite: [this.idTramite, Validators.required],
      id_documento_adicional: [doc && doc.id_documento_adicional ? doc.id_documento_adicional : -1, null],
      // Actual id_estado_tramite del Tramite
      id_estado_tramite: [this.idEstadoTramite, null],
      // id_estado_tramite en el que fue agregado el documento al tramite
      id_estado_tramite_documento: [doc && doc.id_estado_tramite ? doc.id_estado_tramite : null, null],
      id_estado_documento_adicional: [doc && doc.id_estado_documento_adicional ? doc.id_estado_documento_adicional : null, null],
      id_documento_adicional_encontrado: [null, null],

      accion: [doc && doc.id_estado_documento_adicional ? Parametricas.ACTUALIZAR : Parametricas.CREAR, null],
      tipo: [tipoDocumento, null],
      nuevo_archivo: [false, null],
      adjuntar_archivo_existente: [false, null],
      orden: [doc && doc.orden ? doc.orden : this.docAdicionales.length + 1],
      verificado: [doc && doc.verificado ? doc.verificado : null],
      editable: [doc && doc.editable ? doc.editable : null]
    })

    if (tipoDocumento == this.DOCUMENTO_OBLIGATORIO)
      this.documentosObligatorios.push(documentoForm)
    else
      this.documentosAdicionales.push(documentoForm)

    // if ((this.idEstado == 614) && documentoForm.get('id_documento_adicional').value != -1) {
    //   this.verArchivo = true
    // } else {
    //   this.verArchivo = false
    // }
  }

  async guardarDocumento(documentoForm: FormGroup) {
    if (documentoForm.invalid) {
      Swal.fire({ text: 'Debe completar el formulario', icon: 'warning', confirmButtonText: 'Aceptar' })
      return
    }

    // Completamos el base64 con su cabecera respectiva
    if (documentoForm.value.base64.substring(28) != 'data:application/pdf;base64,') {
      const base64 = 'data:application/pdf;base64,' + documentoForm.value.base64
      documentoForm.patchValue({ base64: base64 })
    }

    // Verificamos que la fecha de emision y vencimiento sean correctas
    // let fechaEmision =  moment(documentoForm.value.fecha_emision).format("YYYY-MM-DD")
    let fechaEmision = new Date(documentoForm.value.fecha_emision)
    //let fechaVencimiento = moment(documentoForm.value.fecha_vencimiento).format("YYYY-MM-DD")
    let fechaVencimiento = new Date(documentoForm.value.fecha_vencimiento)

    // Validamos las fechas (Que la fecha de vencimiento sea posterior a la fecha de emision)
    // if (moment(fechaEmision).isAfter(fechaVencimiento, 'day')) {
    //   Swal.fire({ text: 'La fecha de vencimiento no puede ser inferior a la fecha de emision!', icon: 'warning', confirmButtonText: 'Aceptar' })
    //   return
    // }

    // ENVIAR DIRECTAMENTE AL BACKEND PA Q SE VAYA GUARDANDO UNO POR UNO LOS ARCHIVOS
    try {
      var resp = await lastValueFrom(this.documentosAdicionalesService.guardarDocumentoAdicional(documentoForm.value))
    } catch (error) {
      Swal.fire({ text: error.error.mensaje, icon: 'warning', confirmButtonText: 'Aceptar' })
      return
    }

    // Quitamos la cabecera del tipo de archivo data:application/pdf;base64,
    const base64File = documentoForm.value.base64.substring(28)

    documentoForm.patchValue({
      guardado: true,
      accion: Parametricas.ACTUALIZAR,
      base64: base64File,
      nuevo_archivo: false,
      adjuntar_archivo_existente: false
    })

    Swal.fire({ text: 'Documento guardado correctamente', icon: 'success', confirmButtonText: 'Aceptar' })
    return
  }

  async verDocumento(documentoForm) {
    // Si aun no se cargo ningun archivo (Significa que esta en null)
    if (!documentoForm.value.base64)
      return

    let idDocumentoAdicional = documentoForm.value.id_documento_adicional

    if (documentoForm.value.adjuntar_archivo_existente)
      idDocumentoAdicional = documentoForm.value.id_documento_adicional_encontrado

    // Si ya se cargo el archivo pero aun no se trajo el archivo del backend (Modo: edicion)
    if (documentoForm.value.base64 == 'data:application/pdf;base64,') {
      try {
        var documentoResp = await lastValueFrom(this.documentosAdicionalesService.obtenerDocumentoAdicional(idDocumentoAdicional))
      } catch (error) {
        Swal.fire({ text: error.error.mensaje, icon: 'warning', confirmButtonText: 'Aceptar' })
        return
      }

      documentoForm.patchValue({
        base64: documentoResp.datos.documento.base64
      })
    }

    this.dialog.open(VisorPdfDialogoComponent, {
      data: { archivoBase64: documentoForm.value.base64, disableCancelButton: true }
    })
  }

  async eliminarDocumento(docAdicionalForm: FormGroup, index: number) {
    console.log("ELIMINAR", docAdicionalForm.value);

    // Eliminar en DB - Backend
    if (docAdicionalForm.value.accion != Parametricas.CREAR) {
      try {
        docAdicionalForm.patchValue({
          accion: Parametricas.ELIMINAR
        })
        await lastValueFrom(this.documentosAdicionalesService.guardarDocumentoAdicional(docAdicionalForm.value))
        Swal.fire({ text: 'El documento ha sido eliminado', icon: 'success', confirmButtonText: 'Aceptar' })
      } catch (error) {
        Swal.fire({ text: error.error.mensaje, icon: 'warning', confirmButtonText: 'Aceptar' })
        return
      }
    }

    this.documentosAdicionales.removeAt(index)
  }

  finalizar() {
    if (this.formDocumentos.invalid) {
      Swal.fire({ text: 'Debe completar el formulario', icon: 'warning', confirmButtonText: 'Aceptar' })
      return
    }

    if (this.subtipoTramite == 19 && (this.formDocumentos.get('documentos_adicionales').value.length == 0)) {
      Swal.fire({ text: 'Registrar documentos presentados en su anterior registro', icon: 'warning', confirmButtonText: 'Aceptar' })
      return
    }
    // Devolvemos true porque ya se guardo todo correctamente
    this.dialogRef.close({
      success: true
    })
  }

  async finalizarDocumentos() {
    if (this.formDocumentos.invalid) {
      Swal.fire({ text: 'Debe completar el formulario', icon: 'warning', confirmButtonText: 'Aceptar' })
      return
    }

    if (this.subtipoTramite == 19 && (this.formDocumentos.get('documentos_adicionales').value.length == 0)) {
      Swal.fire({ text: 'Registrar documentos presentados en su anterior registro', icon: 'warning', confirmButtonText: 'Aceptar' })
      return
    }
    console.log("ESTADO::", this.idEstado);

    // if (this.idEstado == 601) {
    //   var res = await Swal.fire({
    //     text: `Si se adjunto todos los documentos deberá solicitar el tramite`,
    //     icon: "info",
    //     confirmButtonText: "Aceptar",
    //     reverseButtons: true
    //   });
    // }
    // if (!res.isConfirmed)
    //   return
    // Devolvemos true porque ya se guardo todo correctamente
    this.dialogRef.close({
      success: true
    })
  }

  cancelarAdjuntarDocumentos() {
    this.dialogRef.close({
      success: false
    })
  }

  async editarInfoDoc(documentoForm) {
    console.log("documentoForm", documentoForm.value);

    if (!documentoForm.value.guardado) {
      Swal.fire({ text: 'Debe adjuntar el documento', icon: 'warning', confirmButtonText: 'Aceptar' })
      return
    }
    const dialoEditDocs = this.dialog.open(EditarDocumentosComponent, {
      width: '650px',
      data: { datosDocs: documentoForm.value }
    })
    dialoEditDocs.afterClosed().subscribe({
      next: async data => {
        if (data.codigo == 1) {
          this.docAdicionales
          this.documentosAdicionales.clear()
          this.documentosObligatorios.clear()
          this.obtenerDocumentos()
        }
      }
    })
  }

  /**
   * 
   * @param documento cambiart posiscion de los documentos
   * @param indice1 
   * @param indice2 
   * @returns 
   */
  async subirPosicion(documento, indice1, indice2) {
    const guardar = this.documentosAdicionales.value.some(doc => !doc.guardado)
    if (guardar) {
      Swal.fire({ text: 'Debe guardar el documento', icon: 'warning', confirmButtonText: 'Aceptar' })
      return
    }
    const primerElemento = this.documentosAdicionales.at(indice1);
    const segundoElemento = this.documentosAdicionales.at(indice2);
    this.documentosAdicionales.setControl(indice1, segundoElemento);
    this.documentosAdicionales.setControl(indice2, primerElemento);
    const arrayBack = this.documentosAdicionales.value.map((item, index) => ({ id: item.id_documento_adicional, descripcion: item.descripcion, guardado: item.guardado, orden: index + 1 }))
    try {
      let paramsDoc = {
        idTramite: this.idTramite,
        documentos: arrayBack
      }
      var resp = await lastValueFrom(this.documentosAdicionalesService.ordenarDocumentos(paramsDoc))
      console.log("RESP", resp);
    } catch (error) {
      console.log(error);
      Swal.fire({ text: error.error.mensaje, icon: 'warning', confirmButtonText: 'Aceptar' })
      return
    }
    console.log("arrayBack", arrayBack);
    /**enviar al back para cambiar el orden y guardar, al momento agregar un nuevo documento podemos mandar el orden des del formgrup*/
  }
}
