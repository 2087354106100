import { Component, Input, OnChanges, OnInit } from "@angular/core"

@Component({
  selector: "app-pdf-viewer",
  templateUrl: "./pdf-viewer.component.html",
  styleUrls: ["./pdf-viewer.component.scss"],
})
export class PdfViewerComponent implements OnInit, OnChanges {
  @Input() url: boolean
  @Input() base64: boolean
  @Input() source = ""
  @Input() height = ""

  sourceFile: any = ""

  constructor() { }

  ngOnChanges(): void {
    if (this.base64) {
      this.sourceFile = this.base64ToArrayBuffer(this.source)
    }
  }

  ngOnInit(): void {
    if (this.base64 && this.url) {
      console.warn("Debes enviar solo uno de los dos parametros: base64 o urlPdf")
      return
    }

    if (this.base64) {
      this.sourceFile = this.base64ToArrayBuffer(this.source)
    }

    if (this.url) {
      this.sourceFile = this.source
    }

    if (!this.height) {
      this.height = "100%"
    }
  }

  base64ToArrayBuffer(base64) {
    // let binary_string = base64.replace(/\\n/g, "")
    let binary_string = window.atob(base64)
    let len = binary_string.length
    let bytes = new Uint8Array(len)

    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i)
    }

    return bytes.buffer
  }
}
