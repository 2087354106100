import { Component, OnInit } from '@angular/core';
import { Parametricas } from 'src/app/core/enums/parametricas.enums';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss']
})
export class AuthLayoutComponent implements OnInit {
  public authTitle = Parametricas.authTitle
  public authSubtitle = Parametricas.authSubtitle
  public dgsc=environment.base_url_backend=='https://edapi.mingobierno.gob.bo/dgsc_api'? false:true;
  public environment=environment.production

  constructor() { }

  ngOnInit(): void { }

  downloadApp(fileName): void {
    // const fileName = `test.xlsx`;
    // this.manageExcelFile();
    const filtePath = `assets/app/${fileName}.apk`
    // const filtePath = window.URL.createObjectURL(new Blob(binaryData, { type: 'xlsx' }));
    const downloadLink = document.createElement('a')
    downloadLink.href = filtePath
    downloadLink.setAttribute('download', `${fileName}.apk`)
    document.body.appendChild(downloadLink)
    downloadLink.click()
  }

  // manageExcelFile(): void {
  //   // const dataType = response.type;
  //   // const binaryData = [];
  //   // binaryData.push(response);

  //   const filtePath = 'assets/app/dgsc-hr__24-10-2023.apk';
  //   // const filtePath = window.URL.createObjectURL(new Blob(binaryData, { type: 'xlsx' }));
  //   const downloadLink = document.createElement('a');
  //   downloadLink.href = filtePath;
  //   downloadLink.setAttribute('download', 'dgsc__hr.apk');
  //   document.body.appendChild(downloadLink);
  //   downloadLink.click();
  // }

}
