import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { UsuarioService } from 'src/app/modules/auth/services/usuario.service';
import { Token } from 'src/app/core/models/token.model';
import { SistemaService } from '../../services/sistema.service';

@Component({
  selector: 'app-pin-fd',
  templateUrl: './pin-fd.component.html',
  styleUrls: ['./pin-fd.component.scss']
})
export class PinFDComponent {

  public pin: string;
  public model: string;
  public name: string;
  public slot: number;
  public tokens: Token[];
  tokenForm: FormGroup = this.formBuilder.group({
    pin: ['', Validators.required]
  })

  constructor(
    private usuarioService: UsuarioService,
    private sistemaService: SistemaService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<PinFDComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder
  ) {
    console.log('Dentro dialogo', this.data.tokens);
    this.pin = '';
    this.model = '';
    this.name = '';
    this.slot = 1;

    this.tokens = this.data.tokens;
    if (this.tokens.length == 1) {
      this.model = this.tokens[0].model;
      this.name = this.tokens[0].name;
    }
  }

  async aceptar() {
    this.pin = this.tokenForm.value.pin
    if (!this.pin)
      return;

    //validando el pin ingresado
    try {
      var dataToken = await this.usuarioService.getTokenData(this.slot, this.pin).toPromise();
    } catch (error) {
      Swal.fire('', error.error.mensaje, 'error');
      this.dialogRef.close({ 'ok': false });
      return;
    }

    //tenemos datos del dueño del token
    if (!dataToken.finalizado) {
      Swal.fire('', dataToken.mensaje, 'info');
      return;
    }

    if (dataToken.datos.data_token.certificates == 0) {
      Swal.fire('', 'No se encontro informacion del propietario del token', 'info');
      return;
    }

    if (dataToken.datos.data_token.certificates > 1) {
      Swal.fire('', 'Se tiene varios certificados, se valida con datos del primer certificado', 'info');
    }

    //en data tenemos 2 objetos, el segundo es mas completo, tiene tanto el alias como el propietario del certificado
    let certificado = dataToken.datos.data_token.data[1];

    try {
      var resFecha = await this.sistemaService.getFechaSistema().toPromise();
    } catch (error) {
      Swal.fire('', error.error.mensaje, 'error');
      this.dialogRef.close({ 'ok': false });
      return;
    }

    //validamos la vigencia del certificado de firma digital
    let hasta = new Date(certificado.validez.hasta);
    let fechaSistema = new Date(resFecha.datos.fecha);

    if (fechaSistema > hasta) {
      Swal.fire('', 'Certificado vencido en fecha ' + hasta + ', debe renovar su certificado', 'info');
      return;
    }

    //validamos que el dueño del token sea el usuario
    if (this.usuarioService.usuario.ci != certificado.titular.uidNumber) {
      Swal.fire('', 'El CI del titular del certificado del token no corresponde al usuario del sistema', 'info');
      return;
    }

    if (this.usuarioService.usuario.nombre.toUpperCase() != certificado.titular.CN.toUpperCase()) {
      Swal.fire('', 'El nombre del titular del certificado del token no corresponde al usuario del sistema', 'info');
      return;
    }

    //si llegamos aqui->todo estaria ok-> devolvemos un token y el pin
    this.dialogRef.close({
      'ok': true,
      'slot': this.slot,
      'pin': this.pin,
      'alias': certificado.alias,
    });
  }

  setToken(slot) {
    this.slot = slot;

    for (let i = 0; i < this.tokens.length; i++) {
      if (this.tokens[i].slot == slot) {
        this.model = this.tokens[i].model;
        this.name = this.tokens[i].name;
      }
    }
  }
}
