
<div class="user-container">
<div class="auth-container">
  <div class="row flex-column justify-content-center align-items-center">
    <div class="col-md-3">
      <div class="auth-container__box">
        <div class="auth-container__head">
          <div class="auth-container__head head__logo">
            <a routerLink="/login">
              <img src="assets/images/logo2021.jpg" alt="DGSC" />
            </a>
          </div>

          <a routerLink="/login">
            <img src="assets/images/iconofinal.png" alt="DGSC" width="65" height="65" />
          </a> <br>
          <mat-card-title
            style="text-align: center; font-size: 20px;margin-bottom: 1rem;"><br>{{authTitle}}</mat-card-title>
          <mat-card-title style="text-align: center;  font-size: 1.3vh;"><br>{{authSubtitle}}</mat-card-title>
        </div>
        <router-outlet></router-outlet>
      </div>
      
      <div class="text-center" style="width: 400px">
        <div class="mt-2 text-center">
          <div style="font-size: 0.9rem; color: #595959;">Descarga las aplicaciones móviles (Android)</div>
        </div>

        <div class="mt-1 d-flex justify-content-between" *ngIf="!dgsc">
          <div>
            <a (click)="downloadApp('dgsc__token')" matTooltip="Descargar aplicación DGSC-Token"
              style="cursor: pointer;">
              <img src="assets/images/icon-apps/dgsc-token.png" alt="" width="65">
            </a>
          </div>
          <div>
            <a (click)="downloadApp('dgsc_qr')" matTooltip="Descargar aplicación DGSC-QR" style="cursor: pointer;">
              <img src="assets/images/icon-apps/dgsc-qr.png" alt="" width="65">
            </a>
          </div>
          <div>
            <a (click)="downloadApp('dgsc__hr')" matTooltip="Descargar aplicación DGSC-HR" style="cursor: pointer;">
              <img src="assets/images/icon-apps/dgsc-hr.png" alt="" width="65">
            </a>
          </div>
        </div>

        <div class="mt-1 d-flex justify-content-between" *ngIf="dgsc">
          <div>
            <a (click)="downloadApp('dev_dgsc__token')" matTooltip="Descargar aplicación DGSC-Token Prueba"
              style="cursor: pointer;">
              <img src="assets/images/icon-apps/dgsc-token-test.jpeg" alt="" width="65">
            </a>
          </div>
          <div>
            <a (click)="downloadApp('dev_dgsc__qr')" matTooltip="Descargar aplicación DGSC-QR Prueba"
              style="cursor: pointer;">
              <img src="assets/images/icon-apps/dgsc-qr-test.jpeg" alt="" width="65">
            </a>
          </div>
          <div>
            <a (click)="downloadApp('dev_dgsc__hr')" matTooltip="Descargar aplicación DGSC-HR Prueba"
              style="cursor: pointer;">
              <img src="assets/images/icon-apps/dgsc-hr-test.jpeg" alt="" width="65">
            </a>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
</div>
