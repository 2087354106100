import { Component, OnInit } from '@angular/core';
import {  MatDialogRef } from '@angular/material/dialog'
import { ActivatedRoute, Router } from "@angular/router";
import { lastValueFrom } from "rxjs";
import Swal from 'sweetalert2';
import { TipoEntidadService } from "../../../core/services/parametricas/tipoEntidad.service";
@Component({
  selector: 'app-tipo-entidad',
  templateUrl: './tipo-entidad.component.html',
  styleUrls: ['./tipo-entidad.component.scss']
})
export class TipoEntidadComponent implements OnInit {
   /**
   * variable de seleccion de tipo de ENTIDAD
   */
   seleccionEntidad: number
    /**
   * variable de seleccion de tipo de ENTIDAD
   */
    seleccionPersoneria: number
        /**
 * Constructor  que permite consumir el servicio de tramites
 */
      
        public entidades: any[] = []
        public personerias: any[] = []
        public personerias0: any[] = []
  constructor(
        /**
     * objeto dialogRef para el tipo de ENTIDAD 
     */
        public dialogRef: MatDialogRef<TipoEntidadComponent>,
        private router: Router,
        private tipoEntidadService: TipoEntidadService,
  ) { }
   /**
     * funcion  de inicio del componente 
     */
  ngOnInit(): void {
    this.dialogRef.updateSize('auto', 'auto')
    this.cargarDatos()
    this.cargarDatosPersoneria()
  }

  cargarDatos(){
    this.tipoEntidadService.obtenerTipoEntidad().subscribe({
      next: data => {    
        this.entidades = data.datos.entidad
       // this.personerias = data.datos.personeria       
      }
      
    })     
  }
  cargarDatosPersoneria(){
    this.tipoEntidadService.obtenerTipoPersoneria().subscribe({
      next: data => {
      console.log("datas", data);
       this.personerias = data.datos.personeria    
       this.personerias0 = data.datos.personeria0   
      }
      
    })     
  }
   /**
     * funcion de cierre del componente
     */
  cancel(): void {
    this.dialogRef.close({ res: false });
  }   /**
  * funcion de retornar al inico de sesión
  */
  async retornar() {
    this.router.navigateByUrl('/login')
    this.dialogRef.close({ res: false })
  }
}
