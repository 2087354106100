<div class="form-registro"> 
 
  <mat-stepper [linear]="true" #stepper >  
    <mat-step label="Comercializacion" matTooltip="Formulario de Comercializacion" [stepControl]="formDatosGenerales">  
   
      <br>
     
        <mat-card class="main-card">
            <mat-card-content class="inner-card">
              <app-primera-comercializacion (formComercializacionOuput)="botonSiguiente($event)"></app-primera-comercializacion>
            </mat-card-content>
        </mat-card>
        <br>
       
      </mat-step>

      <mat-step label="Importacion" matTooltip="Formulario de Importacion" [stepControl]="formPrincipal"
        [editable]="true">
        
        <br>
        <mat-card class="main-card">
          <mat-card-content class="inner-card">
            <app-primera-importacion (formImportacionOuput)="botonSiguiente($event)"></app-primera-importacion>
          </mat-card-content>
        </mat-card>
        <br>
      </mat-step>

    </mat-stepper>
</div>

