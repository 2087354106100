<button mat-icon-button class="close-button" [mat-dialog-close]="true" (click)="cancelar()">
  <mat-icon class="close-icon" matTooltip="Cerrar dialogo">close</mat-icon>
</button>
<button mat-icon-button class="close-button" (click)="minimizar()" *ngIf="isFullScreen">
  <mat-icon class="close-icon" matTooltip="Restaurar pantalla">fullscreen_exit</mat-icon>
</button>
<button mat-icon-button class="close-button" (click)="maximizar()" *ngIf="!isFullScreen">
  <mat-icon class="close-icon" matTooltip="Pantalla completa">fullscreen</mat-icon>
</button>

<div class="row mt-2">
  <div class="col text-center">
    <h2>{{titulo}}</h2>
    <span style="font-size:13px;">{{subtitulo}}</span>
  </div>
</div>

<div class="row" style="border: thin solid #c7c7c7; position: relative;">

  <app-navbar-pdf [base64]="source" [fileName]="fileName"></app-navbar-pdf>

  <div class="pdf-viewer-block" [ngStyle]="{'height.px': pdfViewerHeight}">
    <app-pdf-viewer [source]="source" [base64]="base64" [url]="url"></app-pdf-viewer>
  </div>
</div>

<div mat-dialog-actions class="mt-3 mb-1">

  <div class="col text-end">
    
    <button mat-raised-button    [ngClass]="botonExtra.tipo=='stroked' ? 'mat-stroked-button' : 'mat-flat-button'" class="nuevo-button"
      *ngFor="let botonExtra of botonesExtra"
      [color]="botonExtra.tipo=='stroked' ? botonExtra.color + '-outline' : botonExtra.color"
      (click)="accionesExtra(botonExtra.code)"> <mat-icon>done</mat-icon>{{ botonExtra.texto }}</button>  
    <button mat-raised-button class="nuevo-button" (click)="aceptar()" *ngIf="this.textoBoton!='Descargar'"><mat-icon>done</mat-icon>{{textoBoton}}</button>
    <button mat-raised-button  color="warn" style="height: 45px;" (click)="cancelar()" *ngIf="!disableCancelButton"> <mat-icon>cancel</mat-icon> Cancelar {{enableCancelButton}}</button>
  </div>
</div>