<form [formGroup]="comercializacionForm ">
  <mat-card-title style="text-align: center; font-size: 20px;">SOLICITUD DE COMERCIALIZACIÓN</mat-card-title>
  <mat-card-content>
    <mat-card-title class="title" style="text-align: left;"> I. DATOS GENERALES</mat-card-title>
    <div class="row" style="font-size: 13px;">
      <div class="col-md-3">
        <mat-form-field appearance="outline" style="width: 90%;" class="disabled">
          <mat-label>Nombre/Razón Social</mat-label>
          <input matInput type="text" value="{{this._usuarioService.usuario.rz}}" disabled>
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field appearance="outline" style="width: 90%;" class="disabled">
          <mat-label>Representante Legal</mat-label>
          <input matInput type="text" value="{{this._usuarioService.usuario.nombre}}" disabled>
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field appearance="outline" style="width: 90%;" class="disabled">
          <mat-label>Número de Registro</mat-label>
          <input matInput type="text" value="{{this._usuarioService.usuario.num}}" disabled>
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field appearance="outline" style="width: 90%;" class="disabled">
          <mat-label>Correo</mat-label>
          <input matInput type="text" value="{{this._usuarioService.usuario.correo}}" disabled>
        </mat-form-field>
      </div>
    </div>
  </mat-card-content>
  <mat-card-content>
    <mat-card-title class="title" style="text-align: left;">II. DATOS DE LAS SUSTANCIAS</mat-card-title>


    <div class="row">
      <mat-card-title class="alert d-flex align-items-center fade show"><mat-icon>info</mat-icon>
        <div [innerHTML]="MesSustancia"></div>
      </mat-card-title>


      <!-- <div class="col-md-12">
      <button mat-raised-button  class="mx-3"  matTooltip="Agregar sustancias registradas"  class="nuevo-button"  *ngIf="!activar"
        (click)="agregarSustancias()">
        <mat-icon>add_box</mat-icon> Agregar sustancias
      </button>           
    </div> -->
      <div class="col-md-12">
        <button mat-raised-button class="mx-3" matTooltip="Agregar tipo de sustancias registradas" class="nuevo-button"
          *ngIf="!activar" (click)="agregarTipoSustancias()" [disabled]="verBoton()">
          <mat-icon>add_box</mat-icon> Agregar tipo de sustancias
        </button>
      </div>
    </div>
    <br>
    <!-- <div class="mat-elevation-z8" > -->
    <form [formGroup]="sustanciasForm">
      <table class="table table-list">
        <thead *ngIf="sustanciasArray.controls.length > 0">
          <tr>
            <th>N°</th>
            <!-- <th *ngIf="!activar">Acciones</th> -->
            <th>Sustancias (sinónimo o producto terminado)</th>
            <th>Tip. sustancia</th>
            <th>Unidad medida</th>
            <!-- <th></th>
                <th>Detalle de Envase(s)</th>
                <th>Cant. según Envase(s)</th>                                                 -->
          </tr>
        </thead>
        <tbody formArrayName="sustanciasArray" *ngFor="let sustanciaForm of sustanciasArray.controls; let i = index;">
          <tr [formGroupName]="i">
            <td>
              {{ i + 1 }}
            </td>
            <!-- <td *ngIf="!activar" style="width: 13%;">                      
                  <button mat-icon-button color="warn" matTooltip="Eliminar Sustancia" (click)="eliminaSustancia(i)" >
                    <img class="color-delete" src="assets/svg/delete_forever.svg"/>
                  </button>
                </td> -->
            <td> {{ sustanciaForm.value.sustancia_nombre }} ({{ sustanciaForm.value.sustancia_primaria }})</td>

            <td>
              <span>Primaria {{sustanciaForm.value.tipo_sustancia==true? '(S)' : '(N)' }}</span>
              <br>
              <span>{{ sustanciaForm.value.porcentaje }} %</span>
            </td>
            <td>{{ sustanciaForm.value.unidad_medida }}</td>
            <!-- <td>
                  <button mat-icon-button matTooltip="Agregar envases" (click)="agregarEnvasesLote(sustanciaForm)"
                  matTooltipClass="tooltip-background">
                  <img class="color-fill" src="assets/svg/format_color_fill_white.svg"/>
                </button></td>
                  <td>{{ sustanciaForm.value.tipo_envase }}</td>
                  <td>{{ sustanciaForm.value.cantidad_envases.toLocaleString("es-BO") }} {{ sustanciaForm.value.abreviacion }}</td> -->

          </tr>
        </tbody>

      </table>

    </form>
  </mat-card-content>
  <mat-card-content>
    <mat-card-title class="title" style="text-align: left;"> IV. REQUISITOS OBLIGATORIOS</mat-card-title>
    <div class="row">
      <mat-card-title class="alert d-flex align-items-center fade show"><mat-icon>info</mat-icon>
        <div [innerHTML]="MesDocumento"></div>
      </mat-card-title>

    </div>
    <!--  <div class="mat-elevation-z8"> -->
    <form [formGroup]="formDocumentos">
      <table class="table table-list">
        <thead *ngIf="documentos.controls.length > 0">
          <tr>
            <th>N°</th>
            <th>Acciones</th>
            <th>Requisito Obligatorio</th>
            <th>Número y/o descripción de Documento</th>
            <th>Fecha de Emisión</th>
            <th>Fecha Finalización</th>
            <th></th>

          </tr>
        </thead>
        <tbody formArrayName="documentosArray" *ngFor="let docObligatorioForm of documentos.controls; let i=index">
          <tr [formGroupName]="i">
            <td>
              {{ i + 1 }}
            </td>
            <td>
              <button mat-icon-button (click)="verDocumentoAdj(docObligatorioForm)" matTooltip="Ver documento"
                *ngIf="verArchivo(docObligatorioForm)">
                <img class="color-print" src="assets/svg/visibility_white.svg" />
              </button>
              <label
                matTooltip="{{ docObligatorioForm.value.base64 == null ? 'Adjuntar archivo' : '¿Actualizar archivo?' }}"
                *ngIf="verSinRA(docObligatorioForm)"
                [ngClass]="{'color-edit': docObligatorioForm.value.base64 == null , 'color-upload': docObligatorioForm.value.base64 == null }">
                <!--  <img class="color-upload" [src]="archivosAdjuntados[i] ? 'assets/svg/verified_white_24dp.svg' : 'assets/svg/attach_file_white.svg'"/> -->
                <img class="color-upload"
                  [src]="archivosAdjuntados[i] ? 'assets/svg/verified_white_24dp.svg' : 'assets/svg/attach_file_white.svg'"
                  [ngClass]="{'color-edit': archivosAdjuntados[i], 'color-upload': !archivosAdjuntados[i]}">
                <input type="file" (change)="adjuntarArchivo($event,docObligatorioForm,i)" style="display: none;"
                  accept=".pdf">
              </label>

              <button mat-icon-button (click)="verDocumento(docObligatorioForm)" matTooltip="Ver documento"
                *ngIf="verUltimaRA(docObligatorioForm)">
                <img class="color-print" src="assets/svg/visibility_white.svg" />
              </button>

            </td>
            <td style=" text-align:left"> {{ docObligatorioForm.value.nombre_documento }}</td>
            <td>
              <mat-form-field appearance="outline">
                <mat-label>Número de Documento</mat-label>
                <input matInput type="text" class="text-uppercase" required formControlName="identificador">
                <mat-error *ngIf='docObligatorioForm.controls["identificador"].hasError("required")'>Campo
                  requerido</mat-error>
                <mat-error *ngIf="docObligatorioForm.get('identificador').hasError('pattern')">No se permiten espacios
                  al inicio o al final</mat-error>
              </mat-form-field>
            </td>
            <td>
              <mat-form-field appearance="outline">
                <mat-label>Fecha de Emisión</mat-label>
                <input matInput [matDatepicker]="picker" formControlName="fecha_emision" readonly>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-error *ngIf='docObligatorioForm.controls["fecha_emision"].hasError("required")'>Campo
                  requerido</mat-error>
              </mat-form-field>
            </td>
            <td>
              <mat-form-field appearance="outline">
                <mat-label>Fecha Finalización</mat-label>
                <input matInput [matDatepicker]="pickerF" formControlName="fecha_fin" readonly>
                <mat-datepicker-toggle matSuffix [for]="pickerF"></mat-datepicker-toggle>
                <mat-datepicker #pickerF></mat-datepicker>
                <mat-error *ngIf='docObligatorioForm.controls["fecha_fin"].hasError("required")'>Campo
                  requerido</mat-error>
              </mat-form-field>
            </td>

          </tr>
        </tbody>
      </table>
    </form>

  </mat-card-content>
  <mat-card-content>
    <!-- <mat-card-title class="title" style="text-align: left;"> V. REQUISITOS (CUANDO CORRESPONDA)</mat-card-title>             -->


    <!--    </div> -->
    <!--  -->
    <div style="font-size: 12px">
      <div class="row">
        <div class="col-md-12">
          <mat-form-field style="width:100%;" appearance="outline">
            <mat-label>Información relevante que considere para el trámite</mat-label>
            <textarea matInput placeholder="" class="text-uppercase" rows="5" formControlName="notas_adicionales"
              maxlength="1000"></textarea>
            <small class="form-text text-muted">{{ comercializacionForm.get('notas_adicionales').value?.length
              }}/1000<br /></small>
            <mat-error *ngIf="comercializacionForm.get('notas_adicionales').hasError('pattern')">No se permiten espacios
              al inicio o al final</mat-error>
          </mat-form-field>
        </div>
      </div> <br>
      <div class="form-control" style="font-size: 10px">
        <div class="col-md-12">
          YO <strong>{{this._usuarioService.usuario.nombre}}</strong> CON CI.
          <strong>{{this._usuarioService.usuario.ci}}</strong> {{declaracion}}
        </div>
      </div>
      <div mat-dialog-actions class="mt-3 mb-1">
        <button mat-raised-button class="nuevo-button" [disabled]="!formsAreValid()"
          (click)="guardarComercializacion()">
          <mat-icon>save</mat-icon>Guardar
        </button>&nbsp;&nbsp;

        <button mat-raised-button class="nuevo-button" 
          (click)="btnGuardar()">
          <mat-icon>save</mat-icon>Siguiente
        </button>&nbsp;&nbsp;

        <button mat-raised-button color="warn" style="height: 45px;" [mat-dialog-close]="false">
          <mat-icon>cancel</mat-icon> Cancelar
        </button>
      </div>


    </div>
  </mat-card-content>


</form>