<div class="auth-container">
  <div class="row justify-content-center">
    <div class="col-md-3 auth-container__box">

      <div class="auth-container__head">
        <div class="auth-container__head head__logo">
          <img src="assets/images/logo2021.jpg" alt="DGSC" />
        </div>
        <mat-card-title style="text-align: center; font-size: 20px;"  class="main__title"><br>ESTADO DIGITAL 6 - DGSC</mat-card-title>
      </div>

      <div class="auth-container__body">
        <mat-stepper class="stepper-code" [linear]="true" #stepper>

          <mat-step [stepControl]="requestCodeForm" label="Solicitud" [editable]="false">
            <div class="body__lead">
              <h2 class="body__title">Solicitud de código de activación</h2>
              <p class="body__legend">Ingrese su correo con el cual se registró y se le enviará un nuevo código de
                activación</p>
            </div>
            <div class="auth-container__form">

              <form [formGroup]="requestCodeForm" novalidate (ngSubmit)="solicitarCodigo()" autocomplete="off">

                <mat-form-field appearance="outline">
                  <mat-label>Correo electrónico</mat-label>
                  <input matInput cdkFocusInitial placeholder="Correo electrónico" formControlName="email"
                    autocomplete="off">
                </mat-form-field>

                <button mat-raised-button color="primary" class="button-login" type="submit"
                  [disabled]="requestCodeForm.invalid">
                  Solicitar código
                </button>
              </form>
            </div>
          </mat-step>

          <mat-step label="Verificacion">
            <div class="body__lead">
              <h2 class="body__title">Ingrese su código de activación</h2>
              <p class="body__legend">Se le envió un código de verificación a su correo, por favor revíselo e ingrese el
                código
                de 6 dígitos.</p>
            </div>
            <div class="auth-container__form">
              <form [formGroup]="activationCodeForm" (ngSubmit)="verificarCodigo()" autocomplete="off">

                <mat-form-field appearance="outline">
                  <mat-label>Código</mat-label>
                  <input matInput cdkFocusInitial type="text" formControlName="codigo" name="codigo" maxlength="6">
                </mat-form-field>

                <button mat-raised-button color="primary" class="button-login" type="submit"
                  [disabled]="activationCodeForm.invalid" matStepperNext>Verificar código</button>

              </form>

            </div>
          </mat-step>
        
        </mat-stepper>
      
      </div>

      <div class="auth-container__footer">
        <div class="footer__option">
          <button mat-raised-button color="warn" [mat-dialog-close]="false"><mat-icon>cancel</mat-icon> Cancelar</button>
        </div>
      </div> 

    </div>
  </div>
</div>