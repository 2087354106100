<div class="auth-container__body">
    <div class="body__lead">       
        <h2 class="body__title"> Autentificación - Oficina Virtual</h2>
        <p class="body__legend">Puede ingresar al sistema de las siguientes maneras:</p>
    </div>
    <div class="auth__mode">
        <div class="mode-option">
            <a (click)="logincd()" matTooltip="Iniciar sesión con Ciudadanía Digital">
                <img src="assets/images/logo-cd.png" alt="Ciudadanía Digital" class="mode-option__img" />
                <!-- <div class="mode-option__label">Con su cuenta de Ciudadanía Digital</div> -->
            </a>

            <div class="mode-option__legend">
                <div class="mode-option__legend legend__option">
                    ¿No tiene cuenta de ciudadanía digital?
                    <a href="https://www.gob.bo/ciudadania/registrate">Registrate aquí</a>
                </div>
                <div class="mode-option__legend legend__option">
                    ¿Olvido su contraseña?
                    <a href="https://www.gob.bo/ciudadania/password">Presiona aquí</a>
                </div>
            </div>
        </div>
        <div class="mode-option">
            <a matTooltip="Iniciar sesión con Credenciales DGSC" routerLink="/login-dgsc">
                <img src="assets/images/logo-login.png" alt="Credencial Institucional" class="mode-option__img" />
                <!-- <div class="mode-option__label">Con su cuenta de Ciudadanía Digital</div> -->
            </a>
        </div>
    </div>
</div>