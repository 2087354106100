import { Injectable } from '@angular/core'
import { Observable, lastValueFrom } from 'rxjs'
import { BaseHttpService } from './base-http.service'
import { VisorPdfDialogoComponent } from '../components/visor-pdf-dialogo/visor-pdf-dialogo.component'
import { MatDialog } from '@angular/material/dialog'
import { TokenAppComponent } from '../components/token-app/token-app.component'
import Swal from 'sweetalert2'

@Injectable({
  providedIn: 'root'
})
export class TokenAppService {

  constructor(
    private baseHttpService: BaseHttpService,
    private dialog: MatDialog
  ) { }

  verificarToken(datos: any): Observable<any> {
    return this.baseHttpService.post("token-app/verificar-token", datos)
  }

  async aprobarDocumentoToken(pdf64, uuidv4) {
    
    // Visualizamos el pdf generado en un modal
    const dialogModalPdf = this.dialog.open(VisorPdfDialogoComponent, {
      data: { archivoBase64: pdf64 }
    })

    // Obtenemos la respuesta del boton que presiono en el modal
    var respOptionSelected = await lastValueFrom(dialogModalPdf.afterClosed())
   // console.log(respOptionSelected)

    if (!respOptionSelected || !respOptionSelected.res) {
      // Se cerro el modal de alguna manera sin presionar el boton de firmar
      //throw { mensaje: 'Cancelado' }
    }

    if (respOptionSelected.res === false || respOptionSelected.code === 0) {
      // Se presiono el boton de cancelar
      // throw { mensaje: 'Validación cancelada' }
    }

    // Si presiono el boton de firmar procedemos a validar con el token
    if (respOptionSelected.res === true && respOptionSelected.code === 1) {
      // console.log('Validar con el token')
      // Firmamos el documento generado con Adsib
      try {
        const dialogTokenApp = this.dialog.open(TokenAppComponent, {
          data: { idTramite: 1221, pdfBase64: pdf64, uuidv4: uuidv4 }
        })

        const respTokenApp = await lastValueFrom(dialogTokenApp.afterClosed())
        //  console.log('iam IN BACK', respTokenApp)
        if (respTokenApp?.result === false) {
          return { result: false, mensaje: 'Validacion de token cancelado' }
        }

        // Significa que todo fue bien y que el token era valido y se cambio correctamente de estado al tramite
        Swal.fire({
          title: "Solicitud validada",
          text: "Su solicitud ha sido validada y registrada correctamente",
          icon: "success",
          confirmButtonText: 'Aceptar'
        })
        return { result: true, mensaje: 'Documento validado y almacenado correctamente' }
      } catch (error) {
        Swal.fire({ text: error.mensaje, icon: 'warning', confirmButtonText: 'Aceptar' })
        throw { mensaje: 'Sucedio un error al intentar validar el token' }
      }
    }
  }
}
