import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BaseHttpService } from "src/app/core/services/base-http.service";
/**
 * Inyectable que permite la comunicacion con el api backend de sustancias
 */
@Injectable({
    providedIn: 'root'
})
export class SustanciasService{
    /**
   * url de servico backend de sustancias
   */
    localUrl='licencias-previas/sustancias'
      /**
   * Constructor del listado de sustancias
   */
    constructor(
            /**
   * metodo de servicio http
   */
        private baseHttpService: BaseHttpService) { }
  /**
   * Método que obtiene el listado de sustancias
   */ 
    public obtenerTodos(variables:any=null): Observable<any> {
        let params:string="?";
        for (const [key,value] of Object.entries(variables)){
            params += `${key}=${value}&`;
        }
        if(params.length < 2){
            params="";
        }else{
            params=params.substring(0,params.length-1);
        }
        return this.baseHttpService.get(`${this.localUrl}/obtenertodos${params}`);
      }
      
      public obtenerHidrocarburos(variables:any=null): Observable<any>{
        let params:string="?";
        for (const [key,value] of Object.entries(variables)){
            params += `${key}=${value}&`;
        }
        if(params.length < 2){
            params="";
        }else{
            params=params.substring(0,params.length-1);
        }
        return this.baseHttpService.get(`${this.localUrl}/obtenerHidrocarburos${params}`);
      }
        /**
   * Método que obtiene la cantidad de sustancias por actividad
   */ 
    public obtenerSustanciasActividad(): Observable<any> {      
        return this.baseHttpService.get(`${this.localUrl}/obtenerSustanciasActividad`)
      }
      public obtenerTipoSustancias(): Observable<any> {      
        return this.baseHttpService.get(`${this.localUrl}/obtenerTipoSustancias`)
      }

      public obtenerTipoHidro(idTramite: number): Observable<any> {      
        return this.baseHttpService.get(`${this.localUrl}/obtenerTipoHidro/${idTramite}`)
      }
  
}