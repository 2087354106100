import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseHttpService } from './base-http.service';

@Injectable({
  providedIn: 'root'
})
export class ImagenService {

  constructor(private baseHttpService: BaseHttpService) { }

  obtenerImagenes(datos: any): Observable<any> {
    return this.baseHttpService.post("imagen/obtener-imagenes-adicionales", datos);
  }

  guardarImagenAdicional(datos: any): Observable<any> {
    return this.baseHttpService.post("imagen/guardar-imagen-adicional", datos);
  }

  eliminarImagenAdicional(datos: any): Observable<any> {
    return this.baseHttpService.post("imagen/eliminar-imagen-adicional", datos);
  }
}