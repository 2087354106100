import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseHttpService } from 'src/app/core/services/base-http.service';
import { CostoCategoriaModel } from '../models/costoCategoria.model';

@Injectable({
  providedIn: 'root',
})

export class TramiteRegistroService {
  localUrl = 'tramite'
  localUrlTramite = 'registros/tramite-registro'
  listaDocumentos = []
  costosSustancia: Array<CostoCategoriaModel> = []

  constructor(private baseHttpService: BaseHttpService) { }

  public obtenerPdf(data: any): Observable<any> {
    //return this.baseHttpService.post(`${this.localUrl}/pdf-registro`, data);
    return this.baseHttpService.post(`${this.localUrlTramite}/pdfSolicitud-registro-nuevo`, data)
  }

  public obtenerSolicitudPdf(data: any): Observable<any> {
    return this.baseHttpService.post(`${this.localUrl}/registro/solicitud-modificacion-registro/pdf`, data)
  }


  // /registro/nuevo/pdf-cd
  public obtenerPdfCD(data: any): Observable<any> {
    return this.baseHttpService.post(`${this.localUrl}/registro/nuevo/pdf-cd`, data)
  }

  ///pdf-registro-renovacion
  public obtenerPdfRenovacion(data: any): Observable<any> {
    return this.baseHttpService.post(`${this.localUrlTramite}/pdf-registro-renovacion`, data)
  }

  public obtenerRegistroTramite(): Observable<any> {
    return this.baseHttpService.post(`${this.localUrlTramite}/lista-registro`, null);
  }

  public registroPagado(data: any): Observable<any> {
    return this.baseHttpService.post(`${this.localUrlTramite}/registro-pagado`, data)
  }

  public obtenerRegistroEditar(id: string | number): Observable<any> {
    return this.baseHttpService.get(`${this.localUrlTramite}/obtener-registro-editar/${id}`)
  }

  public obtenerRenovacionEditar(id: string | number): Observable<any> {
    return this.baseHttpService.get(`${this.localUrlTramite}/obtener-renovacion-editar/${id}`)
  }

  public obtenerRegistroEditarDatos(): Observable<any> {
    return this.baseHttpService.get(`${this.localUrlTramite}/obtener-registro-editar`)
  }

  public obtenerTramiteMigracion(data: any): Observable<any> {
    return this.baseHttpService.post(`${this.localUrlTramite}/lista-migracion`, data);
  }

  public obtenerTramiteRenovacion(data: any): Observable<any> {
    return this.baseHttpService.post(`${this.localUrlTramite}/lista-renovacion`, data);
  }

  public obtenerPdfSubsanando(data: any): Observable<any> {
    //return this.baseHttpService.post(`${this.localUrl}/pdf-registro`, data);
    return this.baseHttpService.post(`${this.localUrlTramite}/pdfSolicitud-subsanando`, data)
  }

  /**eliminar registro */
  public eliminarTramite(id: string | number, data: any) {
    return this.baseHttpService.put(`${this.localUrlTramite}/eliminarTramite/${id}`, data)
  }

  /**PDF Solicitud actualizacion de datos */
  public pdfActualizacionDatos(data: any) {
    return this.baseHttpService.post(`${this.localUrlTramite}/pdfSolicitud-actualizacion-datos`, data)
  }

  /**pdf formulario de solicitud actualizacion de datos */
  public pdfFormActualizacionDatos(data: any) {
    return this.baseHttpService.post(`${this.localUrl}/registro/solicitud/pdf`, data)
  }

  /**validaciones registro */
  public validacionesRegistro(data: any) {
    return this.baseHttpService.post(`${this.localUrlTramite}/validaciones-registro`, data)
  }


}