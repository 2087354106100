import { VisorPdfDialogoComponent } from "src/app/core/components/visor-pdf-dialogo/visor-pdf-dialogo.component";
import { MatDialog } from "@angular/material/dialog";
import { Injectable } from "@angular/core";
import { Observable, lastValueFrom } from "rxjs";
import Swal from "sweetalert2";
import { DocumentoFD } from "../models/documentoFD";
import { BaseHttpService } from "./base-http.service";

@Injectable({
  providedIn: "root",
})
export class TramiteService {
  constructor(
    private baseHttpService: BaseHttpService,
    public pdfModalDialog: MatDialog
  ) { }

  /*
  Calcula el costo de un trámite de tipo HR o ACL
  Tipo puede ser hojas o compras
  sustancia es un array que debe tener el id_sustancia_primaria y la cantidad
  vigencia es en dias, hasta 30 para HR y hasta 120 para ACL
  */
  getCostoTramite(
    tipo: string,
    sustancias: any[],
    vigencia: number
  ): Observable<any> {
    const parametros = {
      tipo: tipo,
      sustancias: sustancias,
      vigencia: vigencia,
    };
    return this.baseHttpService.post("tramite/costo", parametros);
  }
  /*
  Ver carpeta digital para el usuario de Oficina Virtual
  */
  getCarpetaDigital(idTramite: string): Observable<any> {
    const parametros = { idTramite: idTramite };
    return this.baseHttpService.post("tramite/carpeta-digital", parametros);
  }

  async verCarpetaDigital(idTramite: string) {
    const parametros = { idTramite: idTramite };

    Swal.fire({
      title: "",
      text: "Cargando espere por favor!",
      icon: "info",
      allowOutsideClick: false,
    });
    Swal.showLoading();
    try {
      var res = await lastValueFrom(
        this.baseHttpService.post("tramite/carpeta-digital", parametros)
      );
    } catch (error) {
      Swal.fire({
        text: error.error.mensaje,
        icon: "error",
        confirmButtonText: "Aceptar",
      });
      return;
    }

    if (!res.datos.pdf64)
      Swal.fire("Error", "No tenemos el archivo para mostrarlo", "info");
    Swal.close();

    // Visualizar el pdf generado
    const dialogModalPdf = this.pdfModalDialog.open(VisorPdfDialogoComponent, {
      disableClose: true,
      data: {
        archivoBase64: res.datos.pdf64,
        titulo: "Carpeta Digital",
        textoBoton: "Aceptar",
      },
    });
  }

  /*
  Guardamos documentos con firma digital
  */
  guardarDocumentoFD(documentoFD: DocumentoFD): Observable<any> {
    return this.baseHttpService.post("tramite/guardar-fd", documentoFD);
  }
  /*
  Guardamos documentos aprobados con ciudadania digital
  */
  guardarDocumentoCD(
    linkVerificacion: string,
    linkVerificacionUnico: string,
    transactionCode: string,
    requestUuid: string,
    uuidBlock: string
  ): Observable<any> {
    const body = {
      linkVerificacion,
      linkVerificacionUnico,
      transactionCode,
      requestUuid,
      uuidBlock,
    };
    return this.baseHttpService.post("tramite/guardar-cd", body);
  }

  //descargamos el ultimo pdf64 con fd o cd, segun el estado del tramite
  descargarpdf64(datos: any): Observable<any> {
    return this.baseHttpService.post("tramite/pdf-fd-cd", datos);
  }
  //descargamos el ultimo pdf64 con fd o cd, ra
  descargarpdfRa64(datos: any): Observable<any> {
    return this.baseHttpService.post("tramite/pdf-fd-ra", datos);
  }
  //descargamos el ultimo pdf64 con fd o cd, ra
  descargarpdfTNAP64(datos: any): Observable<any> {
    return this.baseHttpService.post("tramite/pdf-fd-nap", datos);
  }

  //descargamos el QR de una movilidad
  descargarpdf64QRmovilidad(datos: any): Observable<any> {
    return this.baseHttpService.post("tramite/movilidad/pdf-qr", datos);
  }

  //descargamos el ultimo pdf binario con fd o cd, segun el estado del tramite
  descargarpdfBinario(uuid: string): Observable<any> {
    return this.baseHttpService.getBlob("view-pdf/" + uuid);
  }

  //descargamos el ultimo pdf binario con fd o cd, segun el estado del tramite
  descargardescargopdfBinario(uuid: string): Observable<any> {
    return this.baseHttpService.getBlob("tramite/pdf-fd-cd-binario/" + uuid);
  }

  // obtenerDocumentos(tramiteId: number): Observable<any> {
  //   return this.baseHttpService.get(`tramite/documentos-adicionales/${tramiteId}`);
  // }

  pdfCD(datos: any): Observable<any> {
    return this.baseHttpService.post(
      "tramite/documento-adicional/pdf-cd",
      datos
    );
  }

  pdfFD(datos: any): Observable<any> {
    return this.baseHttpService.post(
      "tramite/documento-adicional/pdf-fd",
      datos
    );
  }

  guardarImagenAdicional(datos: any): Observable<any> {
    return this.baseHttpService.post("tramite/guardar-imagen-adicional", datos);
  }

  generarCPT(datos: any): Observable<any> {
    return this.baseHttpService.post("tramite/cpt", datos);
  }
  pasarelaPagos(datos: any): Observable<any> {
    return this.baseHttpService.post("tramite/pasarelaPagos", datos);
  }
  verificaFechaVencimientoPasarela(datos: any): Observable<any> {
    return this.baseHttpService.post("tramite/verificaFechaVencimientoPasarela", datos);
  }
  pagoExitosoPasarela(pago_id_tramite: string): Observable<any> {
    return this.baseHttpService.get("tramite/pagoExitosoPasarela/" + pago_id_tramite);
  }

  obtenerSustanciasTramite(idTramite: number): Observable<any> {
    return this.baseHttpService.get(`tramite/obtener-sustancias/${idTramite}`);
  }

  getCPT(id_tramite: number): Observable<any> {
    return this.baseHttpService.get("tramite/cpt/" + id_tramite);
  }

  verFormularioRegistro(): Observable<any> {
    return this.baseHttpService.post("tramite/registro/ver-mi-formulario-registro/pdf", {});
  }

  verCertificadoRegistro(): Observable<any> {
    return this.baseHttpService.post("registros/registro/certificado-registro-act/pdf-df", {});
  }

}
