import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { UsuarioService } from 'src/app/modules/auth/services/usuario.service'
import { PaisService } from '../../services/parametricas/pais.service';
import { AduanaService } from 'src/app/modules/licencias-previas/services/aduana.service'; 
import { AutorizacionPreviaImportacionesService } from 'src/app/modules/licencias-previas/services/autorizacion-previa-importaciones.service'; 
import { MatTable } from '@angular/material/table';
import { lastValueFrom } from 'rxjs';
import { SustanciaModel } from 'src/app/modules/licencias-previas/models/Sustancia.model'; 
import { SustanciaAPModel } from 'src/app/modules/licencias-previas/models/SustanciaAP.model'; 
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AgregarSustanciasComponent } from 'src/app/modules/licencias-previas/components/bandeja-licencias-previas/agregar-sustancias/agregar-sustancias.component';
import Swal from 'sweetalert2';
import { SustanciaAPService } from 'src/app/modules/licencias-previas/services/sustancia-ap.service'; 
import { UnidadMedidaModel } from 'src/app/modules/licencias-previas/models/UnidadMedida.model'; 
import { SwalObject } from '../../utils/swal'; 
import { ActivatedRoute, Router } from "@angular/router";
import { AgregarEnvasesComponent } from 'src/app/modules/licencias-previas/components/bandeja-licencias-previas/agregar-envases/agregar-envases.component'; 
import { AgregarEnvasesLoteComponent } from 'src/app/modules/licencias-previas/components/bandeja-licencias-previas/agregar-envases-lote/agregar-envases-lote.component'; 
import { Dias } from 'src/app/modules/licencias-previas/models/Dias.model'
import { Parametricas } from "src/app/core/enums/parametricas.enums"
import { TipoDocumentoGeneralService } from 'src/app/core/services/parametricas/tipoDocumentoGeneral.service';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import * as moment from 'moment';
import { DocumentosAdicionalesService } from '../../services/documentos-adicionales.service';
import { VisorPdfDialogoComponent } from 'src/app/core/components/visor-pdf-dialogo/visor-pdf-dialogo.component';
import { TransaccionPagoComponent } from "src/app/core/components/transaccion-pago/transaccion-pago.component";
import { PagosService } from "src/app/core/services/pagos.service";

@Component({
  selector: 'app-primera-importacion',
  templateUrl: './primera-importacion.component.html',
  styleUrls: ['./primera-importacion.component.scss']
})
export class PrimeraImportacionComponent implements OnInit {
 /**
   * variable datosDocumentos
   */
 datosDocumentos=null
 /**
 * variable docRequisitos
 */
docRequisitos: any[] = []
 /**
 * variable docRequisitos13
 */
docRequisitos13: any[] = []
 /**
 * variable docRequisitosCorresponde
 */
docRequisitosCorresponde: any[] = []
 /**
 * variable ultimaRA
 */
ultimaRA: any
 /**
 * variable raDocumento
 */
raDocumento: any
 /**
 * variable fecha_ini
 */
fecha_ini: any
/**
 * variable fecha_fi
 */
fecha_fi: any
 /**
 * variable archivoAdjuntado
 */
archivoAdjuntado = false; // Variable que indica si se ha adjuntado un archivo
 /**
 * variable indiceActual
 */
indiceActual: number = 0;
 /**
 * variable MesSustancia instanciado de parametricas
 */
public MesSustancia=Parametricas.MesSustancia
  /**
 * variable MesDocumento instanciado de parametricas
 */
public MesDocumento=Parametricas.MesDocumento
 /**
 * variable declaracion instanciado de parametricas
 */
public declaracion = Parametricas.declaracion
/**
 * variable Dias
 */
dias: Dias[] = [
  { value: 30, viewValue: '1 mes (30 días)' },
  { value: 60, viewValue: '2 meses (60 días)' },
  { value: 90, viewValue: '3 meses (90 días)' },
  { value: 120, viewValue: '4 meses (120 días)' },
  { value: 150, viewValue: '5 meses (150 días)' },
  { value: 180, viewValue: '6 meses (180 días)' },
];

/**
 * variable IdAutorizacionPrevia
 */
IdAutorizacionPrevia: any
/**
 * variable datosMod
 */
datosMod = null
 /**
 * variable datosSustancia
 */
datosSustancia = null
  /**
 * variable comprador
 */
comprador: any
  /**
 * variable id_autorizacion_previa
 */
id_autorizacion_previa = null
  /**
 * variable id_tramite
 */
id_tramite = null
  /**
 * variable capacidad_cant_envase1
 */
capacidad_cant_envase1: number = 0
  /**
 * variable capacidad_cant_envase2
 */
capacidad_cant_envase2: number = 0
  /**
 * variable idDocumentoAdicionalEncontrado
 */
idDocumentoAdicionalEncontrado: number = -1
/**
 * variable ArrayEnvase
 */
ArrayEnvase: []
/**
 * variable montop
 */
montop: number = 0
/**
 * variable monto
 */
monto: number = 0
 /**
 * variable datosSusReg
 */
datosSusReg = null
 /**
 * variable formularioControl
 */
formularioControl:boolean=true
 /**
 * variable activar
 */
activar: boolean = false

habilitado: boolean = true
/**
 * variable archivosAdjuntados
 */
archivosAdjuntados: boolean[];
/**
 * variable archivosAdjuntadosCorresponde
 */
archivosAdjuntadosCorresponde:boolean[]
/**
 * variable activarRA
 */
activarRA: boolean = false
/**
 * variable conteo
 */
conteo: number = 0
/**
 * variable conteoDocumentosTotal
 */
conteoDocumentosTotal: number = 0
/**
 * variable conteoDocumentosCorres
 */
conteoDocumentosCorres: number = 0
  /**
 * variable sum
 */
sum: number = 0
  /**
 * variable  ViewChild('stepper')
 */
@ViewChild('stepper') stepper;
  /**
 * variable  ViewChild('MatTable')
 */
@ViewChild(MatTable) tablaSeleccionados!: MatTable<SustanciaModel>;
/**
 * variable  inputFormControl
 */
inputFormControl = new FormControl({ value: null, disabled: true });
/**
 * variable  swal para las alertas y mensajes
 */
public swal = new SwalObject();
 /**
 * modelos de unidad de medida
 */
public unidadesMedidas: UnidadMedidaModel[] = [];
  /**
 * formulario importacionForm
 */
public importacionForm: FormGroup;
 /**
 * formulario sustanciasForm
 */
public sustanciasForm: FormGroup;
/**
 * variable proveedores
 */
public proveedores: any[] = [];
/**
 * variable paises
 */
public paises: any[] = [];
 /**
 * variable aduanas
 */
public aduanas: any[] = [];
  /**
 * modelos de sustancia registro
 */
public documentosAdicionales: any[] = [];

public sustancias: SustanciaModel[] = [];
 /**
 * modelos de sustancia ap
 */
public sustanciasAP: SustanciaAPModel[] = [];
 /**
 * variable envases
 */
public envases: any[] = [];
  /**
 * variable para el paginado
 */
public paginas = [5, 10, 15, 20];
/**
 * variable archivoCargado
 */
archivoCargado: any;
/**
 * variable nombreArchivo
 */
nombreArchivo: string;
/**
 * variable archivoURL
 */
archivoURL: any;
/**
*  formulario formDocumentos
*/
public formDocumentos: FormGroup
/**
*  formulario formDocumentosCorresponde
*/
public formDocumentosCorresponde: FormGroup
/**
*  variable DOCUMENTO_OBLIGATORIO
*/
DOCUMENTO_OBLIGATORIO: any
/**
*  variable para las columnas de la tabla
*/
public columnasSustancias: string[] = [
  "acciones", "nombre_sustancia_primaria", "tipo_sustancia", "abreviacion", "cantidad_solicitada", "saldo_kardex", "cantidad_capacidad", "tipo_envase_cantidad", "utilizacion"
];
/**
* propiedad de tabla seleccionada filtrada
*/
@ViewChild(MatSort) sort: MatSort;
/**
* propiedad de tabla seleccionada paginador
*/
@ViewChild(MatPaginator) paginator: MatPaginator;
 /**
* Constructor de agregar tipo documento
*/
idTramite: number = 0
tipoTramite: number = 0
subtipoTramite: number = 0
idEstado: number = 0
idRegistro: number = 0
razonSocial: string = ''
  tokenAppService: any;

constructor(
  private pagoService:PagosService,
/**
* instancia servicio documentosAdicionalesService
*/
  private documentosAdicionalesService: DocumentosAdicionalesService,
/**
* instancia rutaActiva
*/
  private rutaActiva: ActivatedRoute,
/**
* instancia router
*/
  private router: Router,
/**
* instancia dialog
*/
  public dialog: MatDialog,
/**
* instancia dialog
*/
  private formBuilder: FormBuilder,
  public _usuarioService: UsuarioService,
  private paisService: PaisService,
  private aduanaService: AduanaService,
  private tipoDocumentoGeneralService: TipoDocumentoGeneralService,
  public sustanciaAPService: SustanciaAPService,
  public autorizacionPreviaImportacionesService: AutorizacionPreviaImportacionesService,
  public dialogRef: MatDialogRef<PrimeraImportacionComponent>,
  @Inject(MAT_DIALOG_DATA) public data: any
) {
  this.comprador = '';
  this.archivosAdjuntados = [];
  this.archivosAdjuntadosCorresponde=[];
  // Inicializamos Formulario de importación 
  this.importacionForm = this.formBuilder.group({
    comprador: ['',[Validators.required,Validators.pattern(/^(?!\s)(?!.*\s$)(?!.*').*$/),Validators.maxLength(200)]],
    id_pais_origen: ['', null],
    id_pais_ultima_procedencia: ['', null],
    id_aduana: ['', null],
    dias_vigencia: ['', null],
    notas_adicionales: [null,[Validators.pattern(/^(?!\s)(?!.*\s$)(?!.*').*$/),Validators.maxLength(1000)]],
    origen: new FormControl({ value: "", disabled: true },[Validators.pattern(/^(?!\s)(?!.*\s$)(?!.*').*$/),Validators.maxLength(50)]),
    procedencia: new FormControl({ value: "", disabled: true },[Validators.pattern(/^(?!\s)(?!.*\s$)(?!.*').*$/),Validators.maxLength(50)]),
  });
  // Inicializamos Form de sustancias
  this.sustanciasForm = this.formBuilder.group({
    sustanciasArray: this.formBuilder.array([]),

  });
  /**
  * formulario documentosForm para la creacion de nuevo notificaciones
  */
  this.formDocumentos = this.formBuilder.group({
    documentosArray: this.formBuilder.array([]),
  });
  this.formDocumentosCorresponde = this.formBuilder.group({
    documentosCorrespondeArray: this.formBuilder.array([]),
  });
}

/**
* funcion para cadenas documentos de nuevo notificaciones
*/
get documentos() {
  return this.formDocumentos.controls['documentosArray'] as FormArray;
}
get documentosCorresponde() {
  return this.formDocumentosCorresponde.controls['documentosCorrespondeArray'] as FormArray;
}

get sustanciasArray(): FormArray {
  return this.sustanciasForm.controls['sustanciasArray'] as FormArray;
}
 
  
formsAreValid() {
  return this.importacionForm.valid && this.sustanciasArray.valid && this.documentos.valid && this.sustanciasArray.length>0;
}
validateOrigen(event: any) {
  const inputValue = event.target.value;
  const trimmedValue = inputValue.trim();

  if (inputValue !== trimmedValue) {
    // El valor tiene espacios en blanco al inicio o al final
    this.importacionForm.controls['origen'].setErrors({ 'spaces': true });
  } else {
    this.importacionForm.controls['origen'].setErrors(null);
  }
}
validatePro(event: any) {
  const inputValue = event.target.value;
  const trimmedValue = inputValue.trim();

  if (inputValue !== trimmedValue) {
    // El valor tiene espacios en blanco al inicio o al final
    this.importacionForm.controls['procedencia'].setErrors({ 'spaces': true });
  } else {
    this.importacionForm.controls['procedencia'].setErrors(null);
  }
}
validateNotas(event: any) {
  const inputValue = event.target.value;
  const trimmedValue = inputValue.trim();

  if (inputValue !== trimmedValue) {
    // El valor tiene espacios en blanco al inicio o al final
    this.importacionForm.controls['notas_adicionales'].setErrors({ 'spaces': true });
  } else {
    this.importacionForm.controls['notas_adicionales'].setErrors(null);
  }
}
validateProveedor(event: any) {
  const inputValue = event.target.value;
  const trimmedValue = inputValue.trim();

  if (inputValue !== trimmedValue) {
    // El valor tiene espacios en blanco al inicio o al final
    this.importacionForm.controls['comprador'].setErrors({ 'spaces': true });
  } else {
    this.importacionForm.controls['comprador'].setErrors(null);
  }
}
ngOnInit() {
  this.dialogRef.updateSize('70%', '65%')
  this.dialogRef.disableClose = true

  this.idTramite = this.data?.idTramite
  this.tipoTramite = this.data?.tipoTramite
  this.subtipoTramite = this.data?.subtipoTramite
  this.idEstado = this.data?.idEstado
  this.idRegistro = this.data?.idRegistro
  this.razonSocial = this.data?.razonSocial

  this.DOCUMENTO_OBLIGATORIO = Parametricas.OBLIGATORIO
  this.IdAutorizacionPrevia = this.rutaActiva.snapshot.params.datos;
  if (this.IdAutorizacionPrevia != null && this.IdAutorizacionPrevia != undefined && this.IdAutorizacionPrevia != 0) {
    this.buscarDatos()
  }
  if (this.id_autorizacion_previa == null || this.id_autorizacion_previa == undefined || this.id_autorizacion_previa == 0 || this.id_autorizacion_previa == '') {
    this.activar = false
  }

  this.getPaises()
  this.getAduanas()
  // this.getDocumentosGeneral()

}

async buscarDatos() {
  const params = {
    dataEdit: this.IdAutorizacionPrevia
  }
  Swal.fire("", "Espere por favor", "info");
  Swal.fire({
    title: "Espere por favor...",
    allowOutsideClick: false,
  });
  Swal.showLoading()
  try {
    var respuesta = await lastValueFrom(this.autorizacionPreviaImportacionesService.buscarDatos(params))
  } catch (error) {      
    Swal.fire('', error.error.mensaje, 'warning')     
    this.router.navigateByUrl('/licencias-previas/bandeja-licencias-previas/lista')
    return;
    
  }
  Swal.close();
  if (!respuesta.datos.datosEditBD[0] || respuesta.datos.datosEditBD[0] === '' || !respuesta.datos.sustancias || respuesta.datos.sustancias === '' || !respuesta.datos.datosBD || respuesta.datos.datosBD === '' || !respuesta.datos.documentos || respuesta.datos.documentos ==='') {
    this.router.navigateByUrl('/licencias-previas/bandeja-licencias-previas/lista')
  }
  if (respuesta.datos.datosEditBD[0].id_estado!=405 ) {
    this.router.navigateByUrl('/licencias-previas/bandeja-licencias-previas/lista')
  }

  this.datosMod = respuesta.datos.datosEditBD[0]
  this.datosSustancia = respuesta.datos.sustancias
  this.datosDocumentos=respuesta.datos.documentos
  this.datosSusReg = respuesta.datos.datosBD
  this.comprador = this.datosMod?.comprador
  this.id_autorizacion_previa = this.datosMod?.id_autorizacion_previa
  this.id_tramite = this.datosMod?.id_tramite
  this.activar = true
  this.importacionForm.controls['comprador'].setValue(this.datosMod?.comprador)
  this.importacionForm.controls['id_pais_origen'].setValue(this.datosMod?.id_pais_origen)
  this.importacionForm.controls['origen'].setValue(this.datosMod?.origen)
  this.importacionForm.controls['id_pais_ultima_procedencia'].setValue(this.datosMod?.id_pais_ultima_procedencia)
  this.importacionForm.controls['procedencia'].setValue(this.datosMod?.procedencia)
  this.importacionForm.controls['id_aduana'].setValue(this.datosMod?.id_aduana)
  this.importacionForm.controls['dias_vigencia'].setValue(this.datosMod?.dias_vigencia)
  this.importacionForm.controls['notas_adicionales'].setValue(this.datosMod?.notas_adicionales);
  ////////buscar sustancia del la autorizacion
  var capacidad_cant_envase = 0;
  this.datosSustancia.forEach(sustancia => {
    const envases11 = sustancia?.envases
    var cadenaenvases = ''
    envases11.forEach(env => {
      const envases1 = env

      if (!sustancia.envases) {
        this.capacidad_cant_envase2 = 0;
      }
      capacidad_cant_envase += (envases1.cantidad_envase) * (envases1.capacidad) * (envases1.factor_conversion)
      if (cadenaenvases.length == 0) {
        cadenaenvases = envases1.cantidad_envase.toLocaleString("es-BO") + ' ' + envases1.nombre_envase + ' de ' + envases1.capacidad + '' + envases1.abreviacion

      } else {
        cadenaenvases += ', ' + envases1.cantidad_envase.toLocaleString("es-BO") + ' ' + envases1.nombre_envase + ' de ' + envases1.capacidad + '' + envases1.abreviacion
      }      
      this.capacidad_cant_envase2 = this.capacidad_cant_envase1 + (capacidad_cant_envase)
    

    })
    const sustanciaFormGroup = this.formBuilder.group({
      id_sustancia: [sustancia.id_sustancia, null],
      sustancia_primaria: [sustancia.nombre, null],
      sustancia_nombre: [sustancia.sinonimo, null],
      tipo_sustancia: [sustancia.primaria, null],
      unidad_medida: [sustancia.nombre_um, null],
      abreviacion: [sustancia.abreviacion, null],
      id_unidad_medida: [sustancia.id_unidad_medida, null],
      cantidad_solicitada: [sustancia.cantidad_solicitada],
      cantidad_registro: [this.datosSusReg?.cantidad, null],
      masa_unidad_medida: [sustancia.masa_unidad_medida, null],
      porcentaje: [sustancia.porcentaje, null],
      saldo_kardex: [sustancia.saldo_kardex],
      cantidad_envases: [this.capacidad_cant_envase2, null],
      tipo_envase: [cadenaenvases, null],
      utilizacion: [sustancia.utilizacion,[Validators.required,Validators.pattern(/^(?!\s)(?!.*\s$)(?!.*').*$/),Validators.maxLength(250)]],
      envases: [sustancia.envases, []]

    });
    this.sustanciasArray.push(sustanciaFormGroup);

  })
  this.monto = this.montop + this.sustanciasArray.length;
  this.datosDocumentos.forEach(doc => {     
        const documentoFormGroup = this.formBuilder.group({
         id_tipo_documento_general: [doc.id_tipo_documento_general,null],
         nombre_documento: [doc.descripcion,null],
         descripcion:[doc.descripcion, null],
         identificador: [doc.identificador, [Validators.required,Validators.pattern(/^(?!\s)(?!.*\s$)(?!.*').*$/),Validators.maxLength(50)]],
         fecha_emision: [doc.fecha_emision, Validators.required],
         fecha_fin: [doc.fecha_fin,null],
         archivo: [doc.descripcion, null],   
         base64: [doc.base64?'data:application/pdf;base64,':null, Validators.required],         
         nuevo_archivo: [false, null],
         adjuntar_archivo_existente: [false, null],     
       })  
       documentoFormGroup.patchValue({
         nombre_documento: doc.descripcion,
         descripcion:doc.descripcion,
         identificador: doc.identificador,
         fecha_emision: doc.fecha_emision,
         fecha_fin:doc.fecha_fin,
          base64: doc.base64,
         nuevo_archivo: true,
         adjuntar_archivo_existente: false
       });  
       this.documentos.push(documentoFormGroup);
     })
  return
}
onSelectionChangedOrigen({ value }) {
  if (value == 0) {
    this.importacionForm.get('origen').enable();
  } else {
    this.importacionForm.get('origen').disable();
  }

}
onSelectionChangedProcedencia({ value }) {
  if (value == 0) {
    this.importacionForm.get('procedencia').enable();
  } else {
    this.importacionForm.get('procedencia').disable();
  }

}
getPaises() {
  this.paisService.obtenerTodos().subscribe({
    next: data => {
      this.paises = data.datos.paises;
    }
  });
}
getAduanas() {
  this.aduanaService.obtenerTodos().subscribe({
    next: data => {
      this.aduanas = data.datos;
    }
  });
}
async getDocumentos(result) {
    try {
      var resp = await lastValueFrom(this.documentosAdicionalesService.obtenerDocumentosAdicionales(this.idTramite))
    let documentos = resp.datos.documentos
    for (let index = 0; index < documentos.length; index++) {

      if(documentos[index].id_rep_legal == null ){
        this.datosDocumentos = documentos[index]

        const documentoFormGroup = this.formBuilder.group({
          id_tipo_documento_general: [this.datosDocumentos.id_tipo_documento_general,null],
          nombre_documento: [this.datosDocumentos.descripcion,null],
          descripcion:[this.datosDocumentos.descripcion, null],
          identificador: [this.datosDocumentos.identificador, [Validators.required,Validators.pattern(/^(?!\s)(?!.*\s$)(?!.*').*$/),Validators.maxLength(50)]],
          fecha_emision: [this.datosDocumentos.fecha_emision, Validators.required],
          fecha_fin: [this.datosDocumentos.fecha_fin,null],
          archivo: [this.datosDocumentos.descripcion, null],   
          base64: [this.datosDocumentos.base64?'data:application/pdf;base64,':null, Validators.required],         
          nuevo_archivo: [false, null],
          adjuntar_archivo_existente: [false, null],     
        })  
        documentoFormGroup.patchValue({
          nombre_documento: this.datosDocumentos.descripcion,
          descripcion:this.datosDocumentos.descripcion,
          identificador: this.datosDocumentos.identificador,
          fecha_emision: this.datosDocumentos.fecha_emision,
          fecha_fin:this.datosDocumentos.fecha_fin,
           base64: this.datosDocumentos.base64,
          nuevo_archivo: true,
          adjuntar_archivo_existente: false
        });  
        this.documentos.push(documentoFormGroup);

     }
    }
    
  } catch (error) {
    Swal.fire({ text: error.error.mensaje, icon: 'warning', confirmButtonText: 'Aceptar' })
    return
  }
      
}

async verDocumentoAdj(documentoFormGroup: FormGroup) {
 
    this.dialog.open(VisorPdfDialogoComponent, {disableClose: true,
     data: { archivoBase64: documentoFormGroup.value.base64, disableCancelButton: true }
   }) 
 }

 async verDocumentoCorrespondeAdj(documentoCorrespondeFormGroup: FormGroup) {

  this.dialog.open(VisorPdfDialogoComponent, {disableClose: true,
   data: { archivoBase64: documentoCorrespondeFormGroup.value.base64, disableCancelButton: true }
 }) 
}
async eliminarDocumento(index):Promise<void>{  
var respuesta = await Swal.fire({
  title: "¿Está seguro eliminar el registro?",
  text: "¡No podrás revertir esto! ",
  icon: "warning",
  showCancelButton: true,
  confirmButtonColor: "#aaa",
  cancelButtonColor: "#aaa",
  confirmButtonText: "Si, eliminar",
  cancelButtonText: "No, cancelar",
  showCloseButton: true
})
if (!respuesta.isConfirmed) return
Swal.fire("", "Espere por favor", "warning");
Swal.fire({
  title: "Espere por favor...",
  html: "Eliminando registro",
  allowOutsideClick: false,
});
Swal.showLoading();
this.documentosCorresponde.removeAt(index)
this.conteoDocumentosCorres = 0
Swal.close();
Swal.fire(
  "¡Eliminado!",
  "El registro ha sido eliminado correctamente",
  "success"
);
}
async verDocumento(documentoFormGroup: FormGroup) {
 

  // Si aun no se cargo ningun archivo (Significa que esta en null)
  /* if (!documentoFormGroup.value.base64)
    return */
    Swal.fire("", "Espere por favor", "info");
    Swal.fire({
      title: "Espere por favor...",       
      allowOutsideClick: false,
    });
    Swal.showLoading()
  let IdAutorizacionPrevia = documentoFormGroup.value.id_autorizacion_previa

  if (documentoFormGroup.value.adjuntar_archivo_existente)
    IdAutorizacionPrevia = documentoFormGroup.value.IdAutorizacionPrevia

  // Si ya se cargo el archivo pero aun no se trajo el archivo del backend (Modo: edicion)
  //  if (documentoFormGroup.value.base64 == 'data:application/pdf;base64,') {
 
  try {
    var documentoResp = await lastValueFrom(this.autorizacionPreviaImportacionesService.obtenerDocumentoRA(IdAutorizacionPrevia))
  } catch (error) {
   
    Swal.fire({ text: error.error.mensaje, icon: 'warning', confirmButtonText: 'Aceptar' })
    return
  }
  Swal.close();
  documentoFormGroup.patchValue({
    base64: documentoResp.datos.documento.base64
  })
  //  }

  this.dialog.open(VisorPdfDialogoComponent, {disableClose: true,
    data: { archivoBase64: documentoFormGroup.value.base64, disableCancelButton: true }
  })
}
verArchivo(documentoFormGroup: FormGroup){
  
 //return documentoFormGroup.value.base64 !=null && documentoFormGroup.value.id_tipo_documento_general != 12
  return documentoFormGroup.value.base64 !=null 
  ? true
  : false;
  return documentoFormGroup.value.base64 !=null 
  ? true
  : false;
  }
  verArchivoCorresponde(documentoCorrespondeFormGroup: FormGroup){
 
    return documentoCorrespondeFormGroup.value.base64 !=null 
    ? true
    : false;
    }
 verArchivoExcel(documentoFormGroup: FormGroup) {
    
    return documentoFormGroup.value.id_tipo_documento_general == 14
    ? true
    : false;
    }

verUltimaRA(documentoFormGroup: FormGroup) {
 // return documentoFormGroup.value.id_tipo_documento_general == 38 //////DECLARACIÓN DE MERCANCÍA DE IMPORTACIÓN
/*   return documentoFormGroup.value.id_tipo_documento_general == 12  //////DECLARACIÓN DE MERCANCÍA DE IMPORTACIÓN
    ? true
    : false; */
}
verSinRA(documentoFormGroup: FormGroup) {
 
  return documentoFormGroup.value.id_tipo_documento_general == 12 /////DECLARACIÓN DE MERCANCÍA DE IMPORTACIÓN
    ? false
    : true;
}
agregarSustancias(): void {
  Swal.fire("", "Espere por favor", "info");
  Swal.fire({
    title: "Espere por favor...",       
    allowOutsideClick: false,
  });
  Swal.showLoading()
  const dialogRef = this.dialog.open(AgregarSustanciasComponent, {
    disableClose: true,

    data: {
      sustanciasArray: this.sustanciasArray.value,
      sustanciasAgregadas: [],
      actividad: 1, ///importa
      subtipoTramite: this.subtipoTramite,
      tipoTramite : this.tipoTramite,
      idTramite: this.idTramite,
      idRegistro : this.idRegistro,
      tipoAP: this.data.tipo
    }
  });
  Swal.close();
  dialogRef.afterClosed().subscribe(result => {
    if(this.idTramite){
      this.getDocumentos(result)
    }
    if (result) {
      result.forEach(async sustancia => {
        const sustanciaFormGroup = this.formBuilder.group({
          id_sustancia: [sustancia.id_sustancia, null],
          tipo: [sustancia.tipo, null],
          codigo: [sustancia.codigo, null],
          sustancia_primaria: [sustancia.nombre_sustancia_primaria, null],
          sustancia_nombre: [sustancia.nombre_sustancia, null],
          tipo_sustancia: [sustancia.primaria, null],
          unidad_medida: [sustancia.nombre_unidad_medida, null],
          abreviacion: [sustancia.abreviacion, null],
          id_unidad_medida: [sustancia.id_unidad_medida, null],
          cantidad_solicitada: ['',  [Validators.required,Validators.pattern(/^(?!\s)(?!.*\s$)(?!.*').*$/),Validators.maxLength(30)]],
          cantidad_registro: [sustancia.cantidad, null],
          masa_unidad_medida: [sustancia.masa_unidad_medida, null],
          porcentaje: [sustancia.porcentaje, null],
          saldo_kardex: ['', [Validators.required,Validators.pattern(/^(?!\s)(?!.*\s$)(?!.*').*$/),Validators.maxLength(30)]],
          cantidad_envases: [0, null],
          tipo_envase: ['', null],
          utilizacion: ['',[Validators.required,Validators.required,Validators.pattern(/^(?!\s)(?!.*\s$)(?!.*').*$/),Validators.maxLength(250)]],
          envases: [[], null]
        });
        this.sustanciasArray.push(sustanciaFormGroup);
        /*    sustanciaFormGroup.patchValue({         
             documentos: this.documentos
           });  */
        this.obtenerUltimaRAxSustancia(sustanciaFormGroup.value)
        this.conteoDocumentosTotal++;
        this.conteoDocumentosCorres++;
        if(!this.idTramite){
          if (this.conteoDocumentosCorres === 1) {           
            this.getDocumentosCorresponde()
          }
          if (sustanciaFormGroup.value.tipo == 1) {////HIDROCARBUROS         
           this.conteo++;
          if (this.conteo === 1) {             
            const paramsTramite = {
              tipoTramite: 12 ///RESOLUCIÓN ADMINISTRATIVA DE LA ANH
            }
            try {
              var resp = await lastValueFrom(this.tipoDocumentoGeneralService.obtenerTodos(paramsTramite))
              this.docRequisitos = resp.datos.documentos
              this.docRequisitos.forEach(doc => {
                this.agregarDocumentoObligatorio(doc, sustanciaFormGroup.value.id_sustancia, sustanciaFormGroup.value.id_autorizacion_previa)
              })
            } catch (error) {
              Swal.fire("", error.error.mensaje, "error");
              return;
            }

          }
         }
        }
        /* else{
          this.conteo++;
          if (this.conteo === 1) {
            this.getDocumentosCorresponde()
          }
        } */

      })
      //monto por sustancia
      this.monto = this.montop + this.sustanciasArray.length;
      if (result !== undefined) {
        this.sustanciasAP = result;
      }
    }

  })
  this.habilitado = false
}

async getDocumentosCorresponde() {

  //   const paramsTramite = {
  //     tipoTramite: 14 ///import y prod cuando corresponda
  //   }
  //   try {
  //     var resp = await lastValueFrom(this.tipoDocumentoGeneralService.obtenerTodos(paramsTramite))
  //   } catch (error) {
  //     Swal.fire("", error.error.mensaje, "error");
  //     return;
  //   }
  //  console.log('documentos corresponde', resp);
   
  //   this.docRequisitosCorresponde = resp.datos.documentos
  //   this.docRequisitosCorresponde.forEach(doc => {
  //     this.agregarDocumentoCorresponde(doc)
  //   })

    const paramsDIM = {
      tipoTramite: 11 ///import y prod cuando corresponda
    }
    try {
      var resp = await lastValueFrom(this.tipoDocumentoGeneralService.obtenerTodos(paramsDIM))
    } catch (error) {
      Swal.fire("", error.error.mensaje, "error");
      return;
    }
   console.log('documentos corresponde', resp);
   
    this.docRequisitosCorresponde = resp.datos.documentos
    this.docRequisitosCorresponde.forEach(doc => {
      this.agregarDocumentoCorresponde(doc)
    })

    const paramsControl = {
      tipoTramite: 13 ///import y prod cuando corresponda
    }
    try {
      var resp = await lastValueFrom(this.tipoDocumentoGeneralService.obtenerTodos(paramsControl))
    } catch (error) {
      Swal.fire("", error.error.mensaje, "error");
      return;
    }

   console.log('documentos corresponde', resp);
   
    this.docRequisitosCorresponde = resp.datos.documentos
    this.docRequisitosCorresponde.forEach(doc => {
      this.agregarDocumentoCorresponde(doc)
    })
    console.log(this.docRequisitosCorresponde);
    
  }

  agregarDocumentoCorresponde(doc: any = null, id_sustancia: any = null, id_autorizacion_previa: any = null) {
    const documentoCorrespondeFormGroup = this.formBuilder.group({
      id_tipo_documento_general: [doc.id_tipo_documento_general, null],
      nombre_documento: [doc.nombre_documento, null],
      descripcion: [doc.nombre_documento, null],
      identificador: ['', [Validators.required,Validators.pattern(/^(?!\s)(?!.*\s$)(?!.*').*$/),Validators.maxLength(50)]],
      fecha_emision: ['', Validators.required],
      fecha_fin: [null],
      archivo: ['', null],
      base64: ['data:application/pdf;base64,', Validators.required],
      nuevo_archivo: [false, null],
      adjuntar_archivo_existente: [false, null],
      id_sustancia: [id_sustancia, null],
      id_autorizacion_previa: [id_autorizacion_previa, null]
    })
    this.documentosCorresponde.push(documentoCorrespondeFormGroup)

  }


verBoton(): boolean {
  return this.habilitado ? false : true;
}

async adjuntarDocumento(documentoFormGroup: FormGroup, index: number) {
 
  let IdAutorizacionPrevia = documentoFormGroup.value.id_autorizacion_previa

  try {
    // Verificamos si el documento adicional ya existe en la DB
    var documentoResp = await lastValueFrom(this.autorizacionPreviaImportacionesService.obtenerDocumentoRA(IdAutorizacionPrevia))
  } catch (error) {
    return
  }

  documentoFormGroup.patchValue({
    archivo: documentoFormGroup.value.nombre_documento,
    base64: documentoResp.datos.documento.base64,
    nuevo_archivo: true,
    adjuntar_archivo_existente: false
  })
}

/**
* funcion para buscar los datos por las sustancias y agregar sus documentos si existe una resolucion administrativa
*/
async obtenerUltimaRAxSustancia(data) {
  
  const paramsSus = {
    id_sustancia: data.id_sustancia,
  }  //para obtener la ultima resolucion por sustancia      
  console.log(paramsSus);
  
  try {
    var respuesta = await lastValueFrom(this.autorizacionPreviaImportacionesService.obtenerUltimaRAxSustancia(paramsSus))
    this.ultimaRA = respuesta.datos?.datosSUS[0]
    this.raDocumento = respuesta.datos?.documento
    
  } catch (error) {
    Swal.fire("", error.error.mensaje, "error");
    return;
  }
}

/**
 * Método que permite la descarga de la plantilla del formulario de control
 */    
downloadExcelFormularioControl(): void {
  const fileName = `FormularioControlImportacion.xlsx`;
  this.autorizacionPreviaImportacionesService.getExcelFormularioControl().subscribe({
    next: response => {
      this.manageExcelFile(response, fileName);
    },
    error: () => { }
  });
}
/**
 * Método que permite gestionar el archivo excel
 */   
manageExcelFile(response: any, fileName: string): void {
const dataType = response.type;
const binaryData = [];
binaryData.push(response);

const filtePath = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
const downloadLink = document.createElement('a');
downloadLink.href = filtePath;
downloadLink.setAttribute('download', fileName);
document.body.appendChild(downloadLink);
downloadLink.click();
}
isPDFFile(documentoFormGroup: FormGroup): boolean {
  const archivoControl = documentoFormGroup;

  if (archivoControl.value && archivoControl.value.archivo) {
    const fileType = archivoControl.value.archivo.toLowerCase();
    return fileType === 'application/pdf';
  }
  return false;
}
/*  eliminaSustancia555(index: number,id_sustancia:number): void {
   this.sustanciasArray.removeAt(index);
   //this.tablaSeleccionados.renderRows();
   this.monto = index;

 } */
async eliminaSustancia(index, sustancia): Promise<void> {
  var respuesta = await Swal.fire({
    title: "¿Está seguro eliminar el registro?",
    text: "¡No podrás revertir esto! ",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#aaa",
    cancelButtonColor: "#aaa",
    confirmButtonText: "Si, eliminar",
    cancelButtonText: "No, cancelar",
    showCloseButton: true
  })
  if (!respuesta.isConfirmed) return
  Swal.fire("", "Espere por favor", "warning");
  Swal.fire({
    title: "Espere por favor...",
    html: "Eliminando registro",
    allowOutsideClick: false,
  });
  Swal.showLoading();
  this.sustanciasArray.removeAt(index)
  this.monto = this.montop + this.sustanciasArray.length;
  for (let index = this.documentos.length - 1; index >= 0; index--) {
    const element = this.documentos.value[index];
    if (element.id_sustancia == sustancia) {
      this.documentos.removeAt(index);
      this.conteo = 0

    }
  }
  Swal.close();
  Swal.fire(
    "¡Eliminado!",
    "El registro ha sido eliminado correctamente",
    "success"
  );
  this.habilitado = true;
}

async adjuntarArchivoCorresponde(event: any, documentoCorrespondeFormGroup: FormGroup, index: number) {
 

  if (event.target.files.length > 0) {
    this.nombreArchivo == event.target.files[0].name
    const size = event.target.files[0].size
    const fileType = event.target.files[0].type
    const fileName = event.target.files[0].name

    if (fileType !== 'application/pdf') {
      Swal.fire({ text: '¡Solo es permitido archivos en formato .pdf!', icon: 'warning', confirmButtonText: 'Aceptar' })
      return
    }
    if (size > Parametricas.maxFileSize) {
      Swal.fire({ text: '¡El tamaño del archivo no puede exceder de 1Mb!', icon: 'warning', confirmButtonText: 'Aceptar' })
      return
    }
    const reader = new FileReader()
    reader.readAsDataURL(event.target.files[0])
    reader.onload = async (ev: any) => {
      // this.archivoCargado = ev.target.result;
      let docBase64 = ev.target.result
      //this.archivoURL = this.sanitizer.bypassSecurityTrustResourceUrl(docBase64);
      //Quitamos la cabecera del tipo de archivo data:application/pdf;base64,
      docBase64 = docBase64.substring(28)
      let archivoBinario = window.atob(docBase64)
      //// Verificamos si el archivo esta dañado
      if (archivoBinario.lastIndexOf("%PDF-") !== 0 || archivoBinario.lastIndexOf("%%EOF") === -1) {
        Swal.fire({ text: 'El archivo esta dañado, por favor revise y vuelva a intentarlo', icon: 'warning', confirmButtonText: 'Aceptar' })
        return
      }
      var resp = await Swal.fire({
        title: '¿Está seguro de adjuntar documento? ' + fileName + '?',
        icon: "question",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Aceptar",
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        reverseButtons: true
      });
      if (!resp.isConfirmed) return;
      documentoCorrespondeFormGroup.patchValue({
        archivo: fileName,
        base64: docBase64,
        nuevo_archivo: true,
        adjuntar_archivo_existente: false,

      })
      this.archivosAdjuntadosCorresponde[index] = true;

      Swal.fire({ text: 'Documento guardado correctamente', icon: 'success', confirmButtonText: 'Aceptar' })
    }
  }
}
agregarEnvasesLote(sustanciaFormGroup: FormGroup): void {
  Swal.fire("", "Espere por favor", "info");
  Swal.fire({
    title: "Espere por favor...",       
    allowOutsideClick: false,
  });
  Swal.showLoading()
  const masaUnidadMedida = sustanciaFormGroup.get('masa_unidad_medida').value;
  const envases = sustanciaFormGroup.value.envases
  const dialogRef = this.dialog.open(AgregarEnvasesLoteComponent, {
    disableClose: true,
    data: {
      envases: envases,
      masa_unidad_medida: masaUnidadMedida
    },
    width: '60%',
    height: '80%',

  });
  Swal.close();
  dialogRef.afterClosed().subscribe(result => {
    if (!result)
      return;

    this.ArrayEnvase = result.envaseSustanciaForm.envases;

    var tipo_envase = "";
    var capacidad_cant_envase = 0;
    var envasesSeleccionados = [];

    this.ArrayEnvase.forEach((elemEnvase: any) => {
      capacidad_cant_envase += (elemEnvase.cantidad_envase) * (elemEnvase.envase.capacidad) * (elemEnvase.envase.factor_conversion);
      if (tipo_envase.length == 0) {
        tipo_envase = elemEnvase.cantidad_envase.toLocaleString("es-BO") + ' ' + elemEnvase.envase.nombre_envase + ' de ' + elemEnvase.envase.capacidad + ' ' + elemEnvase.envase.abreviacion;
      } else {
        tipo_envase += ', ' + elemEnvase.cantidad_envase.toLocaleString("es-BO") + ' ' + elemEnvase.envase.nombre_envase + ' de ' + elemEnvase.envase.capacidad + ' ' + elemEnvase.envase.abreviacion;
      }

      const envase = {
        id_envase: elemEnvase.envase.id_envase,
        cantidad_envase: elemEnvase.cantidad_envase
      };

      envasesSeleccionados.push(envase);
    });

    sustanciaFormGroup.patchValue({
      tipo_envase: tipo_envase,
      cantidad_envases: capacidad_cant_envase,
      envases: envasesSeleccionados
    });

    if (!!!result) {
      return
    }
  });
}

async adjuntarArchivo(event: any, documentoFormGroup: FormGroup, index: number) {
 

  if (event.target.files.length > 0) {
    this.nombreArchivo == event.target.files[0].name
    const size = event.target.files[0].size
    const fileType = event.target.files[0].type
    const fileName = event.target.files[0].name

    if (fileType !== 'application/pdf') {
      Swal.fire({ text: '¡Solo es permitido archivos en formato .pdf!', icon: 'warning', confirmButtonText: 'Aceptar' })
      return
    }
    if (size > Parametricas.maxFileSize) {
      Swal.fire({ text: '¡El tamaño del archivo no puede exceder de 1Mb!', icon: 'warning', confirmButtonText: 'Aceptar' })
      return
    }
    const reader = new FileReader()
    reader.readAsDataURL(event.target.files[0])
    reader.onload = async (ev: any) => {
      // this.archivoCargado = ev.target.result;
      let docBase64 = ev.target.result
      //this.archivoURL = this.sanitizer.bypassSecurityTrustResourceUrl(docBase64);
      //Quitamos la cabecera del tipo de archivo data:application/pdf;base64,
      docBase64 = docBase64.substring(28)
      let archivoBinario = window.atob(docBase64)
      //// Verificamos si el archivo esta dañado
      if (archivoBinario.lastIndexOf("%PDF-") !== 0 || archivoBinario.lastIndexOf("%%EOF") === -1) {
        Swal.fire({ text: 'El archivo esta dañado, por favor revise y vuelva a intentarlo', icon: 'warning', confirmButtonText: 'Aceptar' })
        return
      }
      var resp = await Swal.fire({
        title: '¿Está seguro de adjuntar documento? ' + fileName + '?',
        icon: "question",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Aceptar",
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        reverseButtons: true
      });
      if (!resp.isConfirmed) return;
      documentoFormGroup.patchValue({
        archivo: fileName,
        base64: docBase64,
        nuevo_archivo: true,
        adjuntar_archivo_existente: false,

      })
      this.archivosAdjuntados[index] = true;

      Swal.fire({ text: 'Documento guardado correctamente', icon: 'success', confirmButtonText: 'Aceptar' })
    }
  }
}
agregarDocumentoObligatorio(doc: any = null, id_sustancia: any = null, id_autorizacion_previa: any = null) {
  const documentoFormGroup = this.formBuilder.group({
    id_tipo_documento_general: [doc.id_tipo_documento_general, null],
    nombre_documento: [doc.nombre_documento, null],
    descripcion: [doc.nombre_documento, null],
    identificador: ['', [Validators.required,Validators.pattern(/^(?!\s)(?!.*\s$)(?!.*').*$/),Validators.maxLength(50)]],
    fecha_emision: ['', Validators.required],
    fecha_fin: [null],
    archivo: ['', null],
    base64: ['data:application/pdf;base64,', Validators.required],
    nuevo_archivo: [false, null],
    adjuntar_archivo_existente: [false, null],
    id_sustancia: [id_sustancia, null],
    id_autorizacion_previa: [id_autorizacion_previa, null]
  })
  this.documentos.push(documentoFormGroup)
}

agregarEnvases(): void {
  Swal.fire("", "Espere por favor", "info");
Swal.fire({
  title: "Espere por favor...",       
  allowOutsideClick: false,
});
Swal.showLoading()
  const dialogRef = this.dialog.open(AgregarEnvasesComponent, {
    disableClose: true,

  })
  Swal.close();
  dialogRef.afterClosed().subscribe(result => { });
}

reiniciaFormSustancias(formArray: FormArray) {
  while (formArray.length !== 0) {
    formArray.removeAt(0);
  }
}

validarFormatoNumero(event: any) {
  let key;
  if (event.type === 'paste') {
    key = event.clipboardData.getData('text/plain');

  } else {
    key = event.keyCode;
    key = String.fromCharCode(key);
  }
  const regex = /[0-9]|\./;
  if (!regex.test(key)) {
    event.returnValue = false;
    if (event.preventDefault) {
      event.preventDefault();
    }
  }
}
async guardarImportacion() {
  if (this.IdAutorizacionPrevia == null || this.IdAutorizacionPrevia == 0) {

    const params = {
      importacionForm: this.importacionForm.value,
      sustanciasForm: this.sustanciasForm.value,
      sustanciasArray: this.sustanciasArray.value,
      formDocumentos: this.formDocumentos.value,
      documentos: this.documentos.value,
      formDocumentosCorresponde:this.formDocumentosCorresponde.value,
      documentosCorresponde:this.documentosCorresponde.value,
      tipo_personeria:this._usuarioService.usuario.tipRep,
      id_registro: this.idRegistro,
      solicitante: this.razonSocial,
      id_tramite: this.idTramite,
      id_tipo_tramite : this.tipoTramite,
      id_subtipo_tramite : this.subtipoTramite
    }
  
    if (this.importacionForm.invalid) {
      Swal.fire('', '¡Complete campos requeridos, por favor!', 'warning');
      return;
    }
    if (this.sustanciasArray.invalid) {
      Swal.fire('', '¡Complete campos requeridos de SUSTANCIAS, por favor!', 'warning');
      return;
    }

    /* if (this.sustanciasArray.length < 1 || this.sustanciasArray.length == undefined) {
      Swal.fire('', '¡No se puede crear una autorización previa SIN SUSTANCIAS!', 'warning');
      return;
    } */
    //2.-validamos que se tengan sustancias válidas (cantidad mayor a cero)
    var sustanciasValidas: any = []
    for (let i = 0; i < this.sustanciasArray.value.length; i++) {
      let sustancia = this.sustanciasArray.value[i]
     
      if (sustancia.cantidad_solicitada > 0) {
        sustanciasValidas.push(sustancia)
      }
      
      let mes_vigencia=(this.importacionForm.value.dias_vigencia)/30
      let cantidadXmes=sustancia.cantidad_registro*mes_vigencia
      //console.log('sustancia.cantidad_solicitada',sustancia.cantidad_solicitada)
      //console.log('cantidadXmes',cantidadXmes)
      if(sustancia.cantidad_solicitada > cantidadXmes){
      Swal.fire('', "¡La cantidad solicitada (" + sustancia.cantidad_solicitada + ")" + " de la sustancia " + sustancia.sustancia_nombre + ", supera lo permitido del tiempo de vigencia ("+mes_vigencia+" meses) por la cantidad de su registro (" + sustancia.cantidad_registro + "),  el cáculo es: ("+cantidadXmes+"!)", 'warning');
       return
     }
    
/*         if(sustancia.cantidad_solicitada > sustancia.cantidad_registro){
        Swal.fire('', "¡La cantidad solicitada (" + sustancia.cantidad_solicitada + ")" + " de la sustancia " + sustancia.sustancia_nombre + " es superior a lo permitido (" + sustancia.cantidad_registro + ")!", 'warning');
        return
      } */
    }
    if (sustanciasValidas.length == 0) {
      Swal.fire('', '¡La cantidad solicitada no es válida!', 'warning');
      return;
    }
    //3.- Validamos envases de las sustanciass, si se definieron envases->verificamos que la sustanciaFormGroup.cantidad_envase sea = cantidad solicitada
    for (let i = 0; i < this.sustanciasArray.value.length; i++) {
      //En caso de tener envases definidos-> validamos la cantidad
      if (this.sustanciasArray.value[i].envases.length > 0) {
        if ( Number((this.sustanciasArray.value[i].cantidad_envases).toFixed(5)) != Number((this.sustanciasArray.value[i].cantidad_solicitada).toFixed(5)) ) {
          Swal.fire('', 'La cantidad solicitada ' + '(' + Number((this.sustanciasArray.value[i].cantidad_solicitada).toFixed(5)) + ') ' + 'de la sustancia ' + this.sustanciasArray.value[i].sustancia_nombre + '(' + this.sustanciasArray.value[i].sustancia_primaria + ')' + ' no coincide con la cantidad de los envases (' + Number((this.sustanciasArray.value[i].cantidad_envases).toFixed(5)) + ')', 'warning');
          return
        }
      }
    }
    if(!this.idTramite) {
    var respuesta = await Swal.fire({
      title: "¿Desea guardar el registro?", html: "<H2>Costo de trámite: " + this.monto * 80 + " Bs. </H2>", icon: "question",
      showCancelButton: true, confirmButtonText: "Si, guardar",
      cancelButtonText: "No, cancelar",
      showCloseButton: true
    })
    if (!respuesta.isConfirmed) return
    Swal.fire("", "Espere por favor", "info");
    Swal.fire({
      title: "Espere por favor...",
      html: "¡Almacenando Registro!",
      allowOutsideClick: false,
    });
    Swal.showLoading()
    Swal.close()
    Swal.fire({
      title: "¡Guardado!", text: "Registro exitoso.", icon: "success",
      confirmButtonText: 'Aceptar', showCloseButton: true
    })
  } else   {
    try {
      let result = await lastValueFrom(this.autorizacionPreviaImportacionesService.crearPrimeraImportacion(params));
      console.log(result,'resultado crear 1ra importacion');
    } catch (error) {
      Swal.fire('', error.error.mensaje, 'warning');
      return
    }
    Swal.close()
    Swal.fire({
      title: "¡Guardado!", text: "Registro exitoso.", icon: "success",
      confirmButtonText: 'Aceptar', showCloseButton: true
    })
    }
  }
this.dialogRef.close({
  success: true
})

}

async retornar() {
  var respuesta = await Swal.fire({
    title: "¿Desea cancelar la solicitud?", text: "¡No podrás revertir esto! ", icon: "question",
    showCancelButton: true, confirmButtonText: "Si.",
    cancelButtonText: "No.",
    showCloseButton: true
  })
  if (!respuesta.isConfirmed) return
  Swal.fire("", "Espere por favor", "info");
  Swal.fire({
    title: "Espere por favor...",
    allowOutsideClick: false,
  });
  Swal.showLoading()
  this.router.navigateByUrl('/licencias-previas/bandeja-licencias-previas/lista');
  Swal.close()
}
agregarDocumento(event: any, sustanciaFormGroup: FormGroup) {
  
  if (event.target.files.length > 0) {
    const size = event.target.files[0].size
    const fileType = event.target.files[0].type
    const fileName = event.target.files[0].name
    if (fileType !== 'application/pdf') {
      Swal.fire({ text: '¡Solo es permitido archivos en formato .pdf!', icon: 'warning', confirmButtonText: 'Aceptar' })
      return
    }
    if (size > Parametricas.maxFileSize) {
      Swal.fire({ text: '¡El tamaño del archivo no puede exceder de 1Mb!', icon: 'warning', confirmButtonText: 'Aceptar' })
      return
    }

    const reader = new FileReader()
    reader.readAsDataURL(event.target.files[0])
    reader.onload = async (ev: any) => {
      let docBase64 = ev.target.result
      //Quitamos la cabecera del tipo de archivo data:application/pdf;base64,
      docBase64 = docBase64.substring(28)
      let archivoBinario = window.atob(docBase64)
      //// Verificamos si el archivo esta dañado
      if (archivoBinario.lastIndexOf("%PDF-") !== 0 || archivoBinario.lastIndexOf("%%EOF") === -1) {
        Swal.fire({ text: 'El archivo esta dañado, por favor revise y vuelva a intentarlo', icon: 'warning', confirmButtonText: 'Aceptar' })
        return
      }

      sustanciaFormGroup.patchValue({
        archivo: fileName,
        base64: docBase64,
        nuevo_archivo: true,
        adjuntar_archivo_existente: false
      })
    }
  }
}
}
