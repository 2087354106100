import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseHttpService } from 'src/app/core/services/base-http.service';
/**
 * Inyectable que permite la comunicacion con el api backend de autorizaciones previas
 */
@Injectable({
  providedIn: 'root'
})
export class AutorizacionPreviaImportacionesService {
 /**
   * variable boton boolean
   */
  boton = true;
    /**
   * variable contador
   */
  contador: number = 0;
    /**
   * variable currenURL
   */
  currenURL: string = '';
   /**
   * variable modulo
   */
  modulo: string = '';
   /**
   * variable botonAlmacen
   */
  botonAlmacen: boolean = false;
   /**
   * variable botonImportacion
   */
  botonImportacion: boolean = false;
  /**
   * url de servico backend de autorizaciones previas
   */
  localUrl = 'licencias-previas/importaciones';
  /**
   * Constructor del listado de autorizaciones previas
   */
  constructor(
    private http: HttpClient,
            /**
   * metodo de servicio http
   */
    private baseHttpService: BaseHttpService) { }
      /**
   * Método que permite descargar la plantilla de ingresos en formato excel
   */   
  getExcelFormularioControl(): Observable<any> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.get("assets/excel/FORMULARIO DE CONTROL IMPORTACION 01.xlsx", {
      headers,
      responseType: "blob" as "json",
    });
  }
      /**
   * Método que permite descargar la plantilla de ingresos en formato excel
   */   
  getExcelFormularioControlProduccion(): Observable<any> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.get("assets/excel/FORMULARIO DE CONTRO PRODUCCION 02.xlsx", {
      headers,
      responseType: "blob" as "json",
    });
  }

  getWordDeclaracionJuradaProduccion(): Observable<any> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.get("assets/excel/DECLARACIONJURADAPRODUCCION.docx", {
      headers,
      responseType: "blob" as "json",
    });
  }
    /**
   * Método que obtiene el listado autorizaciones previas
   */ 
  public obtenerTodos(data: any=null): Observable<any> {    
  
    return this.baseHttpService.get(`${this.localUrl}/obtenertodos`);
  }
      /**
   * Método que obtiene la ultima ra por sustancia
   */ 
      public obtenerUltimaRAxSustancia(data: any=null): Observable<any> {    
  
        return this.baseHttpService.post(`${this.localUrl}/obtenerUltimaRAxSustancia`,data);
      }
        /**
   * Método que obtiene la ultima ra en su formato pdf base64
   */ 
        obtenerDocumentoRA(IdAutorizacionPrevia: number): Observable<any> {
        return this.baseHttpService.get(`${this.localUrl}/obtenerDocumentoRA/${IdAutorizacionPrevia}`)
      }
 /**
   * Método que crea datos de autorizaciones previas
   */ 
  public crearImportacion(data: any): Observable<any>{
    return this.baseHttpService.post(`${this.localUrl}/crearImportacion`, data);
  }
  /**
   * Método que crea datos de autorizaciones previas
   */ 
  public crearPrimeraImportacion(data: any): Observable<any>{
    return this.baseHttpService.post(`${this.localUrl}/crearPrimeraImportacion`, data);
  }
 /**
   * Método que busca datos de autorizaciones previas
   */ 
  public buscarDatos( data: any): Observable<any> {   
    return this.baseHttpService.post(`${this.localUrl}/buscarDatos`, data);
  }
 /**
   * Método que busca datos de una autorizacione previa
   */ 
  public buscarRAAnterior( data: any): Observable<any> {   
    return this.baseHttpService.post(`${this.localUrl}/buscarRAAnterior`, data);
  }
 /**
   * Método que actualiza datos de importacion de autorizaciones previas
   */ 
  public actualizarImportacion(id: string|number, data: any): Observable<any> {
    return this.baseHttpService.put(`${this.localUrl}/actualizarImportacion/` + id, data);
  }
   /**
   * Método que actualiza datos de exportacion de autorizaciones previas
   */ 
  public actualizarExportacion(id: string|number, data: any): Observable<any> {
    return this.baseHttpService.put(`${this.localUrl}/actualizarExportacion/` + id, data);
  }
   /**
   * Método que actualiza datos de produccion de autorizaciones previas
   */ 
  public actualizarProduccion(id: string|number, data: any): Observable<any> {
    return this.baseHttpService.put(`${this.localUrl}/actualizarProduccion/` + id, data);
  }
   /**
   * Método que actualiza datos de produccion de autorizaciones previas
   */ 
  public actualizarComercializacion(id: string|number, data: any): Observable<any> {
    return this.baseHttpService.put(`${this.localUrl}/actualizarComercializacion/` + id, data);
  }
   /**
   * Método que actualiza datos de ampliacion de autorizaciones previas
   */ 
  public actualizarAmpliacion(id: string|number, data: any): Observable<any> {
    return this.baseHttpService.put(`${this.localUrl}/actualizarAmpliacion/` + id, data);
  }
   /**
   * Método de eliminacion de datos autorizaciones previas
   */ 
  public eliminarRegistro(id: string|number, data: any): Observable<any> {
    return this.baseHttpService.put(`${this.localUrl}/eliminarRegistro/` + id, data);
  }
   /**
   * Método de eliminacion de datos autorizaciones previas
   */ 
  public ImprocedenteporAprobar(id: string|number, data: any): Observable<any> {
    return this.baseHttpService.put(`${this.localUrl}/ImprocedenteporAprobar/` + id, data);
  }
 /**
   * Método que crea datos exportacion autorizaciones previas
   */ 
  public crearExportacion(data: any): Observable<any>{
    return this.baseHttpService.post(`${this.localUrl}/crearExportacion`, data);
  }
  /**
   * Método que crea datos produccion autorizaciones previas
   */ 
  public crearProduccion(data: any): Observable<any>{
    return this.baseHttpService.post(`${this.localUrl}/crearProduccion`, data);
  }
/**
   * Método que crea datos ampliacion autorizaciones previas
   */ 
  public crearAmpliacion(data: any): Observable<any>{
    return this.baseHttpService.post(`${this.localUrl}/crearAmpliacion`, data);
  }
   /**
   * Método que crea datos exportacion autorizaciones previas
   */ 
   public crearComercializacion(data: any): Observable<any>{
    return this.baseHttpService.post(`${this.localUrl}/crearComercializacion`, data);
  }
  public crearComercializacionAP(data: any): Observable<any>{
    return this.baseHttpService.post(`${this.localUrl}/crearComercializacion-ap`, data);
  }
  public crearPrimeraComercializacion(data: any): Observable<any>{
    return this.baseHttpService.post(`${this.localUrl}/crearPrimeraComercializacion-ap`, data);
  }
  

  public obtenerSustanciaxTipo(data: any): Observable<any>{
    return this.baseHttpService.post(`${this.localUrl}/obtenerSustanciaxTipo`, data);
  }

  public obtenerTipoHidrocarburo(data: any): Observable<any>{
    return this.baseHttpService.post(`${this.localUrl}/obtenerTipoHidrocarburo`, data);
  }
  /**
   * Método que busca datos segun numero de resolucion RA ampliacion autorizaciones previas
   */
  buscarRA(numero_ra: string): Observable<any> {
    let formData = { numero_ra: numero_ra };
    return this.baseHttpService.post(`${this.localUrl}/buscar`,formData);
  }
  /**
   * Método que crea pdf importacion de autorizaciones previas
   */
  public pdfImportacion(data: any): Observable<any>{
    return this.baseHttpService.post(`/tramite/ap/importacion/pdf-fd`, data);
  }
   /**
   * Método que crea pdf exportacion de autorizaciones previas
   */
  public pdfExportacion(data: any): Observable<any>{
    return this.baseHttpService.post(`tramite/ap/exportacion/pdf-fd`, data);
  }
   /**
   * Método que crea pdf de ampliacion de autorizaciones previas
   */
  public pdfAmpliacion(data: any): Observable<any>{
    return this.baseHttpService.post(`/tramite/ap/ampliacion/pdf-fd`, data);
  }
   /**
   * Método que crea pdf de produccion de autorizaciones previas
   */
  public pdfProduccion(data: any): Observable<any>{
    return this.baseHttpService.post(`tramite/ap/produccion/pdf-fd`, data);
  }
   /**
   * Método que crea pdf de autorizaciones previas
   */
  public pdf(data: any): Observable<any>{
    return this.baseHttpService.post(`tramite/ap/pdf`, data);
  }  
     /**
   * Método que crea pdf de autorizaciones previas firma digital
   */
  public pdfFD(data: any): Observable<any>{
    return this.baseHttpService.post(`tramite/ap/pdf-fd`, data);
  }  
      /**
   * Método que crea pdf de autorizaciones previas ciudadania digital
   */
  public pdfCD(data: any): Observable<any>{
    return this.baseHttpService.post(`tramite/ap/pdf-cd`, data);
  }  
}