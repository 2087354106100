<div class="user-container">
  <div class="row user-height justify-content-center align-items-center">
    <div class="col-md-9 container">
      <div class="user-container__box">
        <div class="">
          <div class="user-container__head">
            <div class="user-container__head head__logo">
              <img src="assets/images/logo2021.jpg" alt="DGSC" />
            </div>
            <!--  <mat-card-title style="text-align: center; font-size: 2vh;"  class="main__title">ESTADO DIGITAL 6 - DGSC</mat-card-title> -->

            <mat-card-title style="text-align: center;  font-size: 1.5vh;"><br>SISTEMA DE REGISTRO, VERIFICACIÓN,
              CONTROL, Y FISCALIZACIÓN DE SUSTANCIAS QUÍMICAS CONTROLADAS</mat-card-title>
            <br>
            <mat-card-subtitle class="subtitle" style=" font-size: 1.5vh;"><strong>REGISTRO DE
                USUARIO</strong></mat-card-subtitle>
          </div>

          <div class="user-container__body">
            <div class="user-container__form">

              <mat-horizontal-stepper class="registro-usuario-stepper" [linear]="isLinear" #stepper>

                <mat-step [completed]="firstStepComplete" label="Datos personales" [editable]="isEditable">

                  <div class="step-body">
                    <div class="step-contain step-contain--center">
                      <div *ngIf="firstStepComplete; then thenBlock else elseBlock"></div>

                      <ng-template #thenBlock>
                        <!-- <img src="assets/images/next-arrow.png" alt="Datos registrados" width="80" /> -->


                        <span>Sus datos personales han sido registrados, presione el botón <span
                            style="font-weight:600;">siguiente</span>
                          para continuar</span>
                        <br>
                        <button mat-flat-button class="mt-3" color="primary" matStepperNext
                          (click)="toSecondStep(stepper)" *ngIf="firstStepComplete">
                          <!-- <mat-icon>arrow_forward</mat-icon> -->
                          Siguiente
                        </button>
                      </ng-template>

                      <ng-template #elseBlock>
                        <img src="assets/images/warning.png" alt="warning" width="80" />
                        <br>
                        <p style="font-size:1.3rem;font-weight:500;">Datos de persona no
                          registrado.</p>
                        <small>Vuelva a registrar o inicie sesión con otra cuenta.</small>

                        <button mat-flat-button color="primary" matStepperNext (click)="registrarPersona()"
                          class="mt-5">Registrar datos personales</button>
                      </ng-template>

                    </div>
                    <!-- <div class="step-footer">
                      <button mat-flat-button color="primary" matStepperNext (click)="toSecondStep(stepper)"
                        *ngIf="firstStepComplete">Siguiente</button>
                    </div> -->
                  </div>
                </mat-step>

                <mat-step [completed]="secondStepComplete" label="Datos de usuario" [editable]="isEditable">
                  <div class="step-body">
                    <div class="step-contain step-contain--center">
                      <form [formGroup]="secondFormGroup" autocomplete="off">
                        <div class="row mb-3">
                          <!-- <div class="col">
                            Ingrese sus datos de usuario para poder acceder al sistema.
                          </div> -->
                          <mat-card-subtitle class="subtitle"><strong>REGISTRO DE DATOS DE USUARIO PARA EL SISTEMA.</strong></mat-card-subtitle>
                        <div class="col" style="color: red;">
                            ¡Verifique su correo electrónico, por favor!
                          </div>
                        </div>
                        <div class="row" style="font-size: 0.8rem;">
                          <div class="col-md-6">
                            <mat-form-field appearance="outline">
                              <mat-label>Correo</mat-label>
                              <input matInput type="text" formControlName="correo" name="correo">
                              <mat-error *ngIf="secondFormGroup.get('correo').invalid">El correo electrónico no es
                                válido</mat-error>
                            </mat-form-field>
                          </div>
                          <div class="col-md-6">
                            <mat-form-field appearance="outline">
                              <mat-label>Nombre de usuario</mat-label>
                              <input matInput type="text" formControlName="cuenta" placeholder="Ej. fvega, mrodriguez"
                                name="cuenta">
                              <mat-error *ngIf="secondFormGroup.get('cuenta').invalid">El nombre de usuario no es
                                válido</mat-error>
                              <!-- <mat-error *ngIf="secondFormGroup.controls['cuenta'].hasError('required')">
                                Nombre de usuario requerido
                              </mat-error> -->
                            </mat-form-field>
                            <!-- <small style="font-size:.7rem;line-height:0rem;">Elija un nombre
                              de usuario para poder acceder al sistema. Ej. frodriguez,
                              frodriguez01, etc.</small> -->
                          </div>
                        </div>
                        <div class="row" style="font-size: 0.8rem;">
                          <div class="col-md-6">
                            <mat-form-field appearance="outline">
                              <mat-label>Contraseña</mat-label>
                              <input matInput [type]="hide ? 'password' : 'text'" placeholder="Contraseña de Usuario"
                                formControlName="password" name="password" autocomplete="off">
                              <button type="button" mat-icon-button matSuffix (click)="hide = !hide"
                                [attr.aria-label]="'Ocultar contraseña'" [attr.aria-pressed]="hide">
                                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                              </button>
                              <mat-error *ngIf="secondFormGroup.controls['password'].hasError('minlength')">
                                Debe contener mínimo 8 caracteres
                              </mat-error>
                              <mat-error *ngIf="secondFormGroup.controls['password'].hasError('pattern')">
                                No contiene los caracteres requeridos
                              </mat-error>
                            </mat-form-field>
                            <div class="col" style="color: rgb(33, 124, 243); font-size: 10px;">
                              *La contraseña debe contener letras, números y uno de los siguientes
                              caracteres #?!@$%^&*-
                            </div>
                            <!--         <small style="font-size:.7rem;line-height:0rem;">*La contraseña
                              debe contener letras, números y uno de los siguientes
                              caracteres #?!@$%^&*-</small> -->
                          </div>
                          <div class="col-md-6">
                            <mat-form-field appearance="outline">
                              <mat-label>Confirmar contraseña</mat-label>
                              <input matInput [type]="hide ? 'password' : 'text'"
                                placeholder="Confirmacion de contraseña" formControlName="passwordConfirm"
                                name="password-confirm" autocomplete="off">
                              <button type="button" mat-icon-button matSuffix (click)="hide = !hide"
                                [attr.aria-label]="'Ocultar contraseña'" [attr.aria-pressed]="hide">
                                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                              </button>
                              <small *ngIf="passwordNoSonIguales" class="error-message">
                                Las contraseña no coinciden
                              </small>
                            </mat-form-field>
                          </div>
                        </div>
                      </form>
                      <div class="step-footer">
                        <button mat-flat-button color="primary" matStepperNext (click)="registrarUsuario()">Registrar
                          Usuario</button>
                      </div>
                    </div>

                  </div>
                </mat-step>

                <mat-step [completed]="thirdStepComplete" label="Código de verificación" [editable]="isEditable">
                  <div class="step-body">
                    <div class="step-contain step-contain--center">

                      <form [formGroup]="thirdFormGroup" autocomplete="off">
                        <div class="row" style="font-size: 0.8rem;">
                          <div class="col-2"></div>
                          <div class="col-8">
                            <mat-form-field appearance="outline">
                              <mat-label>Código</mat-label>
                              <input matInput type="text" formControlName="codigo" name="codigo" maxlength="6" required>
                              <mat-error *ngIf="thirdFormGroup.get('codigo').invalid">El código no es válido</mat-error>
                            </mat-form-field>
                          </div>
                          <div class="col-2"></div>
                        </div>
                      </form>

                      <div class="row mt-4" style="font-size: 0.8rem;">
                        <div class="col text-center">
                          Se le envío un código de verificación a su correo, por favor revíselo e ingrese el código
                          de 6 dígitos.<br>
                          <small style="color: rgba(109, 118, 139, 1)">*Si no lo encuentra en la bandeja principal,
                            revise en la carpeta "Spam".</small>
                          <br><br>
                          <a (click)="solicitarCodigo()"
                            style="color: #34b0a6; cursor: pointer; font-weight: 500;">Solicitar nuevo código de
                            activación</a>
                        </div>
                      </div>
                    </div>
                    <div class="step-footer">

                      <button mat-flat-button color="primary" *ngIf="firstStepComplete" (click)="activarUsuarioNuevo()"
                        matStepperNext>Verificar
                        código</button>
                    </div>
                  </div>

                </mat-step>

                <mat-step label="Finalización de registro">
                  <div class="step-body">
                    <div class="step-contain step-contain--center">
                      <div *ngIf="isFormCompleted; then thenFinishBlock else elseFinishBlock">
                      </div>
                      <ng-template #thenFinishBlock>
                        <img src="assets/images/auth-check.png" alt="DGSC" />
                        <br>
                        <p style="font-size:1.3rem;font-weight:500;">El usuario ha sido
                          registrado y verificado.</p>
                        <small>Ahora puede <a routerLink="/login">iniciar sesión</a> con su
                          nueva cuenta</small>
                      </ng-template>
                      <ng-template #elseFinishBlock>
                        <img src="assets/images/warning.png" alt="warning" width="80" />
                        <br>
                        <p style="font-size:1.3rem;font-weight:500;">El usuario no ha sido
                          registrado.</p>
                        <small><a routerLink="/login">Vuelva a registrar</a> o inicie sesión con
                          otra cuenta.</small>
                      </ng-template>
                    </div>
                    <div class="step-footer">
                      <button mat-flat-button color="primary" *ngIf="isFormCompleted" [disabled]="[!thirdFormGroup]"
                        routerLink="/login">Finalizar
                        registro</button>
                    </div>
                  </div>
                </mat-step>

              </mat-horizontal-stepper>

            </div>
          </div>
          <br>
          <div class="user-container__footer">
            <div class="footer__option">
              ¿Ya tienes cuenta?
              <a routerLink="/login"> Inicia sesión</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>