<div class="modal-container">

  <div class="row modal__header">
    <div class="col text-center">
      <img src="assets/images/dgsc-token.png" class="my-3" alt="Dgsc token logo" width="70">
      <div class="modal__title">DGSC Token</div>
      <div class="modal__subtitle">Ingresa tu codigo</div>
    </div>
  </div>

  <form (ngSubmit)="enviarToken()">

    <div class="my-5">
      <div *ngIf="isMobile; then isMobileBlock else isNotMobileBlock"></div>

      <ng-template #isMobileBlock>
        <div class="row">
          <div class="col-md-12 text-center" style="padding: 0">
            <input type="text" class="pin-code pin-code-mobile" [(ngModel)]="digitsMobile"
              (keypress)="validateInput($event)" name="digits" autocomplete="off" maxlength="6" id="digitsInputMobile">
          </div>
        </div>
      </ng-template>

      <ng-template #isNotMobileBlock>
        <div class="row">
          <div class="col-md-12 text-center" style="padding: 0">
            <input type="text" class="pin-code pin-code-desktop" [(ngModel)]="digits[0]"
              (keypress)="ingresaData($event, 0)" autocomplete="off" maxlength="1" id="digit0Input" name="digit0Input"
              cdkFocusInitial>
            <input type="text" class="pin-code pin-code-desktop" [(ngModel)]="digits[1]"
              (keypress)="ingresaData($event, 1)" autocomplete="off" maxlength="1" id="digit1Input" name="digit1Input">
            <input type="text" class="pin-code pin-code-desktop" [(ngModel)]="digits[2]"
              (keypress)="ingresaData($event, 2)" autocomplete="off" maxlength="1" id="digit2Input" name="digit2Input">
            <input type="text" class="pin-code pin-code-desktop" [(ngModel)]="digits[3]"
              (keypress)="ingresaData($event, 3)" autocomplete="off" maxlength="1" id="digit3Input" name="digit3Input">
            <input type="text" class="pin-code pin-code-desktop" [(ngModel)]="digits[4]"
              (keypress)="ingresaData($event, 4)" autocomplete="off" maxlength="1" id="digit4Input" name="digit4Input">
            <input type="text" class="pin-code pin-code-desktop" [(ngModel)]="digits[5]"
              (keypress)="ingresaData($event, 5)" autocomplete="off" maxlength="1" id="digit5Input" name="digit5Input">
          </div>
        </div>
      </ng-template>

      <div class="row mt-4">
        <div class="col-md-12 text-center">
          <small style="color: #999;" (click)="resetToken()" style="cursor: pointer; color: #205799;">Borrar</small>
        </div>
      </div>

    </div>

    <div class="row">
      <div class="col text-center">
        <button mat-flat-button class="mx-3" [mat-dialog-close]="{ result: false }">Cancelar</button>
        <button mat-raised-button cdkFocusInitial color="primary" class="button button-primary" type="submit">
          Aceptar
        </button>
      </div>
    </div>

  </form>

</div>