import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SustanciasService } from '../../../services/sustancias.service';
import { SustanciaModel } from '../../../models/Sustancia.model';
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { MatTable } from '@angular/material/table';
import { MatTableDataSource } from "@angular/material/table";
import Swal from "sweetalert2";
import { SustanciaAPModel } from '../../../models/SustanciaAP.model';
import { lastValueFrom } from 'rxjs';
import { Parametricas } from 'src/app/core/enums/parametricas.enums';
/**
 * Componente que permite el listado y acciones de sustancias agregadas a la solicitud
 */
@Component({
  selector: 'app-agregar-sustancias',
  templateUrl: './agregar-sustancias.component.html',
  styleUrls: ['./agregar-sustancias.component.scss']
})

export class AgregarSustanciasComponent implements OnInit {
   /**
 * Buscar
 */
    public Buscar=Parametricas.BUSCAR
  /**
 * modelo de variables de cada sustancia
 */
  sustancias: MatTableDataSource<SustanciaModel>;
   /**
 * variable de busqueda de sustancias
 */
  public criterioBusqueda: string = "";
   /**
 * propiedad de tabla seleccionada
 */
  @ViewChild(MatTable) tablaSeleccion!: MatTable<SustanciaModel>;
  /**
 * propiedad de tabla seleccionada filtrada
 */
  @ViewChild(MatSort) sort: MatSort;
  /**
 * propiedad de tabla seleccionada paginador
 */
  @ViewChild(MatPaginator) paginator: MatPaginator;
/**
   * variable de sustancias seleccionadas
   */
  sustanciasSeleccionadas: any[] = [];
  /**
   * variable de datos de sustancias seleccionadas
   */
  sustanciasAgregadas: any[] = [];
  /**
   * columnas columnasSustancias para el listado de sustancias por solicitud
   */
  public columnasSustancias: string[] = [
    "id_sustancia",
    "acciones",    
    "nombre_sustancia",
    "nombre_unidad_medida",
    "nombre_sustancia_primaria",
  ];
 /**
   * Constructor del listado de sustancias por solicitud
   */
  subtipoTramite : number = 0
  tipoTramite : number = 0
  tipoAP : number = 0
  constructor(
    /**
   * objeto dialog para gestion los objetos MatDialog
   */  
    public dialog: MatDialog,
    /**
   * objeto dialogRef de sustancia 
   */  
    public dialogRef: MatDialogRef<AgregarSustanciasComponent>,
    /**
    * Constructor  que permite consumir los servicios de sustancias
    */
    private sustanciasService: SustanciasService,
    /**
    * Consume datos enviados desde el dialogo de solicitud a sustancias 
    */
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }
  /**
   * Inicializa funcion de sustancias agregadas y listado
   */
  ngOnInit(): void {

    this.dialogRef.updateSize('auto', '65%')
    this.sustanciasAgregadas = this.data?.sustanciasAgregadas;
    this.subtipoTramite = this.data?.subtipoTramite
    this.tipoTramite = this.data?.tipoTramite
    this.tipoAP = this.data?.tipoAP
    this.getSustancias()
    
  }
 
  /**
   * Funcion del listado de las sustancias
   */
  async getSustancias(criterio: string = "") {
   
 /*    if(this.data.actividad != 1 || this.data.actividad != 2 || this.data.actividad != 3 || this.data.actividad != 5){
      Swal.fire('', '¡No tiene actividad con Sustancias Químicas Controladas!', 'warning');
        return      
    } */
    let params = {  
   //   criterio: criterio,
      actividad:this.data?.actividad,
      idTramite: this.data?.idTramite,
      idRegistro : this.data?.idRegistro
    }
    if(this.subtipoTramite == 23|| this.tipoTramite == 17 || this.tipoAP == 6){
      try {
        var resp = await lastValueFrom(this.sustanciasService.obtenerHidrocarburos(params))
      } catch (error) {
        Swal.fire("", error.error.mensaje, "error");
        return;
      }
    }else {
    try {
      var resp = await lastValueFrom(this.sustanciasService.obtenerTodos(params))
    } catch (error) {
      Swal.fire("", error.error.mensaje, "error");
      return;
    }
  }
    this.sustancias = new MatTableDataSource(resp.datos);
    this.sustancias.paginator = this.paginator;
    this.sustancias.sort = this.sort;
  }
/**
   * Funcion para el filtro de busqueda en el listado
   */
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.sustancias.filter = filterValue.trim().toLowerCase();
   
    if (this.sustancias.paginator) {
      this.sustancias.paginator.firstPage();
    }
    }
 /**
   * Funcion de busqueda y/o filtro omitiendo mayusculas y minisuclas
   */
  buscarSustancias(): void {
    this.getSustancias(this.criterioBusqueda);
  }

  /**
   * Funcion para validar la existencia de la sustancia
   */
   validaExistencia(sustancia: SustanciaModel):boolean{
    let existe = false
    for(let i = 0; i < this.data.sustanciasArray.length; i++){
      if(sustancia.id_sustancia == this.data.sustanciasArray[i].id_sustancia){
        sustancia.cantidad_solicitada_autorizacion_previa = this.data.sustanciasArray[i].cantidad_solicitada
        existe = true
        break
      }
    }    
    return existe
  }


 /**
   * Funcion que permite la seleccion de sustancia por solicitud
   */
  async seleccionarSustancia(sustancia: SustanciaModel) {
 
    this.sustanciasSeleccionadas.push(new SustanciaAPModel(sustancia))
   
    
  }

/**
   * Funcion para eliminar la sustancia agregada
   */
  eliminaSustancia(sustancia : SustanciaModel):void{
    
    let posicionSeleccionable = 0;
    
    for(posicionSeleccionable ; posicionSeleccionable < this.data.sustanciasArray.length; posicionSeleccionable++){
      if(this.data.sustanciasArray[posicionSeleccionable].id_sustancia == sustancia.id_sustancia){
        break;
      }
    }
    this.data.sustanciasArray.forEach(function(sust:SustanciaModel){
      
    })
    this.data.sustanciasArray.splice(posicionSeleccionable, 1);
    this.tablaSeleccion.renderRows();
  }

 /**
   * Funcion que permite cerrar el dialodo abierto de sustancias
   */
  cerrarDialog(): void {
    this.dialogRef.close();
  }
}
