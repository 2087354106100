<form action="" [formGroup]="formulario">
  <mat-card-title style="text-align: center; font-size: 20px;" class="text-uppercase">Documento
    Adicional</mat-card-title><br>
  <div class="text-center">
    <div class="row justify-content-start">

        <mat-form-field appearance="outline" class="col-md" style="padding: 25px;">
          <mat-label>Nombre del documento</mat-label>
          <textarea matInput type="text" class="text-uppercase" pattern="^(?!\s)(?!.*\s$)(?!.*').*$"
            formControlName="descripcion"></textarea>
          <mat-error *ngIf="formulario.get('descripcion').hasError('pattern')">No se permiten espacios al
            inicio o al final y caracteres especiales</mat-error>
        </mat-form-field>
      

      <div class="row">
        <mat-form-field appearance="outline" class="col-md-4 input-date">
          <mat-label>No. de identificador</mat-label>
          <input matInput type="text" placeholder="Identificador" maxlength="30" pattern="^(?!\s)(?!.*\s$)(?!.*').*$"
            formControlName="identificador" autocomplete="off">
          <mat-error *ngIf="formulario.get('identificador').hasError('pattern')">No se permiten espacios al
            inicio o al final y caracteres especiales</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-md-4 input-date">
          <mat-label>Fecha de Emisión</mat-label>
          <input matInput [matDatepicker]="picker" formControlName="fecha_emision" [max]="today" readonly>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker touchUi #picker></mat-datepicker>
          <mat-error *ngIf='formulario.controls["fecha_emision"].hasError("required")'>Campo
            requerido</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-md-4 input-date">
          <mat-label>Fecha Finalización</mat-label>
          <input matInput [matDatepicker]="pickerF" formControlName="fecha_fin" [min]="formulario.value.fecha_emision"
            [disabled]="formulario.value.fecha_emision === ''" readonly>
          <mat-datepicker-toggle matSuffix [for]="pickerF"></mat-datepicker-toggle>
          <mat-datepicker touchUi #pickerF></mat-datepicker>
          <mat-error *ngIf='formulario.controls["fecha_fin"].hasError("required")'>Campo
            requerido</mat-error>
        </mat-form-field>

      </div>
    </div>

    <div class="row justify-content-center">
      <button mat-raised-button class="col-sm-3 m-2 nuevo-button" [disabled]="formulario.invalid"
        (click)="guardarDocumento()">
        <mat-icon>save</mat-icon>Guardar
      </button>

      <button class="col-sm-3 m-2" mat-raised-button color="warn" style="height: 45px;"
        [mat-dialog-close]="{ ok: false, codigo : 0 }"><mat-icon>cancel</mat-icon>Cancelar
      </button>
    </div>
  </div>
</form>