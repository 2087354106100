import { Content } from '@angular/compiler/src/render3/r3_ast';
import { Component, Inject, OnInit,ViewChild,ElementRef,AfterViewInit ,HostListener } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { fromEvent } from 'rxjs';

@Component({
  selector: 'app-pago-libelula',
  templateUrl: './pago-libelula.component.html',
  styleUrls: ['./pago-libelula.component.scss']
})
export class PagoLibelulaComponent implements OnInit {
  url: SafeResourceUrl;
 // url: string;
  titulo: string;
  @ViewChild('iframe') iframe: ElementRef;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
   
    private sanitizer: DomSanitizer,
    public dialogPagoLibelula: MatDialogRef<PagoLibelulaComponent>,
  ) {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(data.url);
   // this.url = 'https://estadodigital.mingobierno.gob.bo/dgsc/pago-exitoso';
    this.titulo = data.titulo; // Obtenemos el título desde los datos proporcionados por el MatDialog   
  }
  ngOnInit(): void {
    
  }
  cancelar(): void {
    this.dialogPagoLibelula.close({ res: false, code: 0})
  }

/* ngAfterViewInit(): void {
  this.iframe.nativeElement.onload = () => {
    this.iframe.nativeElement.contentWindow.postMessage('get-url', '*');      
    console.log("*-----------------", this.iframe.nativeElement.contentWindow.postMessage('get-url', '*'))  
  };  
} */
/* @HostListener('window:message', ['$event'])
onMessage(event: MessageEvent): void {
  if (event.data.type === 'url-change') {
    const newUrl = event.data.url;
    console.log('Cambio detectado en la dirección URL del iframe:', newUrl);
  }
} */

}
