import { SharedModule } from 'src/app/shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AuthRoutingModule } from './auth-routing.module';
import { MaterialModule } from '../../material.module';

import { LoginComponent } from './pages/login/login.component';
import { RecuperarClaveComponent } from './pages/recuperarclave/recuperar-clave.component';

import { CambiarClaveComponent } from './pages/cambiar-clave/cambiar-clave.component';
import { RestablecercComponent } from './components/restablecerc/restablecerc.component';
import { LogincdComponent } from './pages/logincd/logincd.component';
import { LogindgscComponent } from './pages/logindgsc/logindgsc.component';
import { SeleccionarRegistroComponent } from './pages/seleccionar-registro/seleccionar-registro.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MatMenuModule } from '@angular/material/menu';
import { RegistroPersonaComponent } from './pages/registro-persona/registro-persona.component';
import { RegistroUsuarioComponent } from './pages/registro-usuario/registro-usuario.component';
import { AuthLayoutComponent } from './components/auth-layout/auth-layout.component';
import { FormularioCodigoActivacionComponent } from './components/formulario-codigo-activacion/formulario-codigo-activacion.component';
import { PagoExitosoComponent } from './pages/pago-exitoso/pago-exitoso.component';



@NgModule({
  declarations: [
    AuthLayoutComponent,
    LoginComponent,
    RecuperarClaveComponent,
    CambiarClaveComponent,
    RestablecercComponent,
    LogincdComponent,
    LogindgscComponent,
    SeleccionarRegistroComponent,
    RegistroPersonaComponent,
    RegistroUsuarioComponent,
    FormularioCodigoActivacionComponent,
    PagoExitosoComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    AuthRoutingModule,
    MatMenuModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MaterialModule,
    PdfViewerModule,
    SharedModule
  ],
  providers:[
    DatePipe
  ]
})
export class AuthModule { }
