import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { TokenAppService } from '../../services/token-app.service';
import { lastValueFrom } from 'rxjs';
import Swal from 'sweetalert2';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Platform } from '@angular/cdk/platform';

@Component({
  selector: 'app-token-app',
  templateUrl: './token-app.component.html',
  styleUrls: ['./token-app.component.scss']
})
export class TokenAppComponent implements OnInit, AfterViewInit {
  digits: string[] = ['', '', '', '', '', '']
  isMobile: boolean = false
  digitsMobile: string = ''

  pdfBase64: string = ''
  uuidv4: string = ''

  constructor(
    private tokenAppService: TokenAppService,
    public dialogTokenApp: MatDialogRef<TokenAppComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public platform: Platform
  ) { }

  ngOnInit(): void {
    this.dialogTokenApp.updateSize('400px', '480px')
    this.dialogTokenApp.disableClose = true

    // pdfBase64string: pdf64, uuidv4: uuidv4
    this.pdfBase64 = this.data?.pdfBase64 || ''
    this.uuidv4 = this.data?.uuidv4 || ''

    if (this.platform.IOS || this.platform.ANDROID) {
      this.isMobile = true
    }
  }

  ngAfterViewInit(): void {
    this.setFocusOnFirstInput()
  }

  setFocusOnFirstInput() {
    const idFirstInput = (this.platform.IOS || this.platform.ANDROID) ? 'digitsInputMobile' : 'digit0Input'
    const firstInput = document.getElementById(`${idFirstInput}`) as HTMLInputElement
    setTimeout(() => firstInput.focus())
  }

  /**
   * Metodo para validar e ri al siguiente input
   * @param event 
   * @param index 
   * @returns 
   */
  ingresaData(event: KeyboardEvent, index: number) {
    if (!this.validateInput(event))
      return false

    this.siguienteCampo(index + 1)
  }

  /**
   * Metodo para validar que el token ingresado sea numerico
   * @param event 
   * @param index 
   * @returns 
   */
  validateInput(event: KeyboardEvent) {
    const input = event.key

    // Check if the input is a number or a valid control key
    if (!/^\d$/.test(input) && !['Backspace', 'Delete', 'Tab', 'Enter', 'ArrowLeft', 'ArrowRight'].includes(input)) {
      event.preventDefault() // Prevent the input
      return false
    }

    return true
  }

  /**
   * Metodo para ir al siguiente campo del token
   * @param index 
   */
  siguienteCampo(index) {
    if (index < 6) {
      const nextInput = document.getElementById(`digit${index}Input`) as HTMLInputElement

      if (nextInput) {
        setTimeout(() => nextInput.focus())
      }
    }
  }

  resetToken() {
    this.digits = ['', '', '', '', '', '']
    this.digitsMobile = ''

    this.setFocusOnFirstInput()
  }

  /**
   * Metodo para enviar el token al backend y validarlo
   * @returns 
   */
  async enviarToken(): Promise<void> {
    // Si el componente se esta ejecutando en un dispositivo movil (ANDROID or IOS) o en un browser de escritorio
    const tokenApp = (this.platform.IOS || this.platform.ANDROID) ? this.digitsMobile : this.digits.join('')

    if (tokenApp.length !== 6) {
      Swal.fire({ title: 'Token incorrecto', text: 'Debe ingresar su codigo token', icon: 'error', iconHtml: '!', confirmButtonText: 'Aceptar' })
      return
    }

    try {
      const paramsTokenApp = {
        tokenApp: tokenApp,
        pdfBase64string: this.pdfBase64,
        uuidv4: this.uuidv4
      }
      let respTokenApp = await lastValueFrom(this.tokenAppService.verificarToken(paramsTokenApp))
      console.log('resp respTokenApp', respTokenApp)

      // Si pasa a este punto significa que el token es valido
      Swal.fire({ text: 'Codigo valido', icon: 'success', confirmButtonText: 'Aceptar' })

      // Cerramos el dialog y devolvemos una respuesta { result: true }
      this.dialogTokenApp.close({ resul: true })
    } catch (error) {
      // Si pasa a este punto significa que hubo un error o el token es invalido
      Swal.fire({ text: error.error.mensaje, icon: 'error', iconHtml: '!', confirmButtonText: 'Aceptar' })
      // Cerramos el dialog y devolvemos un respuesta de error
      // this.dialogTokenApp.close({ resul: false, error: error?.error?.mensaje || 'Sucedio un error al validar el token' })
    }
  }

}
