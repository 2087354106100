import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseHttpService } from 'src/app/core/services/base-http.service';
import { CostoCategoriaModel } from '../models/costoCategoria.model';

@Injectable({
  providedIn: 'root',
})

export class RegistroService {
  localUrl = 'registros/registro'
  localUrlTramite = 'registros/tramite-registro'
  listaDocumentos = []
  costosSustancia: Array<CostoCategoriaModel> = []

  public datosEntidad = new BehaviorSubject({});

  constructor(private baseHttpService: BaseHttpService) { }

  obtenerDatosEntidad(valores: any) {
    this.datosEntidad.next(valores)
  }
  public crear(data: any): Observable<any> {
    return this.baseHttpService.post(`${this.localUrl}/crearRegistro`, data)
  }

  /**crear-direccion */
  public crearDireccion(data: any): Observable<any> {
    return this.baseHttpService.post(`${this.localUrl}/crear-direccion`, data)
  }

  public crearMigracion(data: any): Observable<any> {
    return this.baseHttpService.post(`${this.localUrl}/crear-migracion`, data)
  }

  public requisitos(data: any): Observable<any> {
    return this.baseHttpService.post(`${this.localUrl}/doc-obligatorios`, data)
  }

  public categoria(data: any): Observable<any> {
    return this.baseHttpService.post(`${this.localUrl}/calcular-categoria`, data)
  }

  public obtenerRegistroTramite(): Observable<any> {
    return this.baseHttpService.get(`${this.localUrl}/lista-registro`);
  }

  public obtenerPdf(data: any): Observable<any> {
    //return this.baseHttpService.post(`${this.localUrl}/pdf-registro`, data);
    return this.baseHttpService.post(`${this.localUrl}/pdf-registro`, data)
  }

  public actualizar(id: string | number, data: any): Observable<any> {
    return this.baseHttpService.put(`${this.localUrl}/modificar/${id}`, data)
  }

  public personFelcn(data: any): Observable<any> {
    return this.baseHttpService.post(`${this.localUrl}/felcn-persona`, data)
  }

  public obtenerRegistro(data: any): Observable<any> {
    return this.baseHttpService.post(`${this.localUrl}/obtener-registro`, data)
  }

  // obtener categoria
  public calcularCategoria(data: any): Observable<any> {
    return this.baseHttpService.post(`${this.localUrl}/calcular-categorias`, data)
  }

  public actualizarDatosSimples(data: any): Observable<any> {
    return this.baseHttpService.post(`${this.localUrl}/actualizar-datos-simples`, data)
  }

  public guardarCategoria(data: any): Observable<any> {
    return this.baseHttpService.post(`${this.localUrl}/guardar-categoria`, data)
  }

  public costoInicioTramite(data: any): Observable<any> {
    return this.baseHttpService.post(`${this.localUrl}/costo-inicio-tramite`, data)
  }

  public verificarImagenes(data: any): Observable<any> {
    return this.baseHttpService.post(`${this.localUrl}/verificar-imagenes`, data)
  }

  /**servicio simulado para validar rejap */
  public validarRejap(data: any) {
    let valido: any = false
    let invalidos = [{ numero_documento: 123 }, { numero_documento: 456 }]
    invalidos.find((res) => {
      if (res.numero_documento == data) {
        valido = true
      }
    })
    return valido
  }

  /**eliminar licencia Municipio */
  public eliminarLicenciaMunicipio(id: string | number, data: any) {
    return this.baseHttpService.put(`${this.localUrl}/eliminarLicenciaMunicipio/${id}`, data)
  }

  /**eliminar sucursal local */
  public eliminarSucursalLocal(id: string | number, data: any) {
    return this.baseHttpService.put(`${this.localUrl}/eliminarSucursal_local/${id}`, data)
  }

  /**eliminar representante */
  public eliminarRepresentante(id: string | number, data: any) {
    return this.baseHttpService.put(`${this.localUrl}/eliminarRepresentante/${id}`, data)
  }

  /**eliminar sustancia registro */
  public eliminarSustanciaRegistro(id: string | number, data: any) {
    return this.baseHttpService.put(`${this.localUrl}/eliminarSustanciaRegistro/${id}`, data)
  }

  /**eliminar sustancia registro */
  public eliminarMaquinaria(id: string | number, data: any) {
    return this.baseHttpService.put(`${this.localUrl}/eliminarMaquiniaria/${id}`, data)
  }

  /**eliminar registro */
  public eliminarRegistro(id: string | number, data: any) {
    return this.baseHttpService.put(`${this.localUrl}/eliminarRegistro/${id}`, data)
  }

  /**Guardar Imagen Registro */
  public guardarImagenAdicionales(datos: any): Observable<any> {
    return this.baseHttpService.post(`${this.localUrl}/guardar-imagen-adicionales`, datos)
  }
  /**obtener Imagen Registro */
  public obtenemosImagenAdicionalesRegistros(datos: any): Observable<any> {
    return this.baseHttpService.post(`${this.localUrl}/obtener-imagenes-adicionales-registros`, datos)
  }
  /**obtener Imagen Registro */
  public eliminarImagenAdicionalesRegistros(datos: any): Observable<any> {
    return this.baseHttpService.post(`${this.localUrl}/eliminar-imagen-adicional-registros`, datos)
  }


  /**Obtener Registro datos Persona */
  // public obtenerRegistroPersona(data: any): Observable<any> {
  //   return this.baseHttpService.post(`${this.localUrl}/obtener-registroPersona`, data)
  // }


  public obtenerFelcnRejap(id: string | number): Observable<any> {
    return this.baseHttpService.get(`${this.localUrl}/certificados-felcn-rejap/${id}`);
  }
  
  public listaRepresentantesLegalesTramite(data: any): Observable<any> {
    return this.baseHttpService.post(`registros/tramite-representante-legal/obtener-representante-legal-tramite`, data)
  }

  public listaRepresentantesLegales(): Observable<any> {
    return this.baseHttpService.get(`registros/tramite-representante-legal/obtener-representante-legal`)
  }

  /**requisitos al final del registro */
  public requisitosRegistro(data: any): Observable<any> {
    return this.baseHttpService.post(`${this.localUrl}/requisitos-registro`, data)
  }

  /**requisitos al final del registro */
  public verificarRegistro(data: any): Observable<any> {
    return this.baseHttpService.post(`${this.localUrl}/verificacion-registro`, data)
  }

}
