import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderInfoComponent } from './components/header-info/header-info.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material.module';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { PdfViewerComponent } from './components/pdf-viewer/pdf-viewer.component';
import { AutofocusDirective } from './directives/autofocus.directive';


@NgModule({
  declarations: [
    HeaderInfoComponent,
    BreadcrumbsComponent,
    PdfViewerComponent,
    AutofocusDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    PdfViewerModule
  ],
  exports: [
    HeaderInfoComponent,
    BreadcrumbsComponent,
    PdfViewerComponent
  ]
})
export class SharedModule { }
