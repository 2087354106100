import { Injectable } from '@angular/core'
import { Observable, lastValueFrom } from 'rxjs'
import { BaseHttpService } from './base-http.service'
import Swal from 'sweetalert2';
import { TramiteService } from "src/app/core/services/tramite.service";
import * as moment from 'moment';
import { PagoLibelulaComponent } from '../components/pago-libelula/pago-libelula.component';
import { MatDialog } from '@angular/material/dialog';
import { Parametricas } from 'src/app/core/enums/parametricas.enums';
@Injectable({
  providedIn: 'root'
})
export class PagosService {
titulo=Parametricas.TITULO
ancho='70%'
alto='800px'
  constructor(private baseHttpService: BaseHttpService, private tramiteService: TramiteService,  public dialog: MatDialog,) { }
  async PasarelaPagosDigital(row) {
    Swal.fire("", "Espere por favor", "info");
        Swal.fire({
          title: "Espere por favor.....",
          allowOutsideClick: false,
        });
        Swal.showLoading();
    
        try {
          const resp = await lastValueFrom(this.tramiteService.verificaFechaVencimientoPasarela({ idTramite: row.pago_id_tramite }));
          Swal.close();   
         
          const fecha_actual=resp.datos.tramite.hoy 
          const fecha_ven_pasarela=resp.datos.tramite.fecha_vencimiento_pasarela
          const url_pasarela=resp.datos.tramite.url_pasarela_pagos_tramite
            
          if (fecha_actual > fecha_ven_pasarela || !fecha_ven_pasarela || !url_pasarela  || (resp.datos.tramite.id_tipo_tramite===12 && resp.datos.tramite.id_estado ===621 && resp.datos.tramite.id_estado===1)) {          
            await this.PagoPasarelaNuevaSolicitud(row);          
          } else {
            var ans = await Swal.fire({
              title:"Usted cuenta con una solicitud de pago generada. ¿Desea continuar?",icon: "info",
             showCancelButton: true, confirmButtonText: "Si, continuar",
              cancelButtonText: "No, cancelar",
              showCloseButton: true
            })
            if (!ans.isConfirmed) return
            Swal.fire("", "Espere por favor", "info");
            Swal.fire({
              title: "Espere por favor...",            
              allowOutsideClick: false,
            });
            Swal.showLoading()
            const url = url_pasarela;
            this.dialog.open(PagoLibelulaComponent, {disableClose:true,
              width:this.ancho,
              height:this.alto,
              data: {
                titulo: this.titulo,                
                url: url,
              },
            }); 
            Swal.close();
           // window.open(url, '_blank', 'noopener');
          }
        } catch (error) {
          Swal.fire("", error.error.mensaje, "warning");
        }

    }
 
  async PagoPasarelaNuevaSolicitud(row) {
    try {
      var ans = await Swal.fire({
        title:"¿Desea generar una nueva solicitud de pago en línea?",icon: "question",
        showCancelButton: true, confirmButtonText: "Si, guardar",
        cancelButtonText: "No, cancelar",
        showCloseButton: true
      })
      if (!ans.isConfirmed) return
      Swal.fire("", "Espere por favor", "info");
      Swal.fire({
        title: "Espere por favor...",       
        allowOutsideClick: false,
      });
      Swal.showLoading()
      const respuesta = await lastValueFrom(this.tramiteService.pasarelaPagos({ idTramite: row.pago_id_tramite }));
      Swal.close();
 
      if (!respuesta || !respuesta.datos || !respuesta.datos.pasarela) {
        Swal.fire("", "¡Servicio no disponible!", "warning");
        return;
      }
  
      const url = respuesta.datos.pasarela.url_pasarela_pagos;
      this.dialog.open(PagoLibelulaComponent, {disableClose:true,
        width:this.ancho,
        height:this.alto,
        data: {
          titulo: this.titulo,          
          url: url,
        },
      });
     // window.open(url, '_blank', 'noopener');
    } catch (error) {
      Swal.fire("", error.error.mensaje, "warning");
    }
  }
  guardarComprobantes(datos: any): Observable<any> {
    return this.baseHttpService.post("tramites/pagos/guardar-comprobantes", datos)
  }

  verificarPagoTramite(datos: any): Observable<any> {
    return this.baseHttpService.post("tramites/pagos/verificar-pago-tramite", datos)
  }

  obtenerCostoComprobantes(datos: any): Observable<any> {
    return this.baseHttpService.post("tramites/pagos/obtener-costo-comprobantes", datos)
  }
  obtenerTransaccionesOnlinesinVencer(datos: any): Observable<any> {
    return this.baseHttpService.post("tramites/pagos/obtenerTransaccionesOnlinesinVencer", datos)
  }

  obtenerComprobante(datos: any): Observable<any> {
    return this.baseHttpService.post("tramites/pagos/obtener-comprobante", datos)
  }

  validarComprobantes(comprobantes: any): Observable<any> {
    return this.baseHttpService.post('tramites/pagos/validar-comprobantes', comprobantes)
  }
 
}
