
  <mat-card>
  <mat-card-title style="text-align: center; font-size: 20px;">TIPO DE ENVASES</mat-card-title><br>
  <mat-card-subtitle class="subtitle"><strong>AGREGAR TIPO DE ENVASES.</strong></mat-card-subtitle>

  
    <form  [formGroup]="envaseForm" style="font-size: 13px;">
      
        <div class="row">
       
        <div class="col-md-4" >
            <mat-form-field style="width:100%" class="full-width"  appearance="outline" >
              <mat-label>Nombre del envase</mat-label>
              <input  matInput type="text" style="width:100%" formControlName="nombre_envase" maxlength=25 required="" class="text-uppercase">    
              <mat-error *ngIf='envaseForm.controls["nombre_envase"].hasError("required")'>Campo requerido</mat-error>
              <mat-error *ngIf='envaseForm.get("nombre_envase").hasError("pattern")'>No se permiten espacios al inicio o al final</mat-error>
            </mat-form-field>           
          </div>
          <div class="col-md-4" >
            <mat-form-field style="width:100%" class="full-width"  appearance="outline" >
              <mat-label>Capacidad</mat-label>
              <input  matInput type="number" min="0" style="width:100%" formControlName="capacidad"  (keydown)="validarExponente($event)" required>    
              <mat-error *ngIf='envaseForm.controls["capacidad"].hasError("required")'>Campo requerido</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-4">      
            <mat-form-field  style="width:100%" class="full-width" appearance="outline" >
              <mat-label>Unidad de medida</mat-label>
              <mat-select formControlName="id_unidad_medida">              
                  <mat-option *ngFor="let unidades of unidadesMedidas" [value]="unidades.id_unidad_medida">
                    {{unidades.nombre}}
                    </mat-option>
              </mat-select>
              <mat-error *ngIf='envaseForm.controls["id_unidad_medida"].hasError("required")'>Campo requerido</mat-error>
            </mat-form-field>      
      </div> 
        </div>
        <div class="row">
           
          <div class="col-md-12" >
            <mat-form-field style="width:100%"   appearance="outline" >
              <mat-label>Descripción del envase</mat-label>
              <textarea matInput placeholder="" formControlName="descripcion" class="text-uppercase" maxlength=250 ></textarea>        
            </mat-form-field>  
          </div>
            </div>
           <br>
           <div mat-dialog-actions class="mt-3 mb-1">
    <button mat-raised-button class="nuevo-button"  (click)="guardarEnvase()">
      <mat-icon>save</mat-icon>Guardar</button>
    <button mat-raised-button color="warn"  (click)=cerrarDialog()  ><mat-icon>cancel</mat-icon>Cancelar</button>
</div>
    </form>    
  </mat-card>