import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseHttpService } from 'src/app/core/services/base-http.service';

@Injectable({
  providedIn: 'root'
})
export class TipoEntidadService {

  localUrl = 'parametricas/tipoEntidad';

  constructor(private baseHttpService: BaseHttpService) { }

  public obtenerTipoEntidad(): Observable<any> {
    return this.baseHttpService.getSistema(`${this.localUrl}/obtenerTipoEntidad`);
  }
  public obtenerTipoPersoneria(): Observable<any> {
    return this.baseHttpService.getSistema(`${this.localUrl}/obtenerTipoPersoneria`);
  }
 
}