import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-visor-pdf-multiple",
  templateUrl: "./visor-pdf-multiple.component.html",
  styleUrls: ["./visor-pdf-multiple.component.scss"],
})
export class VisorPdfMultipleComponent implements OnInit {
  source = "";
  base64: boolean = true;
  url: boolean = false;

  titulo = "";
  subtitulo = "";
  textoBoton = "";

  pdfBase64Array = [];
  documentTitle: string = '';
  counter: number = 0;

  constructor(
    public dialogVisorPdf: MatDialogRef<VisorPdfMultipleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.titulo = data.titulo || 'Visualizacion de archivos';
    this.subtitulo = data.subtitulo || '';
    this.textoBoton = data.textoBoton || 'Aceptar';
    this.pdfBase64Array = data.pdfArray || []; // En prueba
    // this.pdfBase64Array = data.pdfArray || []; // En funcionamiento
    console.log('PDFARRAY', this.pdfBase64Array)
  }

  ngOnInit(): void {
    this.dialogVisorPdf.updateSize("900px", "600px");

    if (this.pdfBase64Array.length > 0) {
      this.documentTitle = this.pdfBase64Array[this.counter].titulo;
      this.source = this.pdfBase64Array[this.counter].base64
    }
  }

  aceptar() {
    this.dialogVisorPdf.close({ res: true }); // true: aceptar
  }

  cancelar() {
    this.dialogVisorPdf.close({ res: false }); // false: cancelar
  }

  previousNextPdf(value: number) {
    console.log('COUNTER', this.counter)
    if (this.pdfBase64Array.length == 0) return;

    this.counter += value;

    if (this.counter <= 0) {
      this.counter = 0;
    }

    if (this.counter >= this.pdfBase64Array.length) {
      this.counter = this.pdfBase64Array.length - 1;
    }

    console.log('ENTRO', this.counter)
    this.documentTitle = this.pdfBase64Array[this.counter].titulo;
    this.source = this.pdfBase64Array[this.counter].base64;
  }
}
