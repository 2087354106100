import { Directive, AfterViewInit, ElementRef } from '@angular/core';

@Directive({
  selector: '[appAutofocus]'
})
export class AutofocusDirective {

  constructor(private elementRef: ElementRef) { 
    this.elementRef.nativeElement.style.backgroundColor = 'yellow';
  }

  // ngAfterViewInit(): void {
  //   // console.log('NATIVE EL', this.elementRef.nativeElement);
  //   // this.elementRef.nativeElement.style.color = 'red'; 
  // }

}
