import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { MaterialModule } from './material.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthModule } from './modules/auth/auth.module';
import { MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { PdfViewerModule } from "ng2-pdf-viewer";
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { MatchPasswordDirective } from './shared/directives/match-password.directive';
import { CoreComponentsModule } from './core/components/core-components.module';
import { VisorPublicoComponent } from './core/utils/visor-publico/visor-publico.component';
import { getSpanishPaginatorIntl } from './core/services/spanish-paginator-intl.service';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

@NgModule({
  declarations: [
    AppComponent,
    MatchPasswordDirective,
    VisorPublicoComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    CoreComponentsModule,
    BrowserAnimationsModule,
    MaterialModule,
    AuthModule,
    PdfViewerModule,
    MatAutocompleteModule
  ],
  exports: [
    MaterialModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'es-BO' },
    { provide: MatPaginatorIntl, useValue: getSpanishPaginatorIntl() },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          /* dateInput: ['l', 'LL'], */
          dateInput: 'dd/mm/yyyy',
        },
        display: {
          /* dateInput: 'L', */
          dateInput: 'DD/MM/YYYY',
          monthYearLabel: 'MMMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      },
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
